import { withErrorBoundary } from './ErrorBoundary';
import './CustomLoadingBar.scss';
import { Col } from 'react-bootstrap';
import Button from './Button';

export type CustomLoadingBarTypes = 'default' | 'good' | 'bad';

interface CustomLoadingBarProps {
  possibleValues: string[];
  currentValue: string;
  title: string;
  type?: CustomLoadingBarTypes;
  onClose?: () => void;
}

const CustomLoadingBar = ({
  possibleValues,
  currentValue,
  title,
  type = 'default',
  onClose,
}: CustomLoadingBarProps) => {
  const progress = `${Math.round(
    ((possibleValues.findIndex((val) => val === currentValue) + 1) /
      possibleValues.length) *
      100
  )}%`;
  return (
    <div className="custom-loading-bar">
      {typeof onClose === 'function' && (
        <Button
          className="custom-loading-bar__close-btn"
          type="custom"
          onClick={onClose}
        >
          X
        </Button>
      )}
      <Col xs={10} className="custom-loading-bar__title">
        {title}
      </Col>
      <div className="custom-loading-bar__bar">
        <div
          className={`custom-loading-bar__bar__progress custom-loading-bar__bar__progress__${type}`}
          style={{ width: progress }}
        >
          <div
            className={`custom-loading-bar__bar__text custom-loading-bar__bar__text__${type}`}
          >
            {progress}
          </div>
          <div className="custom-loading-bar__value">{currentValue}</div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(CustomLoadingBar);
