import dayjs from 'dayjs';
import {
  User,
  Company,
  Disaster,
  Reports,
  CreateUserRequest,
  UpdateUserRequest,
  CriticalNeedOfHelp,
} from '../../models';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { createArray, randomEnum } from '../utils';
import { Api, ApiResult } from './';
import {
  City,
  County,
  CountyGeoJSON,
  DisasterCityResponse,
  State,
  StateGeoJSON,
  Zipcode,
} from '../../models/Location';
import {
  CreateDisasterRequest,
  DisasterSummary,
  UpdateDisasterRequest,
} from '../../models/Disaster';
import {
  CreateUpdateUserResponse,
  UserExtendedSummary,
  UserSimpleSummary,
  UserSummary,
} from '../../models/User';
import { NotifyContactsResponse } from '../../models/NotifyContacts';
import { CompanyType, SimpleCompanyDetails } from '../../models/Company';
import { UpdateCompanyRequest } from '../../models/UpdateCompanyRequest';
import { CreateCompanyRequest } from '../../models/CreateCompanyRequest';
import BroadcastReport from '../../models/Report/BroadcastReport';
import Broadcaster = BroadcastReport.Data;
import Lerg from '../../models/Lerg';
import { InfrastructurePsap } from '../../models/Psap';
import { V1 } from './v1';
import { LookupStatusAndType } from '../../models/LookupStatusAndType';
import { CriticalNeedIncompleteResponse } from '../../models/CriticalNeedOfHelp';
import { Audit, AuditRequest } from '../../models/Audit';
import { GetShapefileRequest } from '../../models/GetShapefileRequest';
import { ReportCount } from '../../models/ReportCount';
import { UploadData } from '../../models/UploadData';
import {
  FileUploadStatusGetResponse,
  FileUploadsGetRequest,
  FileUploadsGetResponse,
} from '../../models/FileUpload';
import BroadcastLicense from '../../models/BroadcastLicense';
import { GeocodeCount } from '../../models/GeocodeCount';
import { GeocodeStatus } from '../../models/GeocodeStatus';
import { BulkGeocode } from '../../models/BulkGeocode';
import { LatestGeocodeJob } from '../../models/LatestGeocodeJob';

const Companies: Company[] = require('./stub-data/companies.json');
const MapData = require('./stub-data/map-data.json');

const getRandomString = (allowNumbers = false) => {
  let word = (Math.random() + 1).toString(36).slice(2, 10);
  if (!allowNumbers) {
    word = word.replace(/[0-9]/g, '');
  }
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

const createUpload = (id: number): FileUploadsGetResponse => ({
  id: id,
  companyId: 1,
  disasterId: 1,
  fileCategory: Math.round(Math.random()) ? 'non-parsing' : 'parsing',
  fileName: `Test_File_${Math.random()}`,
  fileType: 'xlxs',
  notes: 'Test file.',
  stateIds: [],
  userIdOfUploader: -1,
});

const uploads: FileUploadStatusGetResponse[] = createArray(30).map((it) => ({
  ...createUpload(it),
  latestStatusId:
    Math.round(Math.random()) < 0.2 ? Math.round(Math.random() * 13 + 74) : 74,
}));

const getUploadsUpdate = (): FileUploadStatusGetResponse[] => {
  for (let upload of uploads) {
    if (Math.random() < 0.5) continue;
    if (![75, 78, 79, 81, 84, 85, 87].includes(upload.latestStatusId)) {
      upload.latestStatusId =
        upload.latestStatusId === 74
          ? Math.random() < 0.5
            ? 74
            : Math.random() < 0.5
            ? 75
            : Math.round(
                Math.random() * (87 - upload.latestStatusId) +
                  upload.latestStatusId
              )
          : Math.round(
              Math.random() * (87 - upload.latestStatusId) +
                upload.latestStatusId
            );
    }
  }
  return uploads;
};

const ReportTypeList = [
  'Major Equipment',
  'Remote Aggregation Devices/Distribution',
  'Wireline PSAP',
  'Interoffice Facilities/TSP',
  'Interoffice Facilities - Rings',
  'IXC Blocking',
  'Wireless MSC-STP-Voice',
  'Wireless Cell Site by County',
  'Broadcast',
  'Cable System',
  'Satellite Earth/Ground Station',
];

const randomDate = (start: Date, end: Date) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};

const startDate = new Date().getTime() - 4.73e10;
const endDate = new Date().getTime();
const dateRange = endDate - startDate;

export const StubApi = (token?: string): Api => ({
  getIp: (): Promise<string> => Promise.resolve('0:0:0:0:0:0:0:1'),
  getProfile: (): Promise<ApiResult<User>> =>
    Promise.resolve({
      result: 'error',
    }),
  getUser: (id: string): Promise<ApiResult<UserExtendedSummary>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getUsers: (): Promise<ApiResult<UserSimpleSummary[]>> =>
    Promise.resolve({
      result: 'error',
    }),
  getUsersByCompany: (id: string): Promise<ApiResult<UserSummary[]>> =>
    Promise.resolve({
      result: 'error',
    }),
  getCompany: (id: string): Promise<ApiResult<Company>> =>
    Promise.resolve({
      result: 'error',
    }),
  getCompanies: (): Promise<ApiResult<Array<SimpleCompanyDetails>>> =>
    Promise.resolve({
      result: 'error',
    }),
  getDisaster: (id: string): Promise<ApiResult<Disaster>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getDisasters: (): Promise<ApiResult<DisasterSummary[]>> => {
    return Promise.resolve({ result: 'error' });
  },
  getDisastersWithReports: (): Promise<ApiResult<DisasterSummary[]>> => {
    return Promise.resolve({ result: 'error' });
  },
  getGeocodeCount: (): Promise<ApiResult<GeocodeCount>> => {
    return Promise.resolve({ result: 'error' });
  },
  getGeocodeStatus: (
    jobId: number
  ): Promise<ApiResult<Array<GeocodeStatus>>> => {
    return Promise.resolve({ result: 'error' });
  },
  getLatestGeocodeJob: (): Promise<ApiResult<LatestGeocodeJob>> => {
    return Promise.resolve({ result: 'error' });
  },
  sendBulkGeocode: (): Promise<ApiResult<BulkGeocode>> => {
    return Promise.resolve({ result: 'error' });
  },
  getReportCounts: (
    disasterId: number,
    companyId?: number
  ): Promise<ApiResult<ReportCount[]>> => {
    return Promise.resolve({ result: 'error' });
  },
  createDisaster: (
    request: CreateDisasterRequest
  ): Promise<ApiResult<Disaster>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  updateDisaster: (
    request: UpdateDisasterRequest
  ): Promise<ApiResult<Disaster>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getReport: async (identifier: string): Promise<ApiResult<any>> => {
    const DisasterList = await (await V1(token).getDisasters()).data;
    if (DisasterList) {
      const disaster = await (
        await V1(token).getDisaster(
          Math.floor(Math.random() * DisasterList.length).toString()
        )
      ).data;
      if (disaster) {
        return Promise.resolve({
          result: 'success',
          data: {
            date: new Date(),
            disaster: disaster,
            idNumber: '100-0016515539544',
            company: 'TESTCO',
            equipmentType: Reports.EquipmentType.EDGE_ROUTER,
            clliPsid: 'FDKJFD44AF3',
            status: 'down',
            numberOfAccessLines: 12,
            numberOfWorkingNumbers: 534,
          },
        });
      }
    }
    return Promise.resolve({
      result: 'error',
    });
  },
  getReports: async (): Promise<ApiResult<Reports.Report[]>> => {
    const fauxCompanies = ['TESTCO', 'Faux LLC', 'Mock Bros.', 'Fake Co.'];
    const DisasterList = await (await V1(token).getDisasters()).data;
    if (DisasterList) {
      let disasters = await Promise.all(
        createArray(100).map(async () => {
          return await (
            await V1(token).getDisaster(
              DisasterList[Math.floor(Math.random() * DisasterList.length)].id
            )
          ).data;
        })
      );
      disasters = disasters.filter((disaster) => !!disaster);
      const generatedFauxReports = disasters.map<any>((disaster) => {
        const numberOfVoIPSubscribers =
          Math.round(Math.random()) < 0.5
            ? Math.round(Math.random() * 1000)
            : undefined;
        const numberOfVideoSubscribers =
          Math.round(Math.random()) < 0.5
            ? Math.round(Math.random() * 1000)
            : undefined;

        return {
          type: randomEnum(Reports.Type),
          disaster: disaster!,
          idNumber: '100-0016515539544',
          company:
            fauxCompanies[Math.floor(Math.random() * fauxCompanies.length)],
          equipmentType: randomEnum(Reports.EquipmentType),
          clliPsid: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
            .toString(35)
            .slice(0, 12)
            .toUpperCase(),
          date: new Date(startDate + Math.random() * dateRange),
          status: Math.random() > 0.5 ? 'up' : 'down',
          numberOfAccessLines: Math.round(Math.random() * 10000),
          numberOfWorkingNumbers: Math.round(Math.random() * 1000),
          ...(numberOfVoIPSubscribers ? { numberOfVoIPSubscribers } : {}),
          ...(numberOfVideoSubscribers ? { numberOfVideoSubscribers } : {}),
        };
      });
      return Promise.resolve({
        result: 'success',
        data: generatedFauxReports,
      });
    }
    return Promise.resolve({
      result: 'error',
    });
  },
  getFileUploads: (
    request: FileUploadsGetRequest
  ): Promise<ApiResult<Array<FileUploadsGetResponse>>> => {
    return Promise.resolve({ result: 'error' });
  },
  getStateList: (): Promise<ApiResult<Array<State>>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  createAccount: (
    details: CreateUserRequest
  ): Promise<ApiResult<CreateUpdateUserResponse>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getMapData: (
    identifier: string
  ): Promise<
    ApiResult<FeatureCollection<Geometry, GeoJsonProperties> | undefined>
  > => {
    if (MapData[identifier]) {
      return Promise.resolve({
        result: 'success',
        data: MapData[identifier],
      });
    } else {
      return Promise.reject('Requested data not available');
    }
  },
  updateUser: (user: UpdateUserRequest) => {
    return Promise.reject('');
  },
  getReportTypeList: () => {
    return Promise.resolve({
      result: 'success',
      data: ReportTypeList,
    });
  },
  getNumberOfCompaniesReportData: (
    request: Reports.NumberOfCompaniesDataRequest
  ): Promise<ApiResult<Reports.NumberOfCompaniesDataResponse>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  submitHelpRequest: (): Promise<ApiResult<CriticalNeedOfHelp>> => {
    return Promise.resolve({ result: 'error' });
  },
  updateCriticalNeedOfHelp(
    request
  ): Promise<ApiResult<Array<CriticalNeedOfHelp>>> {
    return Promise.resolve({ result: 'error' });
  },
  getCountyList: async (): Promise<ApiResult<Array<County>>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getCriticalNeedOfHelpRequests(): Promise<
    ApiResult<Array<CriticalNeedOfHelp>>
  > {
    return Promise.resolve({
      result: 'error',
    });
  },
  getRoles: () => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getCompanyTypes: (): Promise<ApiResult<Array<CompanyType>>> => {
    return Promise.resolve({
      result: 'success',
      data: [
        {
          id: '1',
          name: 'Wireline',
          createdByUserId: '1',
          createdDate: dayjs().format('MM/DD/YYYY'),
        },
        {
          id: '2',
          name: 'Wireless',
          createdByUserId: '1',
          createdDate: dayjs().format('MM/DD/YYYY'),
        },
        {
          id: '3',
          name: 'Cable',
          createdByUserId: '1',
          createdDate: dayjs().format('MM/DD/YYYY'),
        },
        {
          id: '4',
          name: 'Broadcaster',
          createdByUserId: '1',
          createdDate: dayjs().format('MM/DD/YYYY'),
        },
      ],
    });
  },
  updateCompany: (
    request: UpdateCompanyRequest
  ): Promise<ApiResult<Company>> => {
    return Promise.resolve({
      result: 'success',
      data: Companies[0],
    });
  },
  createCompany: (
    request: CreateCompanyRequest
  ): Promise<ApiResult<Company>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getBroadcasterData: async (): Promise<ApiResult<Array<Broadcaster>>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getLergData: (): Promise<ApiResult<Array<Lerg.Lerg7>>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getInfrastructurePsapData: async (): Promise<
    ApiResult<Array<InfrastructurePsap.Psap>>
  > => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getCitiesByStateFips: (
    stateFips: String
  ): Promise<ApiResult<Array<City>>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getLookupStatusAndType: (): Promise<
    ApiResult<Array<LookupStatusAndType>>
  > => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getAuditLogs: async (
    request: AuditRequest
  ): Promise<ApiResult<Array<Audit>>> => {
    const logs: Audit[] = [];
    const HTTP_METHODS = ['GET', 'POST', 'PUT'];
    const randAddrPart = () => Math.floor(Math.random() * 256);
    const users = await (await V1(token).getUsers()).data;
    const randomUserId = () => {
      if (users) {
        return users[Math.floor(Math.random() * users.length)].userId;
      }
    };
    const numLogs = Math.floor(Math.random() * 90) + 10;
    for (let i = 0; i < numLogs; i++) {
      logs.push({
        id: i.toString(),
        externalId: undefined,
        accessedEndpoint: `${
          HTTP_METHODS[Math.floor(Math.random() * HTTP_METHODS.length)]
        } http://dirs-dev-api.fcc.gov/v1/${getRandomString(
          false
        )}/${getRandomString(false)}${
          Math.random() > 0.75 ? `/${Math.floor(Math.random() * 100)}` : ''
        }`,
        ipAddress: `${randAddrPart()}.${randAddrPart()}.${randAddrPart()}.${randAddrPart()}`,
        createdByUserId: randomUserId(),
        createdDate: randomDate(
          dayjs(request.startDate).toDate(),
          dayjs(request.endDate).toDate()
        ).toISOString(),
      });
    }
    return Promise.resolve({
      result: 'success',
      data: logs,
    });
  },
  getCriticalNeedIncomplete: (): Promise<
    ApiResult<CriticalNeedIncompleteResponse>
  > => {
    const nums = [0, 5, 10, 25, 75, 100];
    return Promise.resolve({
      result: 'success',
      data: {
        countOfIncompleteCriticalNeedOfHelp:
          nums[Math.floor(Math.random() * nums.length)],
      },
    });
  },
  getShapefile: (request: GetShapefileRequest): Promise<ApiResult<Blob>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getUploadData: (
    disasterId: number,
    companyId?: number
  ): Promise<ApiResult<UploadData>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getFilingStatus: (): Promise<
    ApiResult<Array<Reports.FilingStatusReportResponse>>
  > => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getCountyGeoJSON: (fipsNums: String[]): Promise<ApiResult<CountyGeoJSON>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getZipcodeList: (zipcodes: string[]): Promise<ApiResult<Array<Zipcode>>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  getStateGeoJSON: (fipsNums: String[]): Promise<ApiResult<StateGeoJSON>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  mapboxGeocoding: (): Promise<
    ApiResult<FeatureCollection<Geometry, GeoJsonProperties>>
  > => {
    return Promise.resolve({
      result: 'error',
    });
  },
  updateReports: (): Promise<ApiResult<Reports.Report>> => {
    return Promise.resolve({
      result: 'error',
    });
  },
  fileUpload: (): Promise<ApiResult<any>> =>
    Promise.resolve({ result: 'error' }),
  fileDownload: (): Promise<ApiResult<Blob>> =>
    Promise.resolve({ result: 'error' }),
  sendEmail: (): Promise<ApiResult<NotifyContactsResponse>> =>
    Promise.resolve({ result: 'error' }),
  sendSMS: (): Promise<ApiResult<NotifyContactsResponse>> =>
    Promise.resolve({ result: 'error' }),
  getFileUploadsStatuses: (): Promise<
    ApiResult<Array<FileUploadStatusGetResponse>>
  > =>
    Promise.resolve({
      result: 'success',
      data: getUploadsUpdate(),
    }),
  createReports: (): Promise<ApiResult<Reports.Report>> =>
    Promise.resolve({ result: 'error' }),
  getBroadcastLicenseCallSigns: (): Promise<ApiResult<Array<String>>> =>
    Promise.resolve({ result: 'error' }),
  getBroadcastLicenses: (): Promise<
    ApiResult<Array<BroadcastLicense.Broadcaster>>
  > => Promise.resolve({ result: 'error' }),
  deleteReports: (): Promise<ApiResult<boolean>> =>
    Promise.resolve({ result: 'error' }),
  getCitiesByDisaster: (): Promise<ApiResult<Array<DisasterCityResponse>>> =>
    Promise.resolve({ result: 'error' }),
});
