import { DisasterSummary, disastersToTypeahead } from '../Disaster';
import { $enum } from 'ts-enum-util';
import dayjs, { ManipulateType } from 'dayjs';
import { compareCountyNames, createArray } from '../../features/utils';
import { Address } from '../Address';
import { LookupStatusAndType } from '../LookupStatusAndType';
import { State } from '../Location';
import {
  Company,
  SimpleCompanyDetails,
  companiesToTypeahead,
} from '../Company';
import BroadcastReport from './BroadcastReport';
import CableSystemReport from './CableSystemReport';
import InterofficeFacilitiesTspReport from './InterofficeFacilitiesTspReport';
import IxcBlockingReport from './IxcBlockingReport';
import MajorEquipmentReport from './MajorEquipmentReport';
import RemoteAggregationDevicesReport from './RemoteAggregationDevicesReport';
import SatelliteReport from './SatelliteReport';
import WirelessCellSitesReport from './WirelessCellSitesReport';
import WirelessMscStpVoiceReport from './WirelessMscStpVoiceReport';
import WirelinePSAPsReport from './WirelinePsapReport';
import { TypeaheadFormObj } from '../../features/types';
import { RoleName } from '../Role';

export namespace Reports {
  export type Status = 'down' | 'up';

  export const Type = {
    interofficeFacilitiesTsp: 'Interoffice Facilities - TSP',
    wirelinePsap: 'Wireline PSAPs',
    wirelessMscStpVoice: 'Wireless MSC/STP/Voice',
    wirelessCellSites: 'Wireless Cell Site by County',
    satellites: 'Satellites',
    remoteAggregationDevices: 'Remote Aggregation Devices',
    majorEquipment: 'Major Equipment',
    ixcBlocking: 'IXC Blocking',
    cableSystem: 'Cable System',
    broadcast: 'Broadcast Reports',
  } as const;

  export interface DeleteRequest {
    id: number;
    versionNumber: number;
  }

  export enum EquipmentType {
    STP,
    HOST_SWITCH,
    HOT_SWITCH,
    REMOTE_SWITCH,
    EDGE_ROUTER,
    TANDEM,
  }

  export enum EquipmentStatus {
    PART_DOWN,
    DOWN,
    SWITCH_UP_BUT_OUTSIDE_PLANT_DOWN,
  }

  export enum PowerStatus {
    NO_POWER,
    ON_COMMERCIAL,
    ON_BATTERY,
  }

  export enum Availability {
    YES,
    NO,
    UNKNOWN,
  }

  export interface Report {
    id: number;
    disasterId: number;
    userIdOfUploader: number;
    companyId: number;
    reportNumber: string;
    notes: string;
    createdByUserId: number;
    createdDate: string;
    updatedByUserId: number;
    updatedDate: string;
    versionNumber: number;
  }

  export const fieldRowParams = (
    key: keyof Report,
    type: keyof typeof Type,
    states: State[],
    countyNames: string[],
    callSigns: TypeaheadFormObj[],
    disasters: DisasterSummary[],
    companies: (Company | SimpleCompanyDetails)[],
    lookupTable: LookupStatusAndType[],
    newReport: boolean
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
    theseOrThose?: [string[], string[]][];
  } | null => {
    if (
      key === 'id' ||
      key === 'userIdOfUploader' ||
      key === 'createdByUserId' ||
      key === 'createdDate' ||
      key === 'updatedByUserId' ||
      key === 'updatedDate' ||
      key === 'reportNumber'
    ) {
      return null;
    } else if (key === 'disasterId') {
      return {
        props: {
          key: key,
          label: 'Disaster',
          name: key,
          type: newReport ? 'typeahead' : undefined,
          options: disastersToTypeahead(disasters),
          disabled: !newReport,
          isRequired: newReport,
        },
      };
    } else if (key === 'companyId') {
      return {
        props: {
          key: key,
          label: 'Company',
          name: key,
          type: companies.length > 1 ? 'typeahead' : undefined,
          options: companiesToTypeahead(companies),
          disabled: !newReport || companies.length < 2,
          isRequired: newReport,
        },
      };
    } else if (key === 'versionNumber') {
      if (newReport) {
        return null;
      }

      return {
        props: {
          key: key,
          label: 'Version Number',
          name: key,
          disabled: true,
        },
      };
    } else if (key === 'notes') {
      return {
        props: {
          key: key,
          label: 'Notes',
          name: key,
          as: 'textarea',
          characterCounter: true,
          maxLength: 5000,
        },
      };
    } else if (type === 'broadcast') {
      return BroadcastReport.fieldRowParams(
        key as keyof BroadcastReport.Data,
        states,
        callSigns,
        lookupTable
      );
    } else if (type === 'cableSystem') {
      return CableSystemReport.fieldRowParams(
        key as keyof CableSystemReport.Data,
        states,
        countyNames
      );
    } else if (type === 'interofficeFacilitiesTsp') {
      return InterofficeFacilitiesTspReport.fieldRowParams(
        key as keyof InterofficeFacilitiesTspReport.Data,
        states
      );
    } else if (type === 'ixcBlocking') {
      return IxcBlockingReport.fieldRowParams(
        key as keyof IxcBlockingReport.Data,
        states
      );
    } else if (type === 'majorEquipment') {
      return MajorEquipmentReport.fieldRowParams(
        key as keyof MajorEquipmentReport.Data,
        states,
        lookupTable
      );
    } else if (type === 'remoteAggregationDevices') {
      return RemoteAggregationDevicesReport.fieldRowParams(
        key as keyof RemoteAggregationDevicesReport.Data,
        states,
        lookupTable
      );
    } else if (type === 'satellites') {
      return SatelliteReport.fieldRowParams(
        key as keyof SatelliteReport.Data,
        states,
        lookupTable
      );
    } else if (type === 'wirelessCellSites') {
      return WirelessCellSitesReport.fieldRowParams(
        key as keyof WirelessCellSitesReport.Data,
        states,
        countyNames
      );
    } else if (type === 'wirelessMscStpVoice') {
      return WirelessMscStpVoiceReport.fieldRowParams(
        key as keyof WirelessMscStpVoiceReport.Data,
        states,
        lookupTable
      );
    } else if (type === 'wirelinePsap') {
      return WirelinePSAPsReport.fieldRowParams(
        key as keyof WirelinePSAPsReport.Data,
        states,
        lookupTable
      );
    } else {
      return null;
    }
  };

  export const onRenderReportValue = (
    key: keyof Report,
    val: any,
    type: keyof typeof Type,
    disasters: DisasterSummary[],
    companies: SimpleCompanyDetails[],
    countyNames: string[],
    roleName: RoleName
  ) => {
    const value =
      (key as any) === 'county' && val && val.trim() !== ''
        ? compareCountyNames(val, countyNames).possibilities[0]
        : val;
    if (key === 'disasterId') {
      const dis = disasters.find((dis) => Number(dis.id) === Number(value));
      return dis ? dis.name : `Disaster with ID ${value} does not exist`;
    } else if (key === 'companyId') {
      const company = companies.find(
        (comp) => Number(comp.id) === Number(value)
      );
      return company
        ? roleName === 'inputter' || roleName === 'coordinator'
          ? company.name
          : company.id
        : `Company with ID ${value} does not exist`;
    } else if (key === 'notes') {
      return value ? value : '';
    } else if (type === 'broadcast') {
      return BroadcastReport.onRenderValue(
        key as keyof BroadcastReport.Data,
        value
      );
    } else if (type === 'cableSystem') {
      return CableSystemReport.onRenderValue(value);
    } else if (type === 'interofficeFacilitiesTsp') {
      return InterofficeFacilitiesTspReport.onRenderValue(value);
    } else if (type === 'ixcBlocking') {
      return IxcBlockingReport.onRenderValue(value);
    } else if (type === 'majorEquipment') {
      return MajorEquipmentReport.onRenderValue(value);
    } else if (type === 'remoteAggregationDevices') {
      return RemoteAggregationDevicesReport.onRenderValue(value);
    } else if (type === 'satellites') {
      return SatelliteReport.onRenderValue(value);
    } else if (type === 'wirelessCellSites') {
      return WirelessCellSitesReport.onRenderValue(value);
    } else if (type === 'wirelessMscStpVoice') {
      return WirelessMscStpVoiceReport.onRenderValue(value);
    } else if (type === 'wirelinePsap') {
      return WirelinePSAPsReport.onRenderValue(
        key as keyof WirelinePSAPsReport.Data,
        value
      );
    } else {
      return value ? value : '';
    }
  };

  export interface SituationReportDataRequest {
    disasterId: string;
    asOfDate: string;
    previousDate: string;
    wirelineCompanyData?: string;
    wirelessCompanyData?: string;
    states: string[];
  }

  export interface SituationReport {
    date: string;
    disasterName: string;
    wireline: ReportTable[];
    wirelineCompanies: ReportTable[] | undefined;
    wireless: ReportTable[];
    wirelessCompanies: ReportTable[] | undefined;
    broadcast: ReportTable[];
    cable: ReportTable[];
  }

  export enum ComprehensiveReportType {
    ALL = 'All',
    NETWORK_OUTAGE_INFORMATION = 'Network Outage Information',
    TOTAL_NETWORK_STATUS = 'Total Network Status',
    WIRELESS_NETWORK_STATUS_IN_THE_AFFECTED_AREAS = 'Wireless Network Status In The Affected Areas',
    AFFECTED_COUNTIES = 'Affected Counties',
    BROADCAST_IMPACT_INFORMATION = 'Broadcast Impact Information',
    CABLE_SERVICE_INFORMATION = 'Cable Service Information',
  }
  export enum ComprehensiveReportBy {
    COMPANY = 'Company',
    COMPANY_DATE = 'Company/Date',
    COMPANY_STATE = 'Company/State',
    DATE = 'Date',
    STATE = 'State',
  }
  export interface ComprehensiveReportRequest {
    reportBy: ComprehensiveReportBy;
    disasterId: string;
    reportType: ComprehensiveReportType;
    companyId?: string;
    date?: string;
  }

  export interface ComprehensiveReport {
    disaster: DisasterSummary;
    outageInfo: ReportTable[];
    totalStatus: ReportTable[];
    wirelessStatus: ReportTable[];
    affectedCounties: ReportTable[];
    broadcast: ReportTable[];
    cable: ReportTable[];
  }

  export interface FilingStatusReportRequest {
    disasterId?: string;
    reportType?: string;
    company?: string;
  }

  export interface FilingStatusReportResponse {
    id: string;
    disasterId: string;
    companyId: string;
    reportType: string;
    action: string;
    createdByUserId: string;
    createdDate: string;
    updatedByUserId: string;
    updatedDate: string;
  }

  export interface FilingStatusReport {
    disaster: string;
    date: string;
    data: ReportTable;
  }

  export interface NumberOfCompaniesDataRequest {
    companyType: string;
    updateDate: string;
  }

  export interface NumberOfCompaniesDataResponse {
    companies: {
      name: string;
      companyType: string;
      phoneNumber: string;
      phoneExtension: string;
      cellPhoneNumber: string;
      emailAddress: string;
      address: Address;
    }[];
    users: {
      firstName: string;
      lastName: string;
      phoneNumber: string;
      phoneExtension: string;
      cellPhoneNumber: string;
      emailAddress: string;
      companies: { companyName: string; companyType: number }[];
    }[];
  }

  export interface NumberOfCompaniesReport {
    companyTypes: string;
    updateDate: string;
    reportTypes: {
      title: string;
      reports: ReportTable[];
    }[];
  }

  export interface SummaryReportGroup {
    groupName: string;
    numberOfReports: number;
    reportDatas: ReportTable[];
  }

  export interface SummaryReport {
    disasterName: string;
    reportGroups: SummaryReportGroup[];
  }

  export interface ReportTableRow {
    data: string[];
    tooltip?: string;
  }

  export interface ReportTable {
    reportId: string;
    title?: string;
    headers?: string[];
    rows: ReportTableRow[];
  }

  export const equipmentTypeToString = (type: EquipmentType) => {
    switch (type) {
      case EquipmentType.STP:
        return 'STP';
      case EquipmentType.HOST_SWITCH:
        return 'Host Switch';
      case EquipmentType.HOT_SWITCH:
        return 'Hot Switch';
      case EquipmentType.REMOTE_SWITCH:
        return 'Remote Switch';
      case EquipmentType.EDGE_ROUTER:
        return 'Edge Router';
      case EquipmentType.TANDEM:
        return 'Tandem';
    }
  };

  export const typeToString = (type: keyof typeof Type) => {
    return Type[type];
  };

  export const equipmentStatusToString = (status: EquipmentStatus) => {
    switch (status) {
      case EquipmentStatus.DOWN:
        return 'Down';
      case EquipmentStatus.PART_DOWN:
        return 'Part Down';
      case EquipmentStatus.SWITCH_UP_BUT_OUTSIDE_PLANT_DOWN:
        return 'Switch Up but Outside Plant Down';
    }
  };

  export const powerStatusToString = (status: PowerStatus) => {
    switch (status) {
      case PowerStatus.NO_POWER:
        return 'No Power';
      case PowerStatus.ON_BATTERY:
        return 'On Battery';
      case PowerStatus.ON_COMMERCIAL:
        return 'On Commercial';
    }
  };

  export const availabilityToString = (availability: Availability) => {
    switch (availability) {
      case Availability.NO:
        return 'No';
      case Availability.UNKNOWN:
        return 'Unknown';
      case Availability.YES:
        return 'Yes';
    }
  };

  export const typeAsSelectable = () => {
    return $enum(Reports.Type).map((value, key) => ({
      id: key,
      label: value,
    }));
  };

  export const reportsToData = (
    reports: Reports.Report[],
    limit: number = 3,
    limitType: ManipulateType = 'months'
  ) => {
    const limitDate = dayjs().subtract(limit, limitType).startOf('week');
    const prealloc = createArray(Math.ceil(dayjs().diff(limitDate, 'week')))
      .reverse()
      .map((i) => ({
        label: dayjs().subtract(i, 'weeks').startOf('week').format('MMM DD'),
        value: 0,
      }));
    return reports.reduce((list, report) => {
      // const date = dayjs(report.date);
      // if (date.isBefore(limitDate)) return list;
      // const label = date.startOf('week').format('MMM DD');
      // const entry = list.find((it) => it.label === label);
      // if (entry) entry.value += 1;
      return list;
    }, prealloc);
  };
}
