import {
  CreateUserRequest,
  Company,
  UpdateUserRequest,
  User,
  CriticalNeedOfHelp,
  Disaster,
  Reports,
} from '../../models';
import { Api, ApiResult, handleApiError, handleApiSuccess } from '.';
import { StubApi } from './stub';
import {
  defaultHeaderInterpreter,
  defaultKeyGenerator,
  setupCache,
  buildMemoryStorage,
} from 'axios-cache-interceptor';
import {
  CreateUpdateUserResponse,
  UserExtendedSummary,
  UserSimpleSummary,
  UserSummary,
  createUpdateUserResponseToUserSimpleSummary,
} from '../../models/User';
import { UpdateCompanyRequest } from '../../models/UpdateCompanyRequest';
import { CreateCompanyRequest } from '../../models/CreateCompanyRequest';
import { GetShapefileRequest } from '../../models/GetShapefileRequest';
import {
  CompanyType,
  SimpleCompanyDetails,
  companyToSimpleCompanyDetails,
} from '../../models/Company';
import {
  CreateDisasterRequest,
  DisasterSummary,
  disasterToDisasterSummary,
  UpdateDisasterRequest,
} from '../../models/Disaster';
import {
  County,
  City,
  State,
  Zipcode,
  CountyGeoJSON,
  StateGeoJSON,
  DisasterCityResponse,
} from '../../models/Location';
import {
  CriticalNeedIncompleteResponse,
  CriticalNeedOfHelpRequest,
  CriticalNeedOfHelpUpdateRequest,
} from '../../models/CriticalNeedOfHelp';
import { Role } from '../../models/Role';
import { ReportCount } from '../../models/ReportCount';
import dayjs from 'dayjs';
import { UploadData } from '../../models/UploadData';
import { LookupStatusAndType } from '../../models/LookupStatusAndType';
import BroadcastReport from '../../models/Report/BroadcastReport';
import Broadcaster = BroadcastReport.Data;
import Lerg from '../../models/Lerg';
import { InfrastructurePsap } from '../../models/Psap';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import {
  FileUploadRequest,
  FileUploadResponse,
  FileUploadStatusGetResponse,
  FileUploadsGetRequest,
  FileUploadsGetResponse,
} from '../../models/FileUpload';
import {
  EmailRequest,
  NotifyContactsResponse,
  SMSRequest,
} from '../../models/NotifyContacts';
import BroadcastLicense from '../../models/BroadcastLicense';
import { GeocodeCount } from '../../models/GeocodeCount';
import { GeocodeStatus } from '../../models/GeocodeStatus';
import { BulkGeocode } from '../../models/BulkGeocode';
import { LatestGeocodeJob } from '../../models/LatestGeocodeJob';
import { customAxios } from './client';

const WEEK = 604800000;

const axios = setupCache(customAxios, {
  storage: buildMemoryStorage(),
  generateKey: defaultKeyGenerator,
  headerInterpreter: defaultHeaderInterpreter,
  debug: undefined,
});

export const V1 = (token?: string): Api => {
  (function () {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  })();
  return {
    ...StubApi(token),
    getUser: (id: string): Promise<ApiResult<UserExtendedSummary>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/users/userid/${id}`, {
          id: `getUser${id}`,
        })
        .then((res) => handleApiSuccess('get the user', res))
        .catch((error) => handleApiError('get the user', error));
    },
    getIp: (): Promise<string> => {
      return axios
        .get(`/v1/users/ip`)
        .then((res) => res.data)
        .catch((error) => error.error);
    },
    getBroadcastLicenseCallSigns: (): Promise<ApiResult<Array<String>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/broadcastLicenseCallSigns`, {
          cache: { ttl: WEEK },
        })
        .then((res) => handleApiSuccess('get broadcast call signs', res))
        .catch((error) => handleApiError('get broadcast call signs', error));
    },
    getBroadcastLicenses: (request: {
      callSign?: string;
      stateIds?: string[];
      latitude?: string;
      longitude?: string;
      radius?: string;
    }): Promise<ApiResult<Array<BroadcastLicense.Broadcaster>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/broadcastLicense`, {
          params: {
            callSign: request.callSign,
            stateIds: request.stateIds?.join(','),
            latitude: request.latitude,
            longitude: request.longitude,
            radius: request.radius,
          },
          cache: { ttl: WEEK },
        })
        .then((res) => handleApiSuccess('get broadcast licenses', res))
        .catch((error) => handleApiError('get broadcast licenses', error));
    },
    getReports: async (
      type: keyof typeof Reports.Type,
      disasterId: number,
      companyId?: number
    ): Promise<ApiResult<Reports.Report[]>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/${type}`, {
          params: {
            disasterId,
            companyId,
          },
          id: `getReports/${type}/${disasterId}`,
          cache: { ttl: 3000 },
        })
        .then((res) => handleApiSuccess('get report', res))
        .catch((error) => handleApiError('get report', error));
    },
    createReports: (
      type: keyof typeof Reports.Type,
      data: any
    ): Promise<ApiResult<Reports.Report>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .post(`/v1/data/${type}-create`, data, {
          cache: {
            update: {
              [`getReports/${type}/${data.disasterId}`]: (
                cachedState,
                createPostResponse
              ) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                (cachedState.data.data as Reports.Report[]).push(
                  createPostResponse.data
                );
                return cachedState;
              },
            },
          },
        })
        .then((res) => handleApiSuccess('create report', res))
        .catch((error) => handleApiError('create report', error));
    },
    deleteReports: (
      type: keyof typeof Reports.Type,
      records: Reports.DeleteRequest[],
      disasterId: number
    ): Promise<ApiResult<boolean>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .delete(`/v1/data/${type}`, {
          data: records,
          cache: {
            update: {
              [`getReports/${type}/${disasterId}`]: (
                cachedState,
                deleteResponse
              ) => {
                if (cachedState.state !== 'cached' || !deleteResponse.data) {
                  return 'ignore';
                }
                const newState = (
                  cachedState.data.data as Reports.Report[]
                ).filter((it) => {
                  const rec = records.find(
                    (r) =>
                      r.id === it.id && r.versionNumber === it.versionNumber
                  );
                  return rec === undefined;
                });
                cachedState.data.data = newState;
                return cachedState;
              },
            },
          },
        })
        .then((res) => handleApiSuccess('delete report', res))
        .catch((error) => handleApiError('delete report', error));
    },
    updateReports: (
      type: keyof typeof Reports.Type,
      data: any
    ): Promise<ApiResult<Reports.Report>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .post(`/v1/data/${type}`, data, {
          cache: {
            update: {
              [`getReports/${type}/${data.disasterId}`]: (
                cachedState,
                createPostResponse
              ) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                (cachedState.data.data as Reports.Report[]).push(
                  createPostResponse.data
                );
                return cachedState;
              },
            },
          },
        })
        .then((res) => handleApiSuccess('update report', res))
        .catch((error) => handleApiError('update report', error));
    },
    getUsers: (): Promise<ApiResult<UserSimpleSummary[]>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/users`, {
          id: 'getUsers',
        })
        .then((res) => handleApiSuccess('get all users', res))
        .catch((error) => handleApiError('get all users', error));
    },
    getUsersByCompany: (
      identifier: string
    ): Promise<ApiResult<UserSummary[]>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/users/companyid/${identifier}`, {
          cache: { ttl: 0 },
        })
        .then((res) => handleApiSuccess('get company users', res))
        .catch((error) => handleApiError('get company users', error));
    },
    getRoles: (): Promise<ApiResult<Array<Role>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/roles`, {
          cache: { ttl: WEEK },
        })
        .then((res) => handleApiSuccess('get roles', res))
        .catch((error) => handleApiError('get roles', error));
    },
    getProfile: (preventGlobalError = false): Promise<ApiResult<User>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/users/profile`, {
          id: 'getProfile',
        })
        .then((res) => handleApiSuccess('get the user profile', res))
        .catch((error) => {
          if (preventGlobalError) {
            return {
              error: error,
              result: 'error' as const,
            };
          } else {
            return handleApiError('get the user profile', error);
          }
        });
    },
    createAccount: (
      details: CreateUserRequest
    ): Promise<ApiResult<CreateUpdateUserResponse>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .post(`/v1/users`, details, {
          cache: {
            update: {
              getUsers: (cachedState, createPostResponse) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                (cachedState.data.data as UserSimpleSummary[]).push(
                  createUpdateUserResponseToUserSimpleSummary(
                    createPostResponse.data
                  )
                );
                return cachedState;
              },
              getCompanies: 'delete',
            },
          },
        })
        .then((res) => handleApiSuccess('create account', res))
        .catch((error) => handleApiError('create account', error));
    },
    updateUser: async (
      user: UpdateUserRequest
    ): Promise<ApiResult<CreateUpdateUserResponse>> => {
      if (!token) return Promise.reject('API is not prepared');
      const request = {
        ...user,
        companyIds: user.companyIds,
      };
      return axios
        .put(`/v1/users`, request, {
          cache: {
            update: {
              [`getUser${user.userId}`]: 'delete',
              getProfile: (cachedState, updatePutResponse) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                if (
                  (cachedState.data.data as User).id ===
                  (updatePutResponse.data as CreateUpdateUserResponse).id
                ) {
                  return 'delete';
                }
                return 'ignore';
              },
              getUsers: (cachedState, updatePutResponse) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                cachedState.data.data = (
                  cachedState.data.data as UserSimpleSummary[]
                ).map((user) => {
                  if (user.userId === updatePutResponse.data.id) {
                    return createUpdateUserResponseToUserSimpleSummary(
                      updatePutResponse.data
                    );
                  }
                  return user;
                });
                return cachedState;
              },
            },
          },
        })
        .then((res) => handleApiSuccess('update user', res))
        .catch((error) => handleApiError('update user', error));
    },
    getCompanyTypes: (): Promise<ApiResult<Array<CompanyType>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/companies/types`)
        .then((res) => handleApiSuccess('get company types', res))
        .catch((error) => handleApiError('get company types', error));
    },
    getCompanies: (): Promise<ApiResult<SimpleCompanyDetails[]>> => {
      return axios
        .get(`/v1/companies`, {
          id: 'getCompanies',
        })
        .then((res) => handleApiSuccess('get company list', res))
        .catch((error) => handleApiError('get company list', error));
    },
    getGeocodeCount: (): Promise<ApiResult<GeocodeCount>> => {
      return axios
        .get(`/v1/data/geocode`, {
          id: 'getGeocode',
          cache: { ttl: 500 },
        })
        .then((res) => handleApiSuccess('get unprocessed geocodes', res))
        .catch((error) => handleApiError('get unprocessed geocodes', error));
    },
    getGeocodeStatus: (
      jobId: number
    ): Promise<ApiResult<Array<GeocodeStatus>>> => {
      return axios
        .get(`/v1/data/geocode-status?jobId=${jobId}`, {
          id: 'getGeocodeStatus',
          cache: { ttl: 500 },
        })
        .then((res) => handleApiSuccess('get geocode status', res))
        .catch((error) => handleApiError('get geocode status', error));
    },
    getLatestGeocodeJob: (): Promise<ApiResult<LatestGeocodeJob>> => {
      return axios
        .get(`/v1/data/geocode-job`, {
          id: 'getLatestGeocodeJob',
          cache: { ttl: 500 },
        })
        .then((res) => handleApiSuccess('get latest geocode job', res))
        .catch((error) => handleApiError('get latest geocode job', error));
    },
    sendBulkGeocode: (): Promise<ApiResult<BulkGeocode>> => {
      return axios
        .post(`/v1/data/geocode`, {
          id: 'sendBulkGeocode',
        })
        .then((res) => handleApiSuccess('send bulk geocode request', res))
        .catch((error) => handleApiError('send bulk geocode request', error));
    },
    createCompany: (
      request: CreateCompanyRequest
    ): Promise<ApiResult<Company>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .post(`/v1/companies`, request, {
          cache: {
            update: {
              getCompanies: (cachedState, createPostResponse) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                (cachedState.data.data as SimpleCompanyDetails[]).push(
                  companyToSimpleCompanyDetails(createPostResponse.data)
                );
                return cachedState;
              },
            },
          },
        })
        .then((res) => handleApiSuccess('create company', res))
        .catch((error) => handleApiError('create company', error));
    },
    getCompany: (id: string): Promise<ApiResult<Company>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/companies/id/${id}`, {
          id: `getCompany${id}`,
        })
        .then((res) => ({
          data: res.data,
          result: 'success' as const,
        }))
        .catch((error) => ({
          error: error,
          result: 'error' as const,
        }));
    },
    updateCompany: (
      request: UpdateCompanyRequest
    ): Promise<ApiResult<Company>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .put(`/v1/companies`, request, {
          cache: {
            update: {
              getCompanies: (cachedState, updatePutResponse) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                cachedState.data.data = (
                  cachedState.data.data as SimpleCompanyDetails[]
                ).map((comp) =>
                  comp.id === updatePutResponse.data.id
                    ? companyToSimpleCompanyDetails(updatePutResponse.data)
                    : comp
                );
                return cachedState;
              },
              [`getCompany${request.id}`]: (cachedState, updatePutResponse) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                const comp: Company = {
                  ...(cachedState.data.data as Company),
                  ...updatePutResponse.data,
                };
                cachedState.data.data = comp;
                return cachedState;
              },
            },
          },
        })
        .then((res) => handleApiSuccess('update company', res))
        .catch((error) => handleApiError('update company', error));
    },
    submitHelpRequest: (
      request: CriticalNeedOfHelpRequest
    ): Promise<ApiResult<CriticalNeedOfHelp>> => {
      return axios
        .post(`/v1/data/criticalNeedOfHelp`, request)
        .then((res) => handleApiSuccess('request help', res))
        .catch((error) => handleApiError('request help', error));
    },
    getCriticalNeedOfHelpRequests(
      startDate?: string,
      endDate?: string
    ): Promise<ApiResult<Array<CriticalNeedOfHelp>>> {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/criticalNeedOfHelp`, {
          params: {
            startDate: startDate,
            endDate: endDate,
          },
          cache: { ttl: 0 },
        })
        .then((res) => handleApiSuccess('get critical need of help', res))
        .catch((error) => handleApiError('get critical need of help', error));
    },
    updateCriticalNeedOfHelp: (
      request: CriticalNeedOfHelpUpdateRequest
    ): Promise<ApiResult<Array<CriticalNeedOfHelp>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .put(`/v1/data/criticalNeedOfHelp`, request)
        .then((res) =>
          handleApiSuccess('update critical need of help request', res)
        )
        .catch((error) =>
          handleApiError('update critical need of help request', error)
        );
    },
    getCriticalNeedIncomplete: (): Promise<
      ApiResult<CriticalNeedIncompleteResponse>
    > => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/criticalNeedOfHelp/countOfIncomplete`, {
          cache: { ttl: 0 },
        })
        .then((res) =>
          handleApiSuccess('get critical need of help incomplete amount', res)
        )
        .catch((error) =>
          handleApiError('get critical need of help incomplete amount', error)
        );
    },
    createDisaster: (
      request: CreateDisasterRequest
    ): Promise<ApiResult<Disaster>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .post(`/v1/data/disasters`, request, {
          cache: {
            update: {
              getDisasters: (cachedState, createPostResponse) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                (cachedState.data.data as DisasterSummary[]).push(
                  disasterToDisasterSummary(createPostResponse.data)
                );
                return cachedState;
              },
            },
          },
        })
        .then((res) => handleApiSuccess('create disaster', res))
        .catch((error) => handleApiError('create disaster', error));
    },
    getDisasters: (
      after?: Date
    ): Promise<ApiResult<Array<DisasterSummary>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/disasters`, {
          id: 'getDisasters',
          params: {
            after: after ? after.toDateString() : undefined,
          },
        })
        .then((res) => handleApiSuccess('get disaster list', res))
        .catch((error) => handleApiError('get disaster list', error));
    },
    getDisastersWithReports: (
      after?: Date
    ): Promise<ApiResult<Array<DisasterSummary>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/disasters`, {
          id: 'getDisastersWithReports',
          params: {
            withReports: true,
            after: after ? after.toDateString() : undefined,
          },
          cache: {
            ttl: 1000,
          },
        })
        .then((res) => handleApiSuccess('get disasters with reports list', res))
        .catch((error) =>
          handleApiError('get disasters with reports list', error)
        );
    },
    getReportCounts: (
      disasterId: number,
      companyId?: number
    ): Promise<ApiResult<ReportCount[]>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/reportCount`, {
          params: {
            disasterId: disasterId,
            companyId: companyId,
          },
          id: 'reportCount',
          cache: { ttl: 0 },
        })
        .then((res) => handleApiSuccess('get report counts', res))
        .catch((error) => handleApiError('get report counts', error));
    },
    getDisaster: (id: string): Promise<ApiResult<Disaster>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/disasters/${id}`, {
          id: `getDisaster${id}`,
        })
        .then((res) => handleApiSuccess('get disaster', res))
        .catch((error) => handleApiError('get disaster', error));
    },
    updateDisaster: (
      request: UpdateDisasterRequest
    ): Promise<ApiResult<Disaster>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .put(`/v1/data/disasters`, request, {
          cache: {
            update: {
              getDisasters: (cachedState, updatePutResponse) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                cachedState.data.data = (
                  cachedState.data.data as DisasterSummary[]
                ).map((disaster) =>
                  disaster.id === updatePutResponse.data.id
                    ? disasterToDisasterSummary(updatePutResponse.data)
                    : disaster
                );
                return cachedState;
              },
              getDisastersWithReports: 'delete',
              [`getDisaster${request.id}`]: (
                cachedState,
                updatePutResponse
              ) => {
                if (cachedState.state !== 'cached') {
                  return 'ignore';
                }
                cachedState.data.data = updatePutResponse.data;
                return cachedState;
              },
            },
          },
        })
        .then((res) => handleApiSuccess('update disaster', res))
        .catch((error) => handleApiError('update disaster', error));
    },
    getStateList: (): Promise<ApiResult<Array<State>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/states`)
        .then((res) => handleApiSuccess('get state list', res))
        .catch((error) => handleApiError('get state list', error));
    },
    getCountyList: (): Promise<ApiResult<Array<County>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/counties`, {
          cache: { ttl: WEEK },
        })
        .then((res) => handleApiSuccess('get county list', res))
        .catch((error) => handleApiError('get county list', error));
    },
    getZipcodeList: (
      zipcodes: string[]
    ): Promise<ApiResult<Array<Zipcode>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/zipcodes`, {
          cache: { ttl: WEEK },
          params: {
            zipCodes: zipcodes.join(','),
          },
        })
        .then((res) => handleApiSuccess('get zipcode list', res))
        .catch((error) => handleApiError('get zipcode list', error));
    },
    getCountyGeoJSON: (
      fipsNums: String[]
    ): Promise<ApiResult<CountyGeoJSON>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/countyGeo`, {
          cache: { ttl: WEEK },
          params: {
            fips: fipsNums.join(','),
          },
        })
        .then((res) => handleApiSuccess('get county geoJSON', res))
        .catch((error) => handleApiError('get county geoJSON', error));
    },
    getStateGeoJSON: (fipsNums: String[]): Promise<ApiResult<StateGeoJSON>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/stateGeo`, {
          cache: { ttl: WEEK },
          params: {
            fips: fipsNums.join(','),
          },
        })
        .then((res) => handleApiSuccess('get state geoJSON', res))
        .catch((error) => handleApiError('get state geoJSON', error));
    },
    mapboxGeocoding: (
      searchText: string
    ): Promise<ApiResult<FeatureCollection<Geometry, GeoJsonProperties>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            searchText
          )}.json?proximity=ip&access_token=${process.env.MAPBOX_TOKEN}`
        )
        .then((res) => handleApiSuccess('mapbox search', res))
        .catch((error) => handleApiError('mapbox search', error));
    },
    getCitiesByDisaster: (
      disasterId: number
    ): Promise<ApiResult<Array<DisasterCityResponse>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/citiesByDisaster/${disasterId}`)
        .then((res) => handleApiSuccess('get cities by disaster', res))
        .catch((error) => handleApiError('get cities by disaster', error));
    },
    getCitiesByStateFips: (
      stateFips: String
    ): Promise<ApiResult<Array<City>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/cities`, {
          params: {
            stateFips: stateFips,
          },
        })
        .then((res) => handleApiSuccess('get cities by county', res))
        .catch((error) => handleApiError('get cities by county', error));
    },
    getShapefile: (request: GetShapefileRequest): Promise<ApiResult<Blob>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .post(
          `/v1/data/geojsontoshp?filename=${request.filename}`,
          request.data,
          {
            responseType: 'blob',
          }
        )
        .then((res) => handleApiSuccess('get shapefile', res))
        .catch((error) => handleApiError('get shapefile', error));
    },
    getNumberOfCompaniesReportData: (
      request: Reports.NumberOfCompaniesDataRequest
    ): Promise<ApiResult<Reports.NumberOfCompaniesDataResponse>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(
          `/v1/numberOfCompanies?companyTypeIds=${
            request.companyType
          }&updatedSince=${dayjs(request.updateDate).format('YYYY/MM/DD')}`
        )
        .then((res) => handleApiSuccess('get number of companies data', res))
        .catch((error) =>
          handleApiError('get number of companies data', error)
        );
    },
    getUploadData: (
      disasterId: number,
      companyId?: number
    ): Promise<ApiResult<UploadData>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/uploadData`, {
          params: {
            disasterId: disasterId,
            companyId: companyId,
          },
          cache: { ttl: 3000 },
        })
        .then((res) => handleApiSuccess('get upload data', res))
        .catch((error) => handleApiError('get upload data', error));
    },
    getFilingStatus: (
      request: Reports.FilingStatusReportRequest
    ): Promise<ApiResult<Array<Reports.FilingStatusReportResponse>>> => {
      if (!token) return Promise.reject('API is not prepared');
      const params: { [index: string]: string } = {};
      if (request.disasterId) params.disasterId = request.disasterId;
      if (request.reportType) params.reportType = request.reportType;
      if (request.company) params.companyId = request.company;
      return axios
        .get(`/v1/data/filingStatus`, {
          params: params,
        })
        .then((res) => handleApiSuccess('get upload data', res))
        .catch((error) => handleApiError('get upload data', error));
    },
    getBroadcasterData: (request: {
      companyId?: number;
      disasterId?: string;
      stateIds?: string[];
      latitude?: string;
      longitude?: string;
      radius?: string;
    }): Promise<ApiResult<Array<Broadcaster>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/broadcast`, {
          params: {
            companyId: request.companyId,
            disasterId: request.disasterId,
            stateIds: request.stateIds?.join(','),
            latitude: request.latitude,
            longitude: request.longitude,
            radius: request.radius,
          },
        })
        .then((res) => handleApiSuccess('get broadcaster data', res))
        .catch((error) => handleApiError('get broadcaster data', error));
    },
    getLergData: (request: {
      stateIds?: string[];
      latitude?: string;
      longitude?: string;
      radius?: string;
    }): Promise<ApiResult<Array<Lerg.Lerg7>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/lerg`, {
          params: {
            stateIds: request.stateIds?.join(','),
            latitude: request.latitude,
            longitude: request.longitude,
            radius: request.radius,
          },
        })
        .then((res) => handleApiSuccess('get lerg data', res))
        .catch((error) => handleApiError('get lerg data', error));
    },
    getInfrastructurePsapData: (request: {
      stateIds?: string[];
      latitude?: string;
      longitude?: string;
      radius?: string;
    }): Promise<ApiResult<Array<InfrastructurePsap.Psap>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/infrastructurePsap`, {
          params: {
            stateIds: request.stateIds?.join(','),
            latitude: request.latitude,
            longitude: request.longitude,
            radius: request.radius,
          },
        })
        .then((res) => handleApiSuccess('get infrastructure psap data', res))
        .catch((error) =>
          handleApiError('get infrastructure psap data', error)
        );
    },
    getLookupStatusAndType: (): Promise<
      ApiResult<Array<LookupStatusAndType>>
    > => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/statusTypes`, {
          cache: { ttl: WEEK },
        })
        .then((res) => handleApiSuccess('get lookup status and types', res))
        .catch((error) => handleApiError('get lookup status and types', error));
    },
    fileUpload: (
      request: FileUploadRequest
    ): Promise<ApiResult<FileUploadResponse>> => {
      if (!token) return Promise.reject('API is not prepared');
      const data = new FormData();
      data.append('fileToUpload', request.fileToUpload);
      data.append('parsable', String(request.parsable));
      data.append('userId', request.userId);
      data.append('companyId', request.companyId);
      if (request.disasterId) data.append('disasterId', request.disasterId);
      if (request.notes) data.append('notes', request.notes);
      if (request.states && request.states.length > 0)
        data.append('states', request.states.join(','));
      return axios
        .post(`/v1/files/uploadFile`, data)
        .then((res) => handleApiSuccess('upload file', res))
        .catch((error) => handleApiError('upload file', error));
    },
    getFileUploads: (
      request: FileUploadsGetRequest
    ): Promise<ApiResult<Array<FileUploadsGetResponse>>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/fileUploads`, {
          params: request,
          cache: { ttl: 100 },
        })
        .then((res) => handleApiSuccess('get upload file list', res))
        .catch((error) => handleApiError('get upload file list', error));
    },
    fileDownload: (uuid: string): Promise<ApiResult<Blob>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/files/downloadFile`, {
          params: {
            uuid: uuid,
          },
          responseType: 'blob',
        })
        .then((res) => handleApiSuccess('download file', res))
        .catch((error) => handleApiError('download file', error));
    },
    getFileUploadsStatuses: (): Promise<
      ApiResult<FileUploadStatusGetResponse[]>
    > => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .get(`/v1/data/fileUploadStatuses`, {
          cache: { ttl: 0 },
        })
        .then((res) => handleApiSuccess('get file statuses', res))
        .catch((error) => handleApiError('get file statuses', error));
    },
    sendEmail: (
      request: EmailRequest
    ): Promise<ApiResult<NotifyContactsResponse>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .post(`/v1/users/sendEmail`, {
          userIds: request.userIds || [],
          companyIds: request.companyIds || [],
          isAllCompanies: String(request.isAllCompanies),
          isAllUsers: String(request.isAllUsers),
          subject: request.subject,
          body: request.body,
        })
        .then((res) => handleApiSuccess('send email', res))
        .catch((error) => handleApiError('send email', error));
    },
    sendSMS: (
      request: SMSRequest
    ): Promise<ApiResult<NotifyContactsResponse>> => {
      if (!token) return Promise.reject('API is not prepared');
      return axios
        .post(`/v1/users/sendSMS`, {
          userIds: request.userIds || [],
          companyIds: request.companyIds || [],
          isAllCompanies: String(request.isAllCompanies),
          isAllUsers: String(request.isAllUsers),
          message: request.message,
        })
        .then((res) => handleApiSuccess('send SMS', res))
        .catch((error) => handleApiError('send SMS', error));
    },
  };
};
