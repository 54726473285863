import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { TypeaheadFormObj } from '../../features/types';
import { humanReadable } from '../../features/utils';
import { State, statesToTypeahead } from '../Location';
import { LookupStatusAndType } from '../LookupStatusAndType';

export namespace BroadcastReport {
  export interface Data extends Reports.Report {
    callSign: string;
    lookupBroadcastTypeOfService: number;
    facility: string;
    lookupBroadcastStatus: number;
    lookupStatusPowerTransmitter: number;
    lookupStatusGenerator: number;
    lookupStatusFuel: number;
    latitude: number;
    longitude: number;
    city: string;
    stateId: number;
    zipCode: string;
  }
  export const DataKeys: string[] = [
    ...REPORT_KEYS,
    'callSign',
    'lookupBroadcastTypeOfService',
    'facility',
    'lookupBroadcastStatus',
    'lookupStatusPowerTransmitter',
    'lookupStatusGenerator',
    'lookupStatusFuel',
    'latitude',
    'longitude',
    'city',
    'stateId',
    'zipCode',
  ];

  export const serviceCodeToService = (code: string) => {
    switch (code) {
      case 'AM':
        return 'AM';
      case 'FM':
      case 'FB':
      case 'FL':
      case 'FX':
        return 'FM';
      case 'DC':
      case 'DT':
      case 'LD':
      case 'TX':
        return 'TV';
      default:
        return '';
    }
  };

  const lookupKeyToCategory = (lookupKey: string): string => {
    switch (lookupKey) {
      case 'lookupStatusPowerTransmitter':
        return 'lookup_status_power';
      default:
        return lookupKey.replace(/([A-Z])/g, '_$1').toLocaleLowerCase();
    }
  };

  const reorderStatus = (label: string) => {
    let parts = label.split(' ');
    if (parts[0] === 'Status') {
      const status = parts.shift();
      parts.push(status!);
    }
    return parts.join(' ');
  };

  export const fieldRowParams = (
    key: keyof Data,
    states: State[],
    callSigns: TypeaheadFormObj[],
    lookupTable: LookupStatusAndType[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
  } | null => {
    if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
          isRequired: true,
        },
      };
    } else if (key === 'callSign') {
      return {
        props: {
          key: key,
          label: 'Call Sign',
          name: key,
          type: 'typeahead',
          options: callSigns,
          isRequired: true,
          allowNew: true,
        },
      };
    } else if (key.includes('lookup')) {
      const options = lookupTable
        .filter((lookup) => lookup.category === lookupKeyToCategory(key))
        .map(
          (lookup): TypeaheadFormObj => ({
            id: lookup.id,
            label: lookup.name,
          })
        );
      return {
        props: {
          key: key,
          label:
            key === 'lookupStatusPowerTransmitter'
              ? 'Power Status of Transmitter'
              : reorderStatus(humanReadable(key.replace('lookup', ''))),
          name: key,
          type: 'typeahead',
          options: options,
          isRequired:
            key !== 'lookupStatusGenerator' && key !== 'lookupStatusFuel',
        },
      };
    } else if (key === 'zipCode') {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          type: 'mask',
          mask: '99999',
          placeholder: '#####',
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isRequired: key === 'facility',
          placeholder:
            key === 'latitude' || key === 'longitude'
              ? '###.######'
              : undefined,
        },
        isNumber: key === 'latitude' || key === 'longitude',
      };
    }
  };

  export const onRenderValue = (key: keyof Data, value: any) => {
    if (key === 'zipCode' && value && value.length > 0) {
      return (value as string).slice(0, 5);
    }
    if (key === 'callSign' && value && value.length > 0) {
      return value.trim();
    }
    return typeof value === 'number' || value ? `${value}` : '';
  };
}

export default BroadcastReport;
