import { ClipboardEvent } from 'react';
import { TypeaheadFormObj } from '../features/types';
import {
  Geometry,
  booleanWithin,
  pointsWithinPolygon,
  multiPolygon as turfMultiPolygon,
  point as turfPoint,
} from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { Api } from '../features/api';
import { Point } from 'geojson';
import { isString } from 'formik';
import { USBBox } from '../features/const';
import { levenshtein } from '../features/utils';

mapboxgl.accessToken = process.env.MAPBOX_TOKEN as string;

export interface Location {
  name: string;
  lat: number;
  lon: number;
}

export interface City {
  id: string;
  placens?: string;
  geoid?: string;
  cityName: string;
  fips?: string;
  stateFips?: string;
  countyName?: string;
  createdByUserId?: string;
  createdDate?: string;
  updatedByUserId?: string;
  updatedDate?: string;
}

export interface DisasterCityResponse {
  state: string;
  city: string;
  county: string;
  fips: string;
}

export interface County {
  id: number;
  fips: string;
  countyFips: string;
  countyName: string;
  squareMiles: number;
  state: State;
  stateFips: string;
  createdByUserId: number;
  createdDate: string;
  updatedByUserId: number;
  updatedDate: string;
  versionNumber: string;
}

export interface CountyGeoJSON {
  type: string;
  features: {
    type: string;
    geometry: {
      type: string;
      coordinates: number[][][][];
    };
    properties: {
      id: number;
      objectid: number;
      fips: string;
      state_fips: string;
      county_fips: string;
      county_name: string;
      shape_length: number;
      shape_area: number;
      state_id: number;
    };
  }[];
}

export interface Zipcode {
  id?: number;
  zipCode?: string;
  countyFips?: string;
  city?: string;
  stateId: number;
  county: string;
  areaCode?: string;
  cityType?: string;
  cityAliasAbbreviation?: string;
  cityAliasName?: string;
  latitude?: number;
  longitude?: number;
  timeZone?: number;
  elevation?: number;
  daylightSavings?: string;
}

export interface StateGeoJSON {
  type: string;
  features: {
    type: string;
    geometry: {
      type: string;
      coordinates: number[][][][];
    };
    properties: {
      id: number;
      state_fips: string;
      abbreviation: string;
      state_name: string;
      state_id: number;
    };
  }[];
}

export const sortCounties = (counties: County[]): County[] => {
  return counties.sort((a, b) => a.countyName.localeCompare(b.countyName));
};

export interface State {
  id: string;
  name: string;
  abbreviation: string;
  stateFips: string;
  createdByUserId?: string;
  createdDate?: string;
  updatedByUserId?: string;
  updatedDate?: string;
  versionNumber?: string;
}

export const statesToTypeahead = (states: State[]): TypeaheadFormObj[] => {
  return states.map((state) => {
    return {
      id: state.id,
      label: state.name,
      sortOrder: state.id.toString(),
      data: state.abbreviation,
      stateFips: state.stateFips,
      allOption: state.name === 'All States/Territories',
    };
  });
};

export const sortStates = (states: State[]): State[] => {
  return states.sort((a: State, b: State) => a.name.localeCompare(b.name));
};

export const isWithinUSBoundaryBox = (
  latitude: number,
  longitude: number
): boolean => {
  if (latitude < -90 || latitude > 90) return false;
  if (longitude < -180 || longitude > 180) return false;
  const point: Geometry = {
    type: 'Point',
    coordinates: [longitude, latitude],
  };
  return booleanWithin(point, USBBox);
};

/**
 * Checks if location is within any of the counties provided.
 * First tries to check by lat/lon, uses address/city/state/zip to find lat/lon if not provided.
 * Will then string compare by name if cannot determine a lat/lon.
 * @param location Data for the location to check
 * @param counties Array of counties to check if location is within any
 * @param api Api
 * @returns Boolean
 */
export const isLocationInCounties = async (
  location: {
    countyName?: string;
    latitude?: number;
    longitude?: number;
    address?: string;
    city?: string;
    state?: State;
    zip?: string;
  },
  counties: County[],
  api: Api
) => {
  const coords = { latitude: location.latitude, longitude: location.longitude };
  // If no latitude and longitude then attempt to get from address
  if (coords.latitude === undefined || location.longitude === undefined) {
    let mapboxResponse = (
      await api.mapboxGeocoding(
        `${location.address || ''}, ${location.city || ''}, ${
          location.state?.abbreviation || ''
        }, ${location.zip || ''}`
      )
    ).data;
    if (
      mapboxResponse &&
      mapboxResponse.features.length &&
      Object.hasOwn(mapboxResponse.features[0].geometry, 'coordinates')
    ) {
      const geometry = mapboxResponse.features[0].geometry as Point;
      coords.latitude = geometry.coordinates[1];
      coords.longitude = geometry.coordinates[0];
    }
  }
  // Try using latitude/longitude
  if (
    coords.latitude !== undefined &&
    coords.longitude !== undefined &&
    !isNaN(Number(coords.latitude)) &&
    !isNaN(Number(coords.longitude))
  ) {
    const countyFips = counties.map((county) => county.fips);
    if (countyFips.every(isString)) {
      let geoJson = (await api.getCountyGeoJSON(countyFips)).data;
      if (geoJson) {
        return geoJson.features.some((geom) => {
          return (
            pointsWithinPolygon(
              turfPoint([Number(coords.longitude), Number(coords.latitude)]),
              turfMultiPolygon(geom.geometry.coordinates)
            ).features.length > 0
          );
        });
      }
    }
  }
  // If lat/lon didn't work, compare county name
  if (location.countyName) {
    const removeCountySubStr = (txt: string) =>
      txt
        .toLocaleUpperCase()
        .split(' ')
        .filter((part) => part !== 'COUNTY')
        .join(' ')
        .trim();
    const countyNames = counties.map((county) =>
      removeCountySubStr(county.countyName)
    );
    return countyNames.includes(removeCountySubStr(location.countyName));
  }
  // All else fails
  return false;
};

export const parsePastedCounties = (
  event: ClipboardEvent<HTMLInputElement>,
  countyOptions: TypeaheadFormObj[]
): string => {
  event.stopPropagation();
  event.preventDefault();
  const pastedData = event.clipboardData.getData('Text');
  const splitData = Array.from(pastedData.match(/([^\n\r\t,;]+)/gm) || []).map(
    (it) => {
      const split = it.split('/');
      return {
        county: split[0]
          ?.toLocaleLowerCase()
          .replace('county', '')
          .replaceAll('`', "'")
          .trim(),
        state: split[1]?.toLocaleLowerCase(),
      };
    }
  );
  const dataToOptions = splitData
    .map((it) => {
      return countyOptions
        .map((opt) => {
          const optSplit = opt.label.split('/');
          if (optSplit[1]?.toLocaleLowerCase() === it.state) {
            const optCounty = optSplit[0]
              .toLocaleLowerCase()
              .replace('county', '')
              .trim();
            return {
              countyId: opt.id,
              levenshtein: levenshtein(optCounty, it.county),
            };
          }
          return { countyId: opt.id, levenshtein: it.county.length };
        })
        .reduce(
          (prev, curr) => (curr.levenshtein < prev.levenshtein ? curr : prev),
          { countyId: '-1', levenshtein: it.county.length }
        ).countyId;
    })
    .join(',');
  return dataToOptions;
};
