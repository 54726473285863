import { useOktaAuth } from '@okta/okta-react';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Company,
  CreateUserRequest,
  CriticalNeedOfHelp,
  Disaster,
  Reports,
  UpdateUserRequest,
  User,
} from '../../models';
import {
  City,
  County,
  CountyGeoJSON,
  DisasterCityResponse,
  State,
  StateGeoJSON,
  Zipcode,
} from '../../models/Location';
import {
  CreateDisasterRequest,
  DisasterSummary,
  UpdateDisasterRequest,
} from '../../models/Disaster';
import { SessionNotification } from '../../models/SessionNotification';
import { store } from '../../app/store';
import { addNotification, removeNotification } from '../session';
import { AxiosResponse } from 'axios';
import { AlertType } from '../../components/Alert';
import {
  CreateUpdateUserResponse,
  UserExtendedSummary,
  UserSimpleSummary,
  UserSummary,
} from '../../models/User';
import { GetShapefileRequest } from '../../models/GetShapefileRequest';
import {
  EmailRequest,
  NotifyContactsResponse,
  SMSRequest,
} from '../../models/NotifyContacts';
import { CompanyType, SimpleCompanyDetails } from '../../models/Company';
import { UpdateCompanyRequest } from '../../models/UpdateCompanyRequest';
import { CreateCompanyRequest } from '../../models/CreateCompanyRequest';
import { Role } from '../../models/Role';
import BroadcastReport from '../../models/Report/BroadcastReport';
import Broadcaster = BroadcastReport.Data;
import Lerg from '../../models/Lerg';
import { InfrastructurePsap } from '../../models/Psap';
import {
  CriticalNeedIncompleteResponse,
  CriticalNeedOfHelpRequest,
  CriticalNeedOfHelpUpdateRequest,
} from '../../models/CriticalNeedOfHelp';
import { LookupStatusAndType } from '../../models/LookupStatusAndType';
import { Audit, AuditRequest } from '../../models/Audit';
import { UploadData } from '../../models/UploadData';
import { ReportCount } from '../../models/ReportCount';
import {
  FileUploadRequest,
  FileUploadResponse,
  FileUploadStatusGetResponse,
  FileUploadsGetRequest,
  FileUploadsGetResponse,
} from '../../models/FileUpload';
import BroadcastLicense from '../../models/BroadcastLicense';
import { GeocodeStatus } from '../../models/GeocodeStatus';
import { GeocodeCount } from '../../models/GeocodeCount';
import { BulkGeocode } from '../../models/BulkGeocode';
import { LatestGeocodeJob } from '../../models/LatestGeocodeJob';

export interface ApiResult<T = object, E = Error> {
  data?: T;
  error?: E;
  result: 'success' | 'error' | 'pending';
}

export const ApiContext = React.createContext<
  ((token?: string) => Api) | undefined
>(undefined);

export interface Api {
  createAccount: (
    details: CreateUserRequest
  ) => Promise<ApiResult<CreateUpdateUserResponse>>;
  createCompany: (request: CreateCompanyRequest) => Promise<ApiResult<Company>>;
  createDisaster: (
    request: CreateDisasterRequest
  ) => Promise<ApiResult<Disaster>>;
  createReports: (
    type: keyof typeof Reports.Type,
    data: any
  ) => Promise<ApiResult<Reports.Report>>;
  deleteReports: (
    type: keyof typeof Reports.Type,
    records: Reports.DeleteRequest[],
    disasterId: number
  ) => Promise<ApiResult<boolean>>;
  fileDownload: (uuid: string) => Promise<ApiResult<Blob>>;
  fileUpload: (
    request: FileUploadRequest
  ) => Promise<ApiResult<FileUploadResponse>>;
  getIp: () => Promise<string>;
  getAuditLogs: (request: AuditRequest) => Promise<ApiResult<Array<Audit>>>;
  getBroadcasterData: (request: {
    disasterId?: string;
    stateIds?: string[];
    latitude?: string;
    longitude?: string;
    radius?: string;
  }) => Promise<ApiResult<Array<Broadcaster>>>;
  getBroadcastLicenseCallSigns: () => Promise<ApiResult<Array<String>>>;
  getBroadcastLicenses: (request: {
    callSign?: string;
    stateIds?: string[];
    latitude?: string;
    longitude?: string;
    radius?: string;
  }) => Promise<ApiResult<Array<BroadcastLicense.Broadcaster>>>;
  getCitiesByDisaster: (
    disasterId: number
  ) => Promise<ApiResult<Array<DisasterCityResponse>>>;
  getCitiesByStateFips: (stateFips: String) => Promise<ApiResult<Array<City>>>;
  getCompany: (identifier: string) => Promise<ApiResult<Company>>;
  getCompanies: () => Promise<ApiResult<Array<SimpleCompanyDetails>>>;
  getCompanyTypes: () => Promise<ApiResult<Array<CompanyType>>>;
  getCountyList: () => Promise<ApiResult<Array<County>>>;
  getCountyGeoJSON: (fipsNums: String[]) => Promise<ApiResult<CountyGeoJSON>>;
  getCriticalNeedOfHelpRequests: (
    startDate?: string,
    endDate?: string
  ) => Promise<ApiResult<Array<CriticalNeedOfHelp>>>;
  getCriticalNeedIncomplete: () => Promise<
    ApiResult<CriticalNeedIncompleteResponse>
  >;
  getDisaster: (identifier: string) => Promise<ApiResult<Disaster>>;
  getDisasters: (after?: Date) => Promise<ApiResult<DisasterSummary[]>>;
  getDisastersWithReports: (
    after?: Date
  ) => Promise<ApiResult<DisasterSummary[]>>;
  getFileUploads: (
    request: FileUploadsGetRequest
  ) => Promise<ApiResult<Array<FileUploadsGetResponse>>>;
  getFileUploadsStatuses: () => Promise<
    ApiResult<Array<FileUploadStatusGetResponse>>
  >;
  getFilingStatus: (
    request: Reports.FilingStatusReportRequest
  ) => Promise<ApiResult<Array<Reports.FilingStatusReportResponse>>>;
  getGeocodeCount: () => Promise<ApiResult<GeocodeCount>>;
  getGeocodeStatus: (jobId: number) => Promise<ApiResult<Array<GeocodeStatus>>>;
  getLatestGeocodeJob: () => Promise<ApiResult<LatestGeocodeJob>>;

  getInfrastructurePsapData: (request: {
    stateIds?: string[];
    latitude?: string;
    longitude?: string;
    radius?: string;
  }) => Promise<ApiResult<Array<InfrastructurePsap.Psap>>>;
  getLergData: (request: {
    stateIds?: string[];
    latitude?: string;
    longitude?: string;
    radius?: string;
  }) => Promise<ApiResult<Array<Lerg.Lerg7>>>;
  getLookupStatusAndType: () => Promise<ApiResult<Array<LookupStatusAndType>>>;
  getMapData: (
    identifier: string
  ) => Promise<
    ApiResult<FeatureCollection<Geometry, GeoJsonProperties> | undefined>
  >;
  getNumberOfCompaniesReportData: (
    request: Reports.NumberOfCompaniesDataRequest
  ) => Promise<ApiResult<Reports.NumberOfCompaniesDataResponse>>;
  getProfile: (preventGlobalError?: boolean) => Promise<ApiResult<User>>;
  getReportCounts: (
    disasterId: number,
    companyId?: number
  ) => Promise<ApiResult<ReportCount[]>>;
  getReport: (identifier: string) => Promise<ApiResult<Reports.Report>>;
  getReports: (
    type: keyof typeof Reports.Type,
    disasterId: number,
    companyId?: number
  ) => Promise<ApiResult<Reports.Report[]>>;
  getReportTypeList: () => Promise<ApiResult<Array<string>>>;
  getRoles: () => Promise<ApiResult<Array<Role>>>;
  getStateGeoJSON: (fipsNums: String[]) => Promise<ApiResult<StateGeoJSON>>;
  getStateList: () => Promise<ApiResult<Array<State>>>;
  getShapefile: (request: GetShapefileRequest) => Promise<ApiResult<Blob>>;
  getUploadData: (
    disasterId: number,
    companyId?: number
  ) => Promise<ApiResult<UploadData>>;
  getUser: (identifier: string) => Promise<ApiResult<UserExtendedSummary>>;
  getUsers: () => Promise<ApiResult<UserSimpleSummary[]>>;
  getUsersByCompany: (identifier: string) => Promise<ApiResult<UserSummary[]>>;
  getZipcodeList: (zipcodes: string[]) => Promise<ApiResult<Array<Zipcode>>>;
  mapboxGeocoding: (
    searchText: string
  ) => Promise<ApiResult<FeatureCollection<Geometry, GeoJsonProperties>>>;
  sendEmail: (
    request: EmailRequest
  ) => Promise<ApiResult<NotifyContactsResponse>>;
  sendBulkGeocode: () => Promise<ApiResult<BulkGeocode>>;
  sendSMS: (request: SMSRequest) => Promise<ApiResult<NotifyContactsResponse>>;
  submitHelpRequest: (
    request: CriticalNeedOfHelpRequest
  ) => Promise<ApiResult<CriticalNeedOfHelp>>;
  updateCompany: (request: UpdateCompanyRequest) => Promise<ApiResult<Company>>;
  updateCriticalNeedOfHelp: (
    request: CriticalNeedOfHelpUpdateRequest
  ) => Promise<ApiResult<Array<CriticalNeedOfHelp>>>;
  updateDisaster: (
    request: UpdateDisasterRequest
  ) => Promise<ApiResult<Disaster>>;
  updateReports: (
    type: keyof typeof Reports.Type,
    data: any
  ) => Promise<ApiResult<Reports.Report>>;
  updateUser: (
    profile: UpdateUserRequest
  ) => Promise<ApiResult<CreateUpdateUserResponse>>;
}

export type ApiMethod = keyof Api;

/**
 * Allows a nested component of an ApiContext to access the shared API
 * @returns The current contextual instance of the Api implementation provided.
 */
export const useApi = () => {
  const auth = useOktaAuth();
  const api = useContext(ApiContext);
  if (!api) throw Error('useAPI must be used in an ApiContext');

  const authApi = useMemo(
    () => api(auth?.authState?.accessToken?.accessToken),
    [api, auth?.authState]
  );

  return authApi;
};

/**
 * A hook similar to useState that allows invoking API and returns the result
 * as an object consisting of the data or error and the result of the call.
 * @param selector
 * @param args arguments to pass; can also be set to 'noInitialFetch' to prevent initial fetch.
 * @returns
 */
export const useApiState = <R>(selector: keyof Api, ...args: any) => {
  const [result, setResult] = useState<ApiResult<R> | undefined>();
  const api = useApi();
  const method = api[selector] as unknown as (
    ...args: any
  ) => Promise<ApiResult<R>>;

  if (!api) {
    throw new Error(
      'API Not Found - ensure the component is inside of an API Context'
    );
  }

  const update = () => {
    if (args?.[0] === 'noFetch') return;
    method
      .apply(method, args)
      .then((result) => setResult(result as any))
      .catch((e) => {
        setResult(e);
      });
  };

  useEffect(() => {
    update();
  }, [api]);

  return {
    data: result?.data,
    error: result?.error,
    result: result?.result || 'pending',
    fetch: () => {
      setResult((prev) => {
        const newPrev: ApiResult<R> = { ...prev, result: 'pending' };
        return newPrev;
      });
      update();
    },
    useReduced: function <T>(reducer: (data: R) => T) {
      const data = this.data;
      const error = this.error;
      const result = this.result;
      return useMemo(
        () => ({
          data: data ? reducer(data) : undefined,
          error: error,
          result: result,
          fetch: this.fetch,
        }),
        [reducer, data, error, result]
      );
    },
  };
};

export const handleApiSuccess = (msg: string, response: AxiosResponse) => {
  store.dispatch(removeNotification(msg));
  return {
    data: response.data,
    result: 'success' as const,
  };
};

export const handleApiError = (msg: string, error: any) => {
  let display = true;
  let message = `An error occurred while attempting to ${msg}.\nError code: ${
    error.response?.status || error.code || 'Unknown'
  }.`;
  let type: AlertType = 'danger';
  if (error.response) {
    const serverMessage = error.response.data.message;
    if (serverMessage && serverMessage.includes('Entity Already Exists')) {
      message = `A DIRS account already exists for this Okta user.`;
      type = 'warning';
    } else if (
      serverMessage &&
      (serverMessage.includes(
        'AuthenticationException: User does not have a DIRS account'
      ) ||
        serverMessage.includes('No data found'))
    ) {
      display = false;
    } else if (
      error.response.data.status >= 400 &&
      error.response.data.status < 500
    ) {
      message = `An error occurred contacting the server while attempting to ${msg}.\nError code: ${error.response.status}.`;
    } else if (
      error.response.data.status >= 500 &&
      error.response.data.status < 600
    ) {
      message = `The server encountered an error while attempting to ${msg}.\nError code: ${error.response.status}.`;
    }
  }
  if (display) {
    let notification: SessionNotification = {
      id: msg,
      message: message,
      type: type,
    };
    store.dispatch(addNotification(notification));
  }
  return {
    error: error,
    result: 'error' as const,
  };
};
