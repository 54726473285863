import dayjs from 'dayjs';
import { TypeaheadFormObj } from '../features/types';
import { County } from './Location';

export interface DisasterSummary {
  id: string;
  name: string;
  notes: string;
  createdDate: string;
}
export interface Disaster extends DisasterSummary {
  countiesAffected: {
    id: string;
    disasterId?: string;
    county: County;
    createdByUserId?: string;
    createdDate: string;
    updatedByUserId?: string;
    updatedDate?: string;
    versionNumber?: string;
  }[];
  createdByUserId?: string;
  updatedByUserId?: string;
  updatedDate?: string;
  versionNumber?: string;
}

export const disasterToDisasterSummary = (
  disaster: Disaster
): DisasterSummary => {
  return {
    id: disaster.id,
    name: disaster.name,
    notes: disaster.notes,
    createdDate: disaster.createdDate,
  };
};

export interface CreateDisasterRequest {
  countiesAffected: {
    countyId: string;
  }[];
  name: string;
  notes?: string;
}

export interface UpdateDisasterRequest extends CreateDisasterRequest {
  id: string;
}

/**
 * Sorts disasters by creation date
 * @param disasters Array of disasters to sort by name
 */
export const sortDisasters = (disasters: Disaster[] | DisasterSummary[]) => {
  return disasters.sort((d1, d2) => dayjs(d2.createdDate).diff(d1.createdDate));
};

/**
 * Finds the most recent disaster in the list
 * @param disasters Disasters to search through
 * @returns the most recent disaster, or undefined if array is empty
 */
export const mostRecentDisaster = (
  disasters: Disaster[] | DisasterSummary[]
) => {
  if (disasters.length === 0) return undefined;
  if (Object.hasOwn(disasters, 'countiesAffected')) {
    return (disasters as Disaster[]).reduce((prev, curr) =>
      dayjs(prev.createdDate).isAfter(curr.createdDate) ? prev : curr
    );
  } else {
    return (disasters as DisasterSummary[]).reduce((prev, curr) =>
      dayjs(prev.createdDate).isAfter(curr.createdDate) ? prev : curr
    );
  }
};

/**
 * Simplifies mapping disasters to Typeahead options.
 * Disasters will be sorted by creation date.
 * @param disasters Disaster to map
 * @returns An object usable for Typeahead
 */
export const disastersToTypeahead = (
  disasters: Disaster[] | DisasterSummary[]
): TypeaheadFormObj[] =>
  sortDisasters(disasters).map((disaster, idx) => ({
    id: disaster.id,
    label: disaster.name,
    sortOrder: idx.toString(),
  }));
