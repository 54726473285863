import React, { ReactNode, useContext } from 'react';
import {
  Accordion,
  Card,
  Nav,
  Navbar,
  useAccordionButton,
  AccordionContext,
  Col,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { useApi } from '../features/api';
import { selectUser } from '../features/session';
import { PermissionIdentifier } from '../models/Permission';
import { checkUserAccess } from '../models/User';
import Button from './Button';
import './MainMenu.scss';
import SimpleNotification from './SimpleNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TutorialDialog from '../dialogs/TutorialDialog/TutorialDialog';
import { getPrimaryRole } from '../models/Role';
import { useSelectedCompany } from '../features/SelectedCompanyContext';

const NOTIFICATION_TIMEOUT = 10000;

interface Page {
  label: string;
  url: string;
  permission?: PermissionIdentifier;
  notification?: string;
}

/**
 * Include groupName iff this is a group of links.
 */
interface MenuItem {
  groupName?: string;
  links: Page[];
  permission?: PermissionIdentifier;
}

interface CustomToggleProps {
  label: string;
  eventKey: string;
}

const CustomToggle = ({ label, eventKey }: CustomToggleProps) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey);

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className="main_menu__accordion_button"
      onClick={decoratedOnClick}
    >
      {label}
      <div
        className={
          isCurrentEventKey
            ? 'main_menu__accordion_arrow_open'
            : 'main_menu__accordion_arrow_closed'
        }
      >
        &gt;
      </div>
    </button>
  );
};

interface MainMenuProps {
  toggleCallback: (closed?: boolean) => void;
  collapsed: boolean;
  inline: boolean;
}

const MainMenu = ({ toggleCallback, collapsed, inline }: MainMenuProps) => {
  const location = useLocation();
  const currentUser = useAppSelector(selectUser);
  const selectedComp = useSelectedCompany();
  const api = useApi();
  const navigate = useNavigate();
  const [criticalNeedNotification, setCriticalNeedNotification] =
    React.useState<string>('0');
  const showCriticalNeedNotification = React.useRef<boolean>(false);
  const criticalTimer = React.useRef<number | undefined>(undefined);
  const [collapsedState, setCollapsedState] = React.useState(collapsed);
  const [collapsedTimer, setCollapsedTimer] = React.useState<
    number | undefined
  >();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const menuBarTopRef = React.useRef<HTMLDivElement>(null);
  const [menuContentHeight, setMenuContentHeight] =
    React.useState<string>('100%');
  const [reportLabel, setReportLabel] = React.useState<string>('');

  const [showTutorial, setShowTutorial] = React.useState<boolean>(false);

  const changeHeight = React.useCallback(() => {
    if (menuBarTopRef.current) {
      const height =
        window.innerHeight - menuBarTopRef.current.getBoundingClientRect().y;
      setMenuContentHeight(`${height}px`);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', changeHeight);
    return () => window.removeEventListener('scroll', changeHeight);
  }, [changeHeight]);

  React.useEffect(() => {
    changeHeight();
  }, [location.pathname, changeHeight]);

  React.useEffect(() => {
    if (collapsedTimer) {
      window.clearTimeout(collapsedTimer);
      setCollapsedTimer(undefined);
    }
    if (collapsed && !inline) {
      setCollapsedTimer(window.setTimeout(() => setCollapsedState(true), 500));
    } else {
      setCollapsedState(false);
    }
    // eslint-disable-next-line
  }, [collapsed, inline]);

  React.useEffect(() => {
    clearInterval(criticalTimer.current);
    if (showCriticalNeedNotification.current && (!collapsed || inline)) {
      const updateNotification = () => {
        api.getCriticalNeedIncomplete().then((response) => {
          setCriticalNeedNotification((prev) => {
            if (response.data) {
              const val = response.data.countOfIncompleteCriticalNeedOfHelp;
              return val.toString();
            } else {
              return prev;
            }
          });
        });
      };
      updateNotification();
      criticalTimer.current = window.setInterval(
        updateNotification,
        NOTIFICATION_TIMEOUT
      );
    }
  }, [collapsed, inline, api, criticalTimer, showCriticalNeedNotification]);

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      const elem: Element | null =
        event.target instanceof Element ? event.target : null;
      if (
        (!collapsed || inline) &&
        menuRef.current &&
        !menuRef.current.contains(elem)
      ) {
        toggleCallback(true);
      }
    },
    [collapsed, inline, toggleCallback]
  );
  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuRef, handleClickOutside]);

  const handleBlurEvent = React.useCallback(
    (event: FocusEvent) => {
      const elem: Element | null =
        event.relatedTarget instanceof Element ? event.relatedTarget : null;
      if (
        (!collapsed || inline) &&
        menuRef.current &&
        !menuRef.current.contains(elem)
      ) {
        toggleCallback(true);
      }
    },
    [collapsed, inline, toggleCallback]
  );
  React.useEffect(() => {
    // Bind the event listener
    const menuRefCurrent = menuRef.current;
    if (menuRefCurrent) {
      menuRefCurrent.addEventListener('focusout', handleBlurEvent);
    }
    return () => {
      // Unbind the event listener on clean up
      if (menuRefCurrent) {
        menuRefCurrent.removeEventListener('focusout', handleBlurEvent);
      }
    };
  }, [handleBlurEvent, menuRef]);

  React.useEffect(() => {
    if (
      (currentUser &&
        getPrimaryRole(currentUser?.roles)?.name === 'dhs_user') ||
      getPrimaryRole(currentUser?.roles)?.name ===
        'participating_agencies_user' ||
      getPrimaryRole(currentUser?.roles)?.name === 'coverage_map_user'
    ) {
      setReportLabel('View Reports');
    } else {
      setReportLabel('View/Add/Update Reports');
    }
  }, [currentUser]);
  /**
   * All menu items are created here, with permissions set for display of links.
   */
  const menu: MenuItem[] = [
    {
      links: [
        {
          label: 'Main',
          url: '/',
        },
      ],
    },
    {
      groupName: 'Reports',
      permission: 'main_menu:reports:read',
      links: [
        {
          label: reportLabel,
          url: '/reports',
          permission: 'reports:report_list:read',
        },
        {
          label: 'Add/Update Disaster',
          url: '/disaster/add',
          permission: 'reports:add_update_disaster:read',
        },
        {
          label: 'Situation Report',
          url: '/report/situation',
          permission: 'reports:situation_report:read',
        },
        {
          label: 'Comprehensive Report',
          url: '/report/comprehensive',
          permission: 'reports:comprehensive_report:read',
        },
        {
          label: 'Filing Status Report',
          url: '/report/filingstatus',
          permission: 'reports:filing_status_report:read',
        },
        {
          label: 'Affected Installations',
          url: '/report/affectedinstallations',
          permission: 'reports:affected_installations:read',
        },
        {
          label: 'Number of Companies',
          url: '/report/numberofcompanies',
          permission: 'reports:number_of_companies:read',
        },
        {
          label: 'Dashboard',
          url: '/report/dashboard',
          permission: 'reports:dashboard:read',
        },
        {
          label: 'Summary Report',
          url: '/report/summary',
          permission: 'reports:summary_report:read',
        },
      ],
    },
    {
      groupName: 'Company & Users',
      permission: 'main_menu:company_and_users:read',
      links: [
        {
          label: 'Update Company Profile',
          url: `/company/${selectedComp.company?.id || ''}`,
          permission: 'company_and_users:update_company_profile:read',
        },
        {
          label: 'Manage Company Users',
          url: `/company/${selectedComp.company?.id || ''}/users`,
          permission: 'company_and_users:manage_company_users:read',
        },
        {
          label: 'Maintain Company Information',
          url: '/company/maintain',
          permission: 'company_and_users:maintain_company_info:read',
        },
        {
          label: 'Maintain User Information',
          url: '/users/maintain',
          permission: 'company_and_users:maintain_user_info:read',
        },
        {
          label: 'Notify Company and User Contacts',
          url: '/company/contacts',
          permission: 'company_and_users:company_contacts:read',
        },
      ],
    },
    {
      groupName: 'Maps',
      permission: 'main_menu:maps:read',
      links: [
        {
          label: 'Disaster Maps',
          url: '/maps/disaster',
          permission: 'maps:disaster_maps:read',
        },
        {
          label: 'Wireless Coverage Maps',
          url: '/maps/wirelesscoverage',
          permission: 'maps:wireless_coverage_maps:read',
        },
      ],
    },
    {
      links: [
        {
          label: 'Critical Need Requests',
          url: '/criticalneedrequests',
          permission: 'main_menu:critical_need_requests:read',
          notification: 'criticalNeed',
        },
        {
          label: 'PSAPs for Counties',
          url: '/psaps',
          permission: 'main_menu:psaps_for_counties:read',
        },
        {
          label: 'City List',
          url: '/citylist',
          permission: 'main_menu:city_list:read',
        },
      ],
    },
  ];

  if (
    currentUser &&
    currentUser.roles.length &&
    getPrimaryRole(currentUser.roles)?.name !== 'inputter' &&
    getPrimaryRole(currentUser.roles)?.name !== 'participating_agencies_user' &&
    getPrimaryRole(currentUser.roles)?.name !== 'dhs_user' &&
    getPrimaryRole(currentUser.roles)?.name !== 'coverage_map_user' &&
    getPrimaryRole(currentUser.roles)?.name !== 'dashboard_user'
  ) {
    menu.push({
      links: [
        {
          label: 'My Files',
          url: '/files',
        },
      ],
    });
  }

  const items: ReactNode[] = menu.flatMap((item, idx) => {
    const mappedPages: ReactNode[] = item.links
      .map((page) => {
        const hasPermission =
          !page.permission ||
          (currentUser && checkUserAccess(currentUser, page.permission));
        if (hasPermission && page.notification === 'criticalNeed') {
          showCriticalNeedNotification.current = true;
        }
        return (
          hasPermission && (
            <LinkContainer
              to={page.url}
              key={page.url}
              aria-hidden={collapsed && !inline}
            >
              <Nav.Link
                role="menuitem"
                as={Button}
                onClick={() => navigate(page.url)}
                type={'link'}
                label={page.label}
                aria-hidden={collapsed && !inline}
              >
                {page.notification === 'criticalNeed' && (
                  <SimpleNotification value={criticalNeedNotification} />
                )}
              </Nav.Link>
            </LinkContainer>
          )
        );
      })
      .filter((it) => it);

    const hasPermission =
      !item.permission ||
      (currentUser && checkUserAccess(currentUser, item.permission));

    if (
      typeof item.groupName === 'string' &&
      hasPermission &&
      mappedPages.length > 1
    ) {
      return (
        <Card role="menu" key={item.groupName}>
          <Card.Header aria-hidden={collapsed && !inline}>
            <CustomToggle eventKey={idx.toString()} label={item.groupName} />
          </Card.Header>
          <Accordion.Collapse eventKey={idx.toString()}>
            <Card.Body>{mappedPages}</Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    } else {
      return mappedPages;
    }
  });

  return (
    <Col
      className={`main_menu ${inline ? 'main_menu__inline' : ''}`}
      ref={menuRef}
    >
      <div className="main_menu__bar__buttons">
        <div className="main_menu__bar__buttons__top" ref={menuBarTopRef}>
          <Button
            type={'custom'}
            className={`main_menu__bar__buttons__btn ${
              inline
                ? 'main_menu__bar__buttons__btn__collapsed'
                : collapsed
                ? 'main_menu__bar__buttons__btn__collapsed'
                : 'main_menu__bar__buttons__btn__expanded'
            }`}
            label="Main Menu Toggle"
            showLabel={false}
            onClick={() => toggleCallback()}
          >
            <FontAwesomeIcon
              icon={solid('bars')}
              className={`main_menu__bar__buttons__btn__icon bars_icon`}
            />
            <FontAwesomeIcon
              icon={solid('x')}
              className={`main_menu__bar__buttons__btn__icon x_icon`}
            />
          </Button>
        </div>
        <div
          role="group"
          className={`main_menu__content_container main_menu__content_container__${
            collapsed && !inline ? 'collapsed' : 'expanded'
          }`}
        >
          <div
            role="group"
            className="main_menu__content_container__content"
            aria-hidden={collapsed && !inline}
            style={{ height: menuContentHeight }}
          >
            <Navbar
              role="group"
              aria-label="Main menu"
              expand="false"
              aria-hidden={collapsed && !inline}
            >
              <Nav
                role="menu"
                aria-label="main-menu"
                activeKey={location.pathname}
              >
                {collapsedState && !inline ? (
                  <></>
                ) : (
                  <Accordion>{items}</Accordion>
                )}
              </Nav>
            </Navbar>
          </div>
        </div>
        <div className="main_menu__bar__buttons__bottom">
          {currentUser &&
            currentUser.roles.length > 0 &&
            getPrimaryRole(currentUser.roles)?.name !== 'inputter' &&
            getPrimaryRole(currentUser.roles)?.name !==
              'participating_agencies_user' &&
            getPrimaryRole(currentUser.roles)?.name !== 'dhs_user' &&
            getPrimaryRole(currentUser.roles)?.name !== 'coverage_map_user' &&
            getPrimaryRole(currentUser.roles)?.name !== 'dashboard_user' && (
              <Button
                className="main_menu__bar__buttons__btn"
                type="custom"
                onClick={() => navigate('/files')}
              >
                <FontAwesomeIcon
                  icon={solid('file')}
                  className="main_menu__bar__buttons__btn__icon"
                />
                <span className="main_menu__bar__buttons__btn__files">
                  My Files
                </span>
              </Button>
            )}
          <Button
            className="main_menu__bar__buttons__btn"
            type="custom"
            onClick={() => setShowTutorial(true)}
            label="Open the tutorial"
            showLabel={false}
          >
            <FontAwesomeIcon
              icon={solid('question-circle')}
              className="main_menu__bar__buttons__btn__icon"
            />
          </Button>
        </div>
      </div>
      <TutorialDialog
        show={showTutorial}
        onHide={() => setShowTutorial(false)}
      />
    </Col>
  );
};

export default MainMenu;
