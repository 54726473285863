import Permission from './Permission';

const PRIMARY_ROLE_NAMES = [
  'administrator',
  'fcc_special_user',
  'fcc_analyst',
  'coverage_map_user',
  'coordinator',
  'inputter',
  'dhs_user',
  'participating_agencies_user',
  'dashboard_user',
] as const;
export type PrimaryRoleName = typeof PRIMARY_ROLE_NAMES[number];
export const isPrimaryRoleName = (name: any): name is PrimaryRoleName =>
  PRIMARY_ROLE_NAMES.includes(name);

export type RoleName =
  | '*'
  | PrimaryRoleName
  | 'all_states'
  | `${
      | 'A'
      | 'C'
      | 'D'
      | 'F'
      | 'G'
      | 'H'
      | 'I'
      | 'K'
      | 'L'
      | 'M'
      | 'N'
      | 'O'
      | 'P'
      | 'R'
      | 'S'
      | 'T'
      | 'U'
      | 'V'
      | 'W'}${
      | 'C'
      | 'D'
      | 'E'
      | 'H'
      | 'I'
      | 'J'
      | 'L'
      | 'M'
      | 'N'
      | 'O'
      | 'R'
      | 'S'
      | 'T'
      | 'V'
      | 'X'
      | 'Y'}`;

export interface RolePermission {
  id: string;
  permissions: Permission[];
  name: RoleName;
  description?: string;
  createdByUserId?: string;
  createdDate?: string;
  updatedByUserId?: string;
  updatedDate?: string;
  versionNumber?: string;
}

export interface Role {
  id: string;
  name: RoleName;
  description: string;
}

export const getPrimaryRole = <T extends RolePermission | Role>(
  roles: T[] | undefined
) => {
  if (roles === undefined) return;
  return roles.find((role) => isPrimaryRoleName(role.name));
};

export const getStateRoles = <T extends RolePermission | Role>(
  roles: T[] | undefined
) => {
  if (roles === undefined) return;
  return roles.filter((role) => !isPrimaryRoleName(role.name));
};
