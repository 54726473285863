import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { TypeaheadFormObj } from '../../features/types';
import { humanReadable } from '../../features/utils';
import { State, statesToTypeahead } from '../Location';
import { LookupStatusAndType } from '../LookupStatusAndType';

export namespace WirelinePSAPsReport {
  export interface Data extends Reports.Report {
    psapName: string;
    lookupStatusPsap: number;
    lookupReasonForPsapOutOrRerouted: number;
    lookupStatusOfAniAli: number;
    restoralTimeProjectedOrActual: string;
    areaServed: string;
    address: string;
    city: string;
    stateId: number;
    zipCode: string;
    numberOfCustomersServedByPsap: number;
    latitude: number;
    longitude: number;
  }
  export const DataKeys: string[] = [
    ...REPORT_KEYS,
    'psapName',
    'lookupStatusPsap',
    'lookupReasonForPsapOutOrRerouted',
    'lookupStatusOfAniAli',
    'restoralTimeProjectedOrActual',
    'areaServed',
    'numberOfCustomersServedByPsap',
    'latitude',
    'longitude',
    'address',
    'city',
    'stateId',
    'zipCode',
  ];

  const lookupKeyToCategory = (lookupKey: string): string => {
    switch (lookupKey) {
      case 'lookupStatusOfAniAli':
        return 'lookup_status_ani_ali';
      default:
        return lookupKey.replace(/([A-Z])/g, '_$1').toLocaleLowerCase();
    }
  };

  export const fieldRowParams = (
    key: keyof Data,
    states: State[],
    lookupTable: LookupStatusAndType[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
  } | null => {
    if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
        },
      };
    } else if (key.includes('lookup')) {
      const options = lookupTable
        .filter((lookup) => lookup.category === lookupKeyToCategory(key))
        .map(
          (lookup): TypeaheadFormObj => ({
            id: lookup.id,
            label: lookup.name,
          })
        );
      return {
        props: {
          key: key,
          label:
            key === 'lookupStatusPsap'
              ? 'Status Of PSAP'
              : humanReadable(key.replace('lookup', '')),
          name: key,
          type: 'typeahead',
          options: options,
          isRequired: ![
            'lookupStatusPsap',
            'lookupReasonForPsapOutOrRerouted',
            'lookupStatusOfAniAli',
          ].includes(key),
        },
      };
    } else if (key === 'zipCode') {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          type: 'mask',
          mask: '99999',
          placeholder: '#####',
          isRequired: true,
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isRequired: [
            'psapName',
            'areaServed',
            'numberOfCustomersServedByPsap',
          ].includes(key),
          placeholder:
            key === 'latitude' || key === 'longitude'
              ? '###.######'
              : undefined,
        },
        isNumber:
          key === 'latitude' ||
          key === 'longitude' ||
          key === 'numberOfCustomersServedByPsap',
      };
    }
  };

  export const onRenderValue = (key: keyof Data, value: any) => {
    if (key === 'zipCode' && value && value.length > 0) {
      return (value as string).slice(0, 5);
    }
    return typeof value === 'number' || value ? `${value}` : '';
  };
}

export default WirelinePSAPsReport;
