import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { Dispatch } from '@reduxjs/toolkit';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Api, useApi } from '../features/api';
import { selectProfileUpdated, setUser } from '../features/session';
import { User } from '../models';

const updateUserProfile = (
  oktaAuth: OktaAuth,
  api: Api,
  dispatch: Dispatch
): Promise<User | undefined> => {
  return oktaAuth
    .getUser()
    .then(async (user) => {
      const profile = await api?.getProfile(true);

      const userObject = ((): User => {
        switch (profile.result) {
          case 'success':
            return profile.data
              ? profile.data
              : {
                  id: '-1',
                  firstName: '',
                  lastName: '',
                  emailAddress: '',
                  isActive: false,
                  roles: [],
                };
          default:
          case 'error':
            return {
              id: user.sub,
              emailAddress: user.email || '',
              firstName: user.given_name || '',
              lastName: user.family_name || '',
              isActive: false,
              roles: [],
            };
        }
      })();
      dispatch(setUser(userObject));
      return userObject;
    })
    .catch(() => undefined);
};

const OktaSessionManager = () => {
  const { oktaAuth } = useOktaAuth();
  const api = useApi();
  const dispatch = useAppDispatch();
  const lastUpdate = useAppSelector(selectProfileUpdated);

  React.useEffect(() => {
    if (api && oktaAuth.getIdToken()) {
      updateUserProfile(oktaAuth, api, dispatch);
    } else if (window.location.href.includes('redirect')) {
      dispatch(setUser(undefined));
    } else {
      dispatch(setUser(null));
    }
  }, [oktaAuth, api, dispatch, lastUpdate]);

  return <></>;
};

export default OktaSessionManager;
