import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import session from '../features/session';

const reducer = {
  session,
};

export const store = configureStore({
  reducer,
});

export const mockStore = (preloadedState: Partial<RootState>) =>
  configureStore({ reducer, preloadedState });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
