import React from 'react';
import { useClickKeyHandler } from '../app/hooks';
import './Button.scss';

export type ButtonType =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'link'
  | 'custom';

type ButtonSize = 'x-small' | 'small' | 'normal' | 'large';

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLSpanElement>, 'size'> {
  label?: string;
  showLabel?: boolean;
  type?: ButtonType;
  disabled?: boolean;
  id?: string;
  allowDisabledEvents?: boolean;
  size?: ButtonSize;
  className?: string;
  focusable?: boolean;
  role?: string;
  onClick: (e?: any) => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseOver?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
  ['data-testid']?: string;
}

export const Button = React.forwardRef<HTMLSpanElement, ButtonProps>(
  (
    {
      type = 'default',
      disabled,
      id,
      allowDisabledEvents = false,
      label,
      showLabel = true,
      size = 'normal',
      style,
      focusable = true,
      role = 'button',
      onClick,
      onMouseDown,
      onMouseUp,
      onMouseOver,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      children,
      'data-testid': testId = 'button',
      className,
    }: ButtonProps,
    ref
  ) => {
    const onClickWrapper = (e?: any) => {
      if (!disabled || allowDisabledEvents) onClick(e);
    };
    const onMouseDownWrapper = () => {
      if ((!disabled || allowDisabledEvents) && onMouseDown) onMouseDown();
    };
    const onMouseUpWrapper = () => {
      if ((!disabled || allowDisabledEvents) && onMouseUp) onMouseUp();
    };
    const onMouseOverWrapper = () => {
      if ((!disabled || allowDisabledEvents) && onMouseOver) onMouseOver();
    };
    const onMouseEnterWrapper = () => {
      if ((!disabled || allowDisabledEvents) && onMouseEnter) onMouseEnter();
    };
    const onMouseLeaveWrapper = () => {
      if ((!disabled || allowDisabledEvents) && onMouseLeave) onMouseLeave();
    };
    const onFocusWrapper = () => {
      if ((!disabled || allowDisabledEvents) && onFocus) onFocus();
    };
    const onKeyDownOrClickHandler = (e?: any) => {
      onClickWrapper(e);
      onMouseDownWrapper();
    };
    const onKeyUpHandler = useClickKeyHandler(onMouseUpWrapper);
    const onKeyDownHandler = useClickKeyHandler(onKeyDownOrClickHandler);

    const generatedClassName = `button button--${type}${
      disabled ? ' button--disabled' : ''
    }${size ? ` button--${size}` : ''} ${className}`;

    return (
      <span
        id={id}
        ref={ref}
        role={role}
        tabIndex={focusable ? 0 : -1}
        onClick={onClickWrapper}
        onMouseDown={onMouseDownWrapper}
        onMouseUp={onMouseUpWrapper}
        onMouseOver={onMouseOverWrapper}
        onFocus={onFocusWrapper}
        onKeyUp={onKeyUpHandler}
        onKeyDown={onKeyDownHandler}
        onMouseEnter={onMouseEnterWrapper}
        onMouseLeave={onMouseLeaveWrapper}
        className={generatedClassName}
        style={style}
        aria-label={!showLabel ? label : undefined}
        aria-disabled={disabled}
        data-testid={testId}
      >
        {showLabel && label}
        {children}
      </span>
    );
  }
);

export default Button;
