import { CRUD } from './../features/types';
export type ResourceType = 'reports' | string;
export type PermissionIdentifier =
  | `${string}:${CRUD}`
  | `${string}:${string}:${CRUD}`;

export interface Permission {
  id: string;
  name: CRUD;
  resource: string;
  parent: string;
  description: string;
  createdByUserId: string;
  createdDate: string;
  updatedByUserId: string;
  updatedDate: string;
  versionNumber: string;
}

export default Permission;

/**
 * Checks a permission against an identifier
 * @param identifier a string consisting of parent:resource:name or resource:name
 */
export const checkPermission = (
  identifier: PermissionIdentifier,
  permission: Permission
) => {
  const permTree = identifier.split(':').reverse();
  const name = permTree[0];
  const resource = permTree[1];
  const parent = permTree[2];
  const hasAccess =
    permission.name === name &&
    permission.resource === resource &&
    (!parent || permission.parent === parent);
  return hasAccess;
};
