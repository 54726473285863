import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { humanReadable } from '../../features/utils';
import { State, statesToTypeahead } from '../Location';

export namespace IxcBlockingReport {
  export interface Data extends Reports.Report {
    stateId: number;
    timePeriod: number;
    callsBlockedInTimePeriod: number;
    callsBlockedHistoricallyIn24Hours: number;
  }
  export const DataKeys: string[] = [
    ...REPORT_KEYS,
    'stateId',
    'timePeriod',
    'callsBlockedInTimePeriod',
    'callsBlockedHistoricallyIn24Hours',
  ];

  export const fieldRowParams = (
    key: keyof Data,
    states: State[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
  } | null => {
    if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
          isRequired: true,
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isRequired: true,
        },
        isNumber: true,
      };
    }
  };

  export const onRenderValue = (value: any) => {
    return typeof value === 'number' || value ? `${value}` : '';
  };
}

export default IxcBlockingReport;
