import React from 'react';
import Button from './Button';
import './DisclaimerGovernment.scss';

interface DisclaimerGovernmentProps {
  onAccept: () => void;
}
const DisclaimerGovernment = ({ onAccept }: DisclaimerGovernmentProps) => {
  return (
    <div className="disclaimerGovernment__overlay">
      <div className="disclaimerGovernment">
        You are accessing a U.S. Government information system provided by the
        Federal Communications Commission for FCC-authorized users only. All
        communication occurring on this information system are subject to be
        monitored, recorded, and audited, therefore you have no reasonable
        expectation of privacy regarding any communications or data transmitted
        or stored on this information system. Unauthorized or improper use of
        the information system or information stored on this information system
        is prohibited and subject to criminal, civil, and/or administrative
        penalties. Any data transmitted or stored on this information system may
        be disclosed to law enforcement or other Federal agencies or their
        contractors in the course of conducting an investigation or other
        forensic analysis. USE OF THIS INFORMATION SYSTEM INDICATES CONSENT TO
        MONITORING AND RECORDING.
        <Button type={'primary'} onClick={onAccept}>
          I Accept
        </Button>
      </div>
    </div>
  );
};

export default DisclaimerGovernment;
