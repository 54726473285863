import React from 'react';
import { useApi, useApiState } from '../features/api';
import { LookupStatusAndType } from '../models/LookupStatusAndType';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  addFileToMonitor,
  addNotification,
  filesToMonitor,
  notifications,
  selectUser,
  setLatestFileStatuses,
  setShowFileMonitor,
  showFileMonitor,
} from '../features/session';
import FileUploadProgressDialog from '../dialogs/FileUploadProgressDialog';
import { FileUploadStatusGetResponse } from '../models/FileUpload';
import { SessionNotification } from '../models/SessionNotification';
import { AlertType } from './Alert';
import { withErrorBoundary } from './ErrorBoundary';
import { removeNotification } from '../features/session';

const FileMonitor = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const showDialog = useAppSelector(showFileMonitor);
  const allNotifications = useAppSelector(notifications);
  const trackingIds = useAppSelector(filesToMonitor);
  const [timedOut, setTimedOut] = React.useState<boolean>(false);
  const [statuses, setStatuses] = React.useState<
    Array<FileUploadStatusGetResponse>
  >([]);
  const [newData, setNewData] = React.useState<boolean>(false);

  const { data: lookupStatusAndType } = useApiState<Array<LookupStatusAndType>>(
    'getLookupStatusAndType'
  ).useReduced((data) =>
    data.filter((it) => it.category === 'lookup_status_file_upload')
  );

  const timed = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!timed.current) {
      timed.current = true;
      window.setTimeout(() => {
        setTimedOut(true);
      }, 1000);
    }
  }, [timed]);

  React.useEffect(() => {
    if (timedOut && api) {
      const createError = () => {
        const noti: SessionNotification = {
          id: 'FileMonitorError',
          message: 'There was an error retrieving the status of your file(s).',
          type: 'danger',
        };
        dispatch(addNotification(noti));
      };
      setTimedOut(false);
      if (currentUser && currentUser.roles?.length > 0)
        api
          .getFileUploadsStatuses()
          .then((result) => {
            if (result.error) {
              console.error(result.error);
              createError();
            } else if (result.data && result.data.length > 0) {
              dispatch(removeNotification('FileMonitorError'));
              dispatch(setLatestFileStatuses(result.data));
              const stats = result.data.filter((it) => {
                const lookup = lookupStatusAndType?.find(
                  (lsat) => Number(lsat.id) === Number(it.latestStatusId)
                );
                const isProcessing =
                  lookup?.name
                    .toLocaleLowerCase()
                    .match(/failed|infected|error|failure|completed/) === null;
                const isANotification =
                  allNotifications.find(
                    (aNoti) => Number(aNoti.id) === Number(it.id)
                  ) !== undefined;
                const isRecent = trackingIds.includes(it.id);
                return (
                  !!lookup && (isProcessing || isANotification || isRecent)
                );
              });
              setStatuses(stats);
              setNewData(true);
            }
          })
          .catch((error) => {
            if (error !== 'API is not prepared') {
              console.error(error);
              createError();
            }
          });
      const timeoutLength =
        statuses.filter(
          (stat) =>
            lookupStatusAndType
              ?.find((lsat) => Number(lsat.id) === Number(stat.latestStatusId))
              ?.name.toLocaleLowerCase()
              .match(/failed|infected|error|failure|completed/) === null
        ).length > 0 ||
        (showDialog && statuses.length === 0)
          ? 3000
          : 30000;
      window.setTimeout(() => {
        setTimedOut(true);
      }, timeoutLength);
    }
  }, [
    timedOut,
    api,
    allNotifications,
    lookupStatusAndType,
    statuses.length,
    dispatch,
    currentUser,
    statuses,
    showDialog,
    trackingIds,
  ]);

  React.useEffect(() => {
    if (newData) {
      setNewData(false);
      statuses.forEach((stat) => {
        const fileLine = `File: ${stat.fileName}.${stat.fileType}`;
        const lookup = lookupStatusAndType?.find(
          (lsat) => Number(lsat.id) === Number(stat.latestStatusId)
        );
        const statLine = !!lookup ? `\nUpload Status: ${lookup.name}` : '';
        let type: AlertType = 'info';
        if (!!lookup) {
          const lookupName =
            lookup.name
              .toLocaleLowerCase()
              .match(/failed|infected|error|failure|completed/)?.[0] || '';
          type =
            lookupName === 'completed'
              ? 'success'
              : lookupName === ''
              ? 'info'
              : 'danger';
        }
        const noti: SessionNotification = {
          id: stat.id.toString(),
          message: `${fileLine}${statLine}`,
          type: type,
        };
        dispatch(addFileToMonitor(stat.id));
        dispatch(addNotification(noti));
      });
    }
  }, [newData, dispatch, lookupStatusAndType, statuses]);

  return (
    <FileUploadProgressDialog
      show={showDialog}
      onHide={React.useCallback(() => {
        dispatch(setShowFileMonitor(false));
      }, [dispatch])}
      statuses={statuses}
      uploadingFiles={trackingIds.length > 0}
    />
  );
};

export default withErrorBoundary(FileMonitor);
