import React, { useEffect, useMemo, useState } from 'react';
import './UserStatus.scss';
import { nameToInitials } from '../features/utils';
import { Dropdown, Row, Col, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useOktaAuth } from '@okta/okta-react';
import { useAppDispatch } from '../app/hooks';
import { setProfileUpdate, setUser } from '../features/session';
import { useAppSelector } from '../app/hooks';
import { selectUser } from '../features/session';
import { getPrimaryRole } from '../models/Role';
import SwitchCompaniesDialog from '../dialogs/SwitchCompaniesDialog';

const UserStatus = () => {
  const currentUser = useAppSelector(selectUser);
  const userInitials = useMemo(() => {
    return (
      currentUser &&
      nameToInitials(`${currentUser.firstName} ${currentUser.lastName}`)
    );
  }, [currentUser]);
  const oktaContext = useOktaAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modPassword, setModPassword] = useState<HTMLElement | null>(null);
  const [modProfile, setModProfile] = useState<HTMLElement | null>(null);
  const [showSwitchCompanyDialog, setShowSwitchCompanyDialog] =
    useState<boolean>(false);
  const ariaLabel = useMemo(() => {
    return currentUser
      ? `Account menu for ${currentUser.firstName} ${currentUser.lastName}, labeled as ${userInitials}`
      : `User menu, not logged in`;
  }, [currentUser, userInitials]);

  const CustomToggle = useMemo(() => {
    return React.forwardRef(
      (
        {
          children,
          onClick,
        }: {
          children: React.ReactNode;
          onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        },
        ref: React.LegacyRef<HTMLButtonElement>
      ) => {
        return (
          <button
            type="button"
            aria-label={ariaLabel}
            className="user_status__toggle"
            ref={ref}
            onClick={(e) => {
              e.preventDefault();
              onClick(e);
            }}
          >
            {children}
          </button>
        );
      }
    );
  }, [ariaLabel]);

  const menuSelectHandler = (eventKey: string | null) => {
    switch (eventKey) {
      case 'login':
        dispatch(setUser(undefined));
        oktaContext.oktaAuth
          .signInWithRedirect()
          .then(() => dispatch(setProfileUpdate()));
        break;
      case 'logout':
        dispatch(setUser(null));
        navigate('/logout');
        break;
      case 'create_account':
        navigate('/user/new');
    }
  };

  const logOut = () => {
    navigate('/logout');
  };

  const signedInAndActive = useMemo(() => {
    return currentUser && !!currentUser?.roles?.length;
  }, [currentUser]);
  const unregistered = useMemo(() => {
    return currentUser && currentUser.roles?.length === 0;
  }, [currentUser]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.companies &&
      currentUser.companies.length > 1 &&
      (getPrimaryRole(currentUser?.roles)?.name === 'coordinator' ||
        getPrimaryRole(currentUser?.roles)?.name === 'inputter')
    ) {
      if (modPassword) modPassword.style.textAlign = 'center';
      if (modProfile) modProfile.style.textAlign = 'center';
    }
  }, [currentUser, modPassword, modProfile]);

  const getPasswordHref = React.useMemo(() => {
    if (window.location.hostname.includes('-internal'))
      return 'https://fcc.okta.com/enduser/settings';
    else if (window.location.hostname.includes('dirs.fcc.gov'))
      return 'https://apps.fcc.gov/cores/userLogin.do';
    else return 'https://apps2at.fcc.gov/fccUserReg/pages/login.htm';
  }, []);

  return (
    <Dropdown align={'end'} onSelect={menuSelectHandler}>
      <Dropdown.Toggle as={CustomToggle}>
        <div className="user_status">
          {currentUser && (
            <>
              <span className="user_status__initials">{userInitials}</span>
              <span
                id="current_user_email"
                className="current_user_email"
                style={{ display: 'none' }}
              >
                {currentUser.emailAddress}
              </span>
            </>
          )}
          {!currentUser && <FontAwesomeIcon icon={solid('user')} />}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdownMenu">
        <Dropdown.Item href="mailto:DIRSHelp@fcc.gov?subject=DIRS Help">
          <strong>Contact DIRS Help</strong>
        </Dropdown.Item>
        {signedInAndActive && (
          <Dropdown.Item
            ref={setModPassword}
            href={getPasswordHref}
            target="_blank"
          >
            Modify Password
          </Dropdown.Item>
        )}
        {signedInAndActive && (
          <Dropdown.Item
            ref={setModProfile}
            href={`/user/${currentUser?.id}/update`}
          >
            Modify Profile
          </Dropdown.Item>
        )}
        {(!currentUser || unregistered) && (
          <Dropdown.Item eventKey={'create_account'}>
            Create Account
          </Dropdown.Item>
        )}
        <hr className="dropdownDivider" />
        {!currentUser && (
          <Dropdown.Item eventKey={'login'}>Log In</Dropdown.Item>
        )}

        {currentUser &&
        currentUser.companies &&
        currentUser.companies.length > 1 &&
        (getPrimaryRole(currentUser?.roles)?.name === 'coordinator' ||
          getPrimaryRole(currentUser?.roles)?.name === 'inputter') ? (
          <Container>
            <Row className="avatarRow">
              <Col className="switchCompany">
                <Button
                  className="button_switch text-nowrap"
                  onClick={() => {
                    setShowSwitchCompanyDialog(true);
                  }}
                >
                  Switch Company
                </Button>
              </Col>
              <Col className="switchCompany">
                <Button className="button_logout text-nowrap" onClick={logOut}>
                  Log Out
                </Button>
              </Col>
            </Row>
          </Container>
        ) : (
          currentUser && (
            <Dropdown.Item eventKey={'logout'}>Log Out</Dropdown.Item>
          )
        )}

        {showSwitchCompanyDialog && (
          <SwitchCompaniesDialog
            show={showSwitchCompanyDialog}
            onDismiss={() => {
              setShowSwitchCompanyDialog(false);
            }}
            onCompanySelect={() => setShowSwitchCompanyDialog(false)}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserStatus;
