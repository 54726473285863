import { KeyValuePair } from './types';

/**
 * Converts a given name to two-letter initials
 * @param name The name to convert to initials
 * @returns A string consisting of the user's initials as two letters
 */
export const nameToInitials = (name: string) => {
  return name
    .split(' ')
    .map((name) => name.charAt(0))
    .slice(0, 2)
    .join('');
};

/**
 * Given a large number, converts it to a smaller string-based representation
 * @param number The number to convert to a shortened string
 * @returns A string of the number as a human readable shortened string
 */
export const shortenNumber = (number: number): string => {
  if (number < 1000) {
    return `${Math.floor(number)}`;
  }
  if (number < 1000000) {
    return `${Math.floor(number / 1000)}k`;
  }
  if (number < 1000000000) {
    return `${Math.floor(number / 1000000)}m`;
  }
  if (number < 1000000000000) {
    return `${Math.floor(number / 1000000000)}b`;
  }
  return `${number}`;
};

/**
 * Given a number, rounds it to 2 decimal places, or given amount
 * @param number The number to round
 * @param places How many decimal places to round to, default 2
 * @returns The number rounded to given decimal places
 */
export const roundToDecimalPlaces = (number: number, places: number = 2) => {
  const p = Math.pow(10, places);
  return Math.round((number + Number.EPSILON) * p) / p;
};

/**
 * Given a number, creates an array of that length where each value is identical
 * to its index
 * @param count The length of the array
 * @returns An array of 0..<i where i is the number provided
 */
export const createArray = (count: number) =>
  Array.from(new Array(count).keys());

type Comparator<T> = (a: T, b: T) => boolean;
const defaultComparatorForExcludeArray = <T,>(a: T, b: T) => a === b;
/**
 * A comparator for comparing two key-value pairs for equality.
 */
export const keyValuePairComparator: Comparator<KeyValuePair<any>> = <T,>(
  a: KeyValuePair<T>,
  b: KeyValuePair<T>
) => a[0] === b[0] && a[1] === b[1];

/**
 * Given two arrays, returns a copy of the first array where
 * items in the second array are no longer present.
 * @param array The array to exclude from
 * @param exclusion An array of items to exclude from the array
 * @param comparator A boolean comparator to detect equality
 * @returns The array with the excluded items removed
 */
export const excludeArray = <T,>(
  array: T[],
  exclusion: T[],
  comparator: Comparator<T> = defaultComparatorForExcludeArray
) => array.filter((it) => !exclusion.find((ex) => comparator(it, ex)));

export const randomEnum = <T extends {}>(enumType: T): T[keyof T] => {
  const values = Object.keys(enumType)
    .map((e) => parseInt(e))
    .filter((i) => !isNaN(i)) as unknown as T[keyof T][];
  const index = Math.floor(Math.random() * values.length);
  return values[index];
};

/**
 * Allows for code to execute after a given amount of time
 * @param ms The duration, in milliseconds, to sleep for
 * @returns A promise that will resolve after the given time
 */
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

/**
 * Clips number between min and max.
 * @param input String to validate
 * @param min Minimum number to allow
 * @param max Maximum number to allow
 * @param allowEmptyString If this can return an empty string or minimum number. Default is true.
 * @returns Input clipped to min/max
 */
export const stringBetweenNumbers = (
  input: string,
  min: number,
  max: number,
  allowEmptyString = true
) => {
  const newValue = parseInt(input);
  if (
    (allowEmptyString && isNaN(newValue)) ||
    (allowEmptyString && input === '')
  ) {
    return '';
  }
  return isNaN(newValue) || input === ''
    ? min.toString()
    : newValue > max
    ? max.toString()
    : newValue < min
    ? min.toString()
    : isNaN(Number(input))
    ? newValue.toString()
    : input;
};

export const isNumeric = (value: any) => {
  if (isNaN(value) || value === '' || value === ' ' || !value) {
    return false;
  } else {
    return true;
  }
};

/* Capitalizes the word
 * @param string The word to capitalize
 * @returns The word, capitalized
 */
export const capitalize = (string: String) => {
  return string
    .split('')
    .map((c, i) => (i === 0 ? c.toLocaleUpperCase() : c))
    .join('');
};

const adjustAcronyms = (inString: String) => {
  return inString
    .replace(/(\b)Vo IP(\b)/gi, '$1VoIP$2')
    .replace(/(\b)Voip(\b)/gi, '$1VoIP$2')
    .replace(/(\b)Id(\b)/gi, '$1ID$2')
    .replace(/(\b)Fcc(\b)/gi, '$1FCC$2')
    .replace(/(\b)Dhs(\b)/gi, '$1DHS$2')
    .replace(/(\b)Ip(\b)/gi, '$1IP$2')
    .replace(/(\b)Ds 3s(\b)/gi, '$1DS3s$2')
    .replace(/(\b)Tsp(\b)/gi, '$1TSP$2')
    .replace(/(\b)Clli(\b)/gi, '$1CLLI$2')
    .replace(/(\b)Psap(\b)/gi, '$1PSAP$2')
    .replace(/(\b)Ixc(\b)/gi, '$1IXC$2')
    .replace(/(\b)Of(\b)/gi, '$1of$2')
    .replace(/(\b)Psid(\b)/gi, '$1PSID$2')
    .replace(/(\b)Ani Ali(\b)/gi, '$1ANI/ALI$2');
};

/**
 * Takes a string in camelCase, kebab-case, snake_case or PascalCase and
 * converts it to human readable case like Camel Case, Kebab Case, Snake Case
 * and Pascal Case.
 * @param string The string to convert to a human readable string
 * @returns A human readable version of the given string
 */
export const humanReadable = (inString: String) => {
  if (inString.indexOf('-') !== -1) {
    return [inString.split('-').map(capitalize).join(' ').trim()].map(
      adjustAcronyms
    )[0];
  } else if (inString.indexOf('_') !== -1) {
    return [inString.split('_').map(capitalize).join(' ').trim()].map(
      adjustAcronyms
    )[0];
  } else {
    return [
      inString
        .replace(/([A-Z][a-z]+)/g, ' $1 ')
        .split(/\s+/)
        .map(capitalize)
        .join(' ')
        .trim(),
    ].map(adjustAcronyms)[0];
  }
};

export const formatPhoneNumberWithExtension = (
  phoneNumber?: string,
  extension?: string
) => `${phoneNumber || ''}${extension ? ` Ext: ${extension}` : ''}`;

export const asyncFilter = async (
  arr: any[],
  predicate: (value: any, index: number, array: any[]) => unknown
) =>
  Promise.all(arr.map(predicate)).then((results) =>
    arr.filter((_v, index) => results[index])
  );

/**
 * Checks if value given is not undefined (is defined)
 * @param val Value to check
 * @returns False if undefined, true otherwise
 */
export const isDefined = <T,>(val: T | undefined): val is T => {
  return val !== undefined;
};

export const includesLocale = (
  text: string,
  subText: string,
  locales?: string | string[],
  options?: Intl.CollatorOptions
): boolean => {
  const textArray = text.split('');
  const subArray = subText.split('');
  let included = false;
  for (const textIdx in textArray) {
    if (Number(textIdx) + subArray.length > textArray.length) break;
    if (textArray[textIdx].localeCompare(subArray[0], locales, options) === 0) {
      let inc = true;
      for (const subIdx in subArray) {
        if (
          textArray[Number(textIdx) + Number(subIdx)].localeCompare(
            subArray[subIdx],
            locales,
            options
          ) !== 0
        ) {
          inc = false;
          break;
        }
      }
      if (inc) {
        included = true;
        break;
      }
    }
  }
  return included;
};

export const makeAccessible = (action: () => void) => {
  return {
    onKeyDown: (e: React.KeyboardEvent) => {
      console.error(e.code);
      if (e.code === 'Space' || e.code === 'Enter') {
        action();
      }
    },
  };
};

export const levenshtein = (first: string, second: string): number => {
  const a = !!first ? first : '';
  const b = !!second ? second : '';
  const matrix = Array.from({ length: a.length }).map(() =>
    Array.from({ length: b.length }).map(() => 0)
  );

  for (let i = 0; i < a.length; i++) matrix[i][0] = i;

  for (let i = 0; i < b.length; i++) matrix[0][i] = i;

  for (let j = 0; j < b.length; j++)
    for (let i = 0; i < a.length; i++)
      matrix[i][j] = Math.min(
        (i === 0 ? 0 : matrix[i - 1][j]) + 1,
        (j === 0 ? 0 : matrix[i][j - 1]) + 1,
        (i === 0 || j === 0 ? 0 : matrix[i - 1][j - 1]) +
          (a[i] === b[j] ? 0 : 1)
      );

  return matrix[a.length - 1][b.length - 1];
};

/**
 * Checks userCounty against the values in dbCounties to determine if a match can be made.
 * Will return that match if able to determine with great certainty, or dbCounties sorted
 * by best levenshtein distance (compares with and without common endings,
 * e.g. County, Municipality, Municipio, etc).
 * @param userCounty County name entered by user
 * @param dbCounties List of all county names to compare against
 * @returns Object where match is if a match was found,
 * and possibilities are the dbCounties in order by closest levenshtein distance.
 * If match is true then possibilities just has the one value from dbCounties that matched
 * (in case there was a difference in casing or user value didn't include 'County' or similar at the end).
 */
export const compareCountyNames = (
  userCounty: string,
  dbCounties: string[]
): { match: boolean; possibilities: string[] } => {
  const removeCommonCountyEnds = (countyName: string): string => {
    return countyName.replaceAll(
      / municipality| borough| census area| county| planning region| parish| city| municipio| island/gi,
      ''
    );
  };
  const lowerUser = userCounty
    .toLocaleLowerCase()
    .trim()
    .replaceAll('&', 'and');
  const dbMatch = dbCounties.find(
    (it) =>
      it.toLocaleLowerCase().trim() === lowerUser ||
      removeCommonCountyEnds(it.toLocaleLowerCase()).trim() === lowerUser
  );
  if (dbMatch) {
    return { match: true, possibilities: [dbMatch] };
  }
  const levenshteined = dbCounties
    .map((it) => {
      const lowerDB = it.toLocaleLowerCase();
      const straight = levenshtein(lowerDB, lowerUser);
      const dbDropEnds = levenshtein(
        removeCommonCountyEnds(lowerDB),
        lowerUser
      );
      const dropBoth = levenshtein(
        removeCommonCountyEnds(lowerDB),
        removeCommonCountyEnds(lowerUser)
      );
      return {
        name: it,
        levenshtein: Math.min(straight, dbDropEnds, dropBoth),
      };
    })
    .sort((a, b) => a.levenshtein - b.levenshtein)
    .map((it) => it.name);
  return { match: false, possibilities: levenshteined };
};
