const oktaIssuer = window.location.hostname.includes('-internal')
  ? window.env.OKTA_INTERNAL_ISSUER
  : window.env.OKTA_EXTERNAL_ISSUER;

const oktaClientId = window.location.hostname.includes('-internal')
  ? window.env.OKTA_INTERNAL_CLIENT_ID
  : window.env.OKTA_EXTERNAL_CLIENT_ID;

const config = {
  oidc: {
    issuer: oktaIssuer || '',
    clientId: oktaClientId || '',
    redirectUri: `${window.location.origin}${window.env.OKTA_REDIRECT_PATH}`,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
  },
  widget: {
    issuer: oktaIssuer || '',
    clientId: oktaClientId || '',
    redirectUri: `${window.location.origin}${window.env.OKTA_REDIRECT_PATH}`,
    scopes: ['openid', 'profile', 'email'],
    useInteractionCodeFlow: false,
    logo: '/fcc-logo-lg.png',
  },
};

export default config;
