import { useEffect, useRef, useState, Suspense, lazy, RefObject } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import MainMenu from './components/MainMenu';
import { SectionHeaderContext } from './components/SectionHeader';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ApiContext } from './features/api';
import { V1 } from './features/api/v1';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
  disclaimerAccepted,
  selectUser,
  setDisclaimerAccepted,
} from './features/session';
import './App.scss';
import { dirsDescription } from './features/const';
import Alert from './components/Alert';
import { Link } from 'react-router-dom';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Okta from './features/okta';
import OktaSessionManager from './components/OktaSessionManager';
import withClearCache from './ClearCache';
import DisclaimerGovernment from './components/DisclaimerGovernment';
import { Col, Row } from 'react-bootstrap';
import SessionNotifications from './components/SessionNotifications';
import DialogSignedOut from './dialogs/DialogSignedOut';
import FileMonitor from './components/FileMonitor';
import { getPrimaryRole } from './models/Role';
import dayjs from 'dayjs';
import SessionExpiring from './dialogs/SessionExpiring';
import {
  SelectedCompany,
  SelectedCompanyContext,
  useSelectedCompany,
} from './features/SelectedCompanyContext';
import { Company as CompanyModel } from './models';
import { withErrorBoundary } from './components/ErrorBoundary';
import PreparingPortal from './routes/Home/PreparingPortal';

const Home = lazy(() => import('./routes/Home/Home'));
const Logout = lazy(() => import('./routes/User/Logout'));
const Company = lazy(() => import('./routes/Company'));
const CompanyContacts = lazy(() => import('./routes/Company/CompanyContacts'));
const MaintainCompanyInfo = lazy(
  () => import('./routes/Company/MaintainCompanyInfo')
);
const ManageCompanyUsers = lazy(
  () => import('./routes/Company/ManageCompanyUsers')
);
const Reports = lazy(() => import('./routes/Reports'));
const SituationReport = lazy(() => import('./routes/Reports/SituationReport'));
const FilingStatusReport = lazy(
  () => import('./routes/Reports/FilingStatusReport')
);
const ComprehensiveReport = lazy(
  () => import('./routes/Reports/ComprehensiveReport')
);
const AffectedInstallations = lazy(
  () => import('./routes/Reports/AffectedInstallations')
);
const NumberOfCompanies = lazy(
  () => import('./routes/Reports/NumberOfCompanies')
);
const SummaryReport = lazy(() => import('./routes/Reports/Summary'));
const Dashboard = lazy(() => import('./routes/Reports/Dashboard'));
const User = lazy(() => import('./routes/User'));
const UpdateUser = lazy(() => import('./routes/User/UpdateUser'));
const NewUser = lazy(() => import('./routes/User/New/NewUser'));
const MaintainUser = lazy(() => import('./routes/User/MaintainUser'));
const MaintainUserInfo = lazy(() => import('./routes/User/MaintainUserInfo'));
const Disaster = lazy(() => import('./routes/Disaster'));
const DisasterMaps = lazy(() => import('./routes/Maps/DisasterMaps'));
const WirelessCoverageMaps = lazy(
  () => import('./routes/Maps/WirelessCoverageMaps')
);
const Psaps = lazy(() => import('./routes/Psaps'));
const CityList = lazy(() => import('./routes/CityList'));
const HelpRequest = lazy(() => import('./routes/HelpRequest'));
const HelpRequestsView = lazy(() => import('./routes/HelpRequestsView'));
const Files = lazy(() => import('./routes/User/Files'));
const NotFound = lazy(() => import('./routes/NotFound'));

interface AppContentProps {
  setHeaderRef: (ref: HTMLDivElement | null) => void;
}

const oktaAuth = new OktaAuth(Okta.oidc);

const MenuContent = () => {
  const location = useLocation();
  const [mainMenuCollapsed, setMainMenuCollapsed] = useState(true);

  const toggleMainMenu = (closed?: boolean) => {
    if (closed !== undefined) setMainMenuCollapsed(closed);
    else setMainMenuCollapsed((prev) => !prev);
  };

  useEffect(() => {
    setMainMenuCollapsed(true);
    setTimeout(() => window.scrollTo(0, 0), 250);
  }, [location.pathname]);

  return (
    <MainMenu
      toggleCallback={toggleMainMenu}
      collapsed={mainMenuCollapsed}
      inline={false}
      // inline={location.pathname === '/'}
    />
  );
};

const AppContent = ({ setHeaderRef }: AppContentProps) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const hideDisclaimer = useAppSelector(disclaimerAccepted);
  const customAuthHandler = () => navigation('/login');
  const currentUser = useAppSelector(selectUser);
  const selectedComp = useSelectedCompany();

  const tokenTimer = useRef<number | null>(0);
  const [tokenExpireTime, setTokenExpireTime] = useState<dayjs.Dayjs | null>(
    null
  );
  const wasSignedIn = useRef<boolean>(false);

  const [showExpiring, setShowExpiring] = useState<boolean>(false);
  const [doRefresh, setDoRefresh] = useState<boolean>(false);
  const [userLoggedOut, setUserLoggedOut] = useState<boolean>(false);

  const [showSignedOut, setShowSignedOut] = useState(
    new URL(window.location.toString()).searchParams.get('signedout') !== null
  );

  const [mainContentId, setMainContentId] = useState<string>('main-content');

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigation(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  };

  useEffect(() => {
    setMainContentId('main-content');
    document.location.href = '#';
  }, [location]);

  useEffect(() => {
    const interval = setInterval(() => {
      oktaAuth
        .isAuthenticated({ onExpiredToken: 'remove' })
        .then((tokensAlive) => {
          if (tokensAlive) {
            oktaAuth.tokenManager.getTokens().then((tokens) => {
              setTokenExpireTime(
                dayjs.unix(tokens.accessToken?.expiresAt || 0)
              );
            });
            if (showSignedOut) {
              setShowSignedOut(false);
              window.location.href = '/';
            }
          } else if (
            wasSignedIn.current &&
            !userLoggedOut &&
            currentUser !== null
          ) {
            window.location.href = '/?signedout';
          } else if (userLoggedOut) {
            setUserLoggedOut(false);
          }
          wasSignedIn.current = tokensAlive;
        });
    }, 5000);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tokenExpireTime)
      setShowExpiring(tokenExpireTime.subtract(5, 'minutes').diff() <= 0);
  }, [tokenExpireTime]);

  useEffect(() => {
    if (
      !location.pathname.includes('/?signedout') &&
      !location.pathname.includes('/logout')
    )
      oktaAuth.isAuthenticated().then((isAuth) => {
        if (isAuth) {
          oktaAuth.tokenManager.getTokens().then((tokens) => {
            if (tokens.accessToken && tokens.idToken) {
              oktaAuth.token
                .renewTokens()
                .then((renewedTokens) => {
                  oktaAuth.tokenManager.setTokens(renewedTokens);
                  setTokenExpireTime(
                    dayjs.unix(renewedTokens.accessToken?.expiresAt || 0)
                  );
                })
                .finally(() => {
                  if (tokenTimer.current) {
                    window.clearTimeout(tokenTimer.current);
                  }
                  tokenTimer.current = null;
                });
            }
          });
        }
      });
  }, [location.pathname, doRefresh]);

  useEffect(() => {
    if (currentUser && selectedComp.company === null) {
      const role = getPrimaryRole(currentUser.roles)?.name;
      const isCompanyUser = role === 'coordinator' || role === 'inputter';
      const setCompany = (company: CompanyModel) => {
        const sessionCompany = sessionStorage.getItem('selectedCompany');
        if (
          sessionCompany &&
          currentUser.companies?.some(
            (userComps) => userComps.id === JSON.parse(sessionCompany).id
          )
        ) {
          selectedComp.setCompany(JSON.parse(sessionCompany));
        } else {
          selectedComp.setCompany(company);
        }
      };
      if (isCompanyUser && currentUser.companies?.length) {
        const comp = [...currentUser.companies].sort((a, b) =>
          a.name.localeCompare(b.name)
        )[0];
        setCompany(comp);
      }
    }
  }, [currentUser, selectedComp]);

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <OktaSessionManager />
      <DialogSignedOut
        show={showSignedOut}
        onDismiss={() => setShowSignedOut(false)}
      />
      {!hideDisclaimer && !showSignedOut && !showExpiring && (
        <DisclaimerGovernment
          onAccept={() => dispatch(setDisclaimerAccepted(true))}
        />
      )}
      <SessionExpiring
        show={showExpiring && !showSignedOut}
        onHide={(refresh: boolean) => {
          setShowExpiring(false);
          if (refresh) {
            setDoRefresh((prev) => !prev);
          } else {
            setUserLoggedOut(true);
            navigation('/logout');
          }
        }}
        tokenExpireTime={tokenExpireTime}
      />
      <SessionNotifications />
      <Header mainContentId={mainContentId} />
      <div ref={setHeaderRef} />
      {location.pathname !== '/helprequest' && (
        <Alert
          purpose="critical need of help"
          type={'info'}
          align={'center'}
          printable={false}
        >
          <Link to={'/helprequest'}>
            Companies in critical need of help (e.g., generators, fuel, portable
            cell towers) <em>click here.</em>
          </Link>
        </Alert>
      )}

      <Row className="app__content">
        <MenuContent />
        <Col className="app__content__wrapper">
          <Row as="main">
            <Suspense fallback={<PreparingPortal />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/logout" element={<Logout />} />
                <Route
                  path="company/new"
                  element={<Company setMainContentId={setMainContentId} />}
                />
                <Route
                  path="company/:id"
                  element={<Company setMainContentId={setMainContentId} />}
                />
                <Route
                  path="company/contacts/:disaster/:newUp"
                  element={<CompanyContacts />}
                />
                <Route path="company/contacts" element={<CompanyContacts />} />
                <Route
                  path="company/maintain"
                  element={<MaintainCompanyInfo />}
                />
                <Route
                  path="company/:id/users"
                  element={
                    <ManageCompanyUsers setMainContentId={setMainContentId} />
                  }
                />
                <Route path="reports" element={<Reports />} />
                <Route
                  path="report/situation"
                  element={
                    <SituationReport setMainContentId={setMainContentId} />
                  }
                />
                <Route
                  path="report/filingstatus"
                  element={
                    <FilingStatusReport setMainContentId={setMainContentId} />
                  }
                />
                <Route
                  path="report/comprehensive"
                  element={
                    <ComprehensiveReport setMainContentId={setMainContentId} />
                  }
                />
                <Route
                  path="report/affectedinstallations"
                  element={
                    <AffectedInstallations
                      setMainContentId={setMainContentId}
                    />
                  }
                />
                <Route
                  path="report/numberofcompanies"
                  element={
                    <NumberOfCompanies setMainContentId={setMainContentId} />
                  }
                />
                <Route
                  path="report/summary"
                  element={
                    <SummaryReport setMainContentId={setMainContentId} />
                  }
                />
                <Route path="report/dashboard" element={<Dashboard />} />
                <Route path="user/:id" element={<User />} />
                <Route
                  path="user/:id/update"
                  element={<UpdateUser setMainContentId={setMainContentId} />}
                />
                <Route path="user/new" element={<NewUser />} />
                <Route
                  path="user/:id/maintain"
                  element={<MaintainUser setMainContentId={setMainContentId} />}
                />
                <Route
                  path="users/maintain"
                  element={
                    <MaintainUserInfo setMainContentId={setMainContentId} />
                  }
                />
                <Route
                  path="disaster/add"
                  element={<Disaster setMainContentId={setMainContentId} />}
                />
                <Route
                  path="disaster/:id"
                  element={<Disaster setMainContentId={setMainContentId} />}
                />
                <Route
                  path="disaster/:id/update"
                  element={<Disaster setMainContentId={setMainContentId} />}
                />
                <Route path="maps/disaster" element={<DisasterMaps />} />
                <Route
                  path="maps/wirelesscoverage"
                  element={<WirelessCoverageMaps />}
                />
                <Route
                  path="psaps"
                  element={<Psaps setMainContentId={setMainContentId} />}
                />
                <Route
                  path="citylist"
                  element={<CityList setMainContentId={setMainContentId} />}
                />
                <Route
                  path="helprequest"
                  element={<HelpRequest setMainContentId={setMainContentId} />}
                />
                <Route
                  path="criticalneedrequests"
                  element={
                    <HelpRequestsView setMainContentId={setMainContentId} />
                  }
                />
                <Route path="files" element={<Files />} />
                <Route path="redirect" element={<LoginCallback />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </Row>
          <Footer />
        </Col>
      </Row>
      {currentUser &&
        getPrimaryRole(currentUser.roles)?.name !== 'inputter' && (
          <FileMonitor />
        )}
    </Security>
  );
};

const ClearCacheApp = withClearCache(AppContent);

const App = () => {
  const [sectionHeaderRef, setSectionHeaderRef] =
    useState<HTMLDivElement | null>(null);

  const [userCompany, setUserCompany] = useState<CompanyModel | null>(null);
  const userCompanyValue: SelectedCompany = {
    company: userCompany,
    setCompany: (company: CompanyModel | null) => {
      setUserCompany(company);
      if (company) {
        sessionStorage.setItem('selectedCompany', JSON.stringify(company));
      } else {
        sessionStorage.removeItem('selectedCompany');
      }
    },
  };

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <title>Disaster Information Reporting System (DIRS)</title>
          <meta name="description" content={dirsDescription} />
          <meta name="theme-color" content="#13418b" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Disaster Information Reporting System (DIRS)"
          />
          <meta property="og:description" content={dirsDescription} />
          <meta
            property="og:image"
            content={'/social-media-sharing-fcc-logo.jpg'}
          />
        </Helmet>
        <ApiContext.Provider value={V1}>
          <SelectedCompanyContext.Provider value={userCompanyValue}>
            <SectionHeaderContext.Provider value={sectionHeaderRef}>
              <ClearCacheApp setHeaderRef={setSectionHeaderRef} />
            </SectionHeaderContext.Provider>
          </SelectedCompanyContext.Provider>
        </ApiContext.Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default withErrorBoundary(App);
