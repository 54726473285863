import { Feature, MultiPolygon, Properties } from '@turf/turf';

// Long Text
export const dirsDescription =
  'The FCC established the Disaster Information Reporting System (DIRS) on September 11, 2007, in response to the devastation caused by Hurricane Katrina. DIRS is a voluntary, web-based system through which the Commission collects operational status and restoration information from communications providers during major disasters and subsequent recovery efforts. DIRS provides communications providers with a single, coordinated, consistent process to report their communications infrastructure status information during disasters.';
export const fccAccessWarning =
  'You are accessing a U.S. Government information system provided by the Federal Communications Commission for FCC-authorized users only. All communication occurring on this information system are subject to be monitored, recorded, and audited, therefore you have no reasonable expectation of privacy regarding any communications or data transmitted or stored on this information system. Unauthorized or improper use of the information system or information stored on this information system is prohibited and subject to criminal, civil, and/or administrative penalties. Any data transmitted or stored on this information system may be disclosed to law enforcement or other Federal agencies or their contractors in the course of conducting an investigation or other forensic analysis.  USE OF THIS INFORMATION SYSTEM INDICATES CONSENT TO MONITORING AND RECORDING.';

// Map Dropdown Options
export const DisasterMapDatasetList = [
  'Major Equipment Down',
  'Blocked Calls by State',
  'Broadcast Station Status',
  'Cell Sites Down by County',
  'Percent of Cell Sites Down by County',
  'PSAPs Out by County',
  'PSAP Customers Affected by County',
  'Lines Out of Service',
  'Switches Down or At Risk',
  'Video Subscribers Down by County',
  'Percent of Video Subscribers Down by County',
  'VoIP Subscribers Down by County',
  'Percent of VoIP Subscribers Down by County',
  'Telephone Subscribers Down by County',
  'Percent of Telephone Subscribers Down by County',
  'Broadband Subscribers Down by County',
  'Percent of Broadband Subscribers Down by County',
  'Affected Counties',
] as const;

export const DashboardDatasetList = [
  'PSAPs Status',
  'Major Equipment Down',
  'Wireline Customer Status',
  'DS3 Status',
  'TSP Status',
  'Cell Sites Status',
  'Broadcast Station Status',
  'Percent of Video Subscribers Down by County',
  'Percent of VoIP Subscribers Down by County',
  'Percent of Broadband Subscribers Down by County',
] as const;

export const USBBox: Feature<MultiPolygon, Properties> = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'MultiPolygon',
    coordinates: [
      [
        [
          [-124.73364306703067, 24.51490854927549],
          [-66.9819027206272, 24.51490854927549],
          [-66.9819027206272, 49.00240502974029],
          [-124.73364306703067, 49.00240502974029],
          [-124.73364306703067, 24.51490854927549],
        ],
      ],
      [
        [
          [-179.13657211802118, 51.229087747767466],
          [179.77488070600702, 51.229087747767466],
          [179.77488070600702, 71.352561],
          [-179.13657211802118, 71.352561],
          [-179.13657211802118, 51.229087747767466],
        ],
      ],
      [
        [
          [-170.84530299432993, -14.373864584355843],
          [-169.42394257312571, -14.373864584355843],
          [-169.42394257312571, -14.157381542325423],
          [-170.84530299432993, -14.157381542325423],
          [-170.84530299432993, -14.373864584355843],
        ],
      ],
      [
        [
          [144.62133533915335, 13.245763528025279],
          [144.9587289744897, 13.245763528025279],
          [144.9587289744897, 13.652098761677614],
          [144.62133533915335, 13.652098761677614],
          [144.62133533915335, 13.245763528025279],
        ],
      ],
      [
        [
          [-160.24970712717126, 18.91727560534605],
          [-154.80833743387433, 18.91727560534605],
          [-154.80833743387433, 22.23238695135951],
          [-160.24970712717126, 22.23238695135951],
          [-160.24970712717126, 18.91727560534605],
        ],
      ],
      [
        [
          [145.12024720417202, 14.110836636456362],
          [146.0821779942799, 14.110836636456362],
          [146.0821779942799, 18.81247032309323],
          [145.12024720417202, 18.81247032309323],
          [145.12024720417202, 14.110836636456362],
        ],
      ],
      [
        [
          [-67.94024421674217, 17.91217576734767],
          [-65.22314866408664, 17.91217576734767],
          [-65.22314866408664, 18.51609472983729],
          [-67.94024421674217, 18.51609472983729],
          [-67.94024421674217, 17.91217576734767],
        ],
      ],
      [
        [
          [-65.08316619836198, 17.679370591195905],
          [-64.57707574535745, 17.679370591195905],
          [-64.57707574535745, 18.38465859717597],
          [-65.08316619836198, 18.38465859717597],
          [-65.08316619836198, 17.679370591195905],
        ],
      ],
      [
        [
          [130, 1],
          [172, 1],
          [172, 22],
          [130, 22],
          [130, 1],
        ],
      ],
    ],
  },
};

export const OUTSIDE_USA =
  'Latitude and Longitude is outside US controlled territory';

//////////////////
////// FORM //////
//////////////////

// Validation
export const FORM_REQUIRED_FIELD = 'This is a required field.';
export const FORM_INVALID_PHONE =
  'Invalid Phone Number. Must be a US number (###-###-####).';
export const FORM_INVALID_PHONE_EXTENSION =
  'Extension must be a positive number.';
export const FORM_PHONE_OR_CELL = 'Phone or cell is required.';
export const FORM_INVALID_EMAIL =
  'Invalid email address. (ex. doej@company.com)';
export const FORM_FIX_VALIDATION_ERRORS =
  'Please fix all errors before submitting';
export const FORM_MUST_BE_NUMBER = 'Value must be a number';
export const FORM_MUST_AGREE = 'Agreement Required';
// Submit Button
export const FORM_ARIA_LABEL_SUBMIT_DISABLED =
  'Submit button is disabled while form is submitting.';
export const FORM_AIRA_LABEL_SUBMIT_ENABLED = 'Submit the form.';
export const FORM_TEXT_TOO_LONG = 'Too many characters entered.';

//// Reports ///
export const FORM_SECTION_TITLE = 'Request Form';
export const REPORT_NO_DATA = 'No data found';
export const REPORT_SUBMIT_TEXT = 'Generate Report';
export const REPORT_SECTION_TITLE = 'Generated Report';

///////////////
/// REPORTS ///
///////////////

export const REPORT_KEYS = [
  'id',
  'disasterId',
  'userIdOfUploader',
  'companyId',
  'reportNumber',
  'notes',
  'createdByUserId',
  'createdDate',
  'updatedByUserId',
  'updatedDate',
] as const;
