import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { humanReadable } from '../../features/utils';
import { State, statesToTypeahead } from '../Location';

export namespace InterofficeFacilitiesTspReport {
  export interface Data extends Reports.Report {
    numberOfDs3sAffectedDown: number;
    numberOfTspCircuitsDown: number;
    stateId: number;
    legacyClliCodeForFacilityStart: string;
    legacyClliCodeForFacilityEnd: string;
  }
  export const DataKeys: string[] = [
    ...REPORT_KEYS,
    'numberOfDs3sAffectedDown',
    'numberOfTspCircuitsDown',
    'stateId',
    'legacyClliCodeForFacilityStart',
    'legacyClliCodeForFacilityEnd',
  ];

  export const fieldRowParams = (
    key: keyof Data,
    states: State[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
  } | null => {
    if (
      key === 'legacyClliCodeForFacilityStart' ||
      key === 'legacyClliCodeForFacilityEnd'
    ) {
      return null;
    } else if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isRequired: key === 'numberOfDs3sAffectedDown',
        },
        isNumber:
          key === 'numberOfDs3sAffectedDown' ||
          key === 'numberOfTspCircuitsDown',
      };
    }
  };

  export const onRenderValue = (value: any) => {
    return typeof value === 'number' || value ? `${value}` : '';
  };
}
export default InterofficeFacilitiesTspReport;
