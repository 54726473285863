import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { TypeaheadFormObj } from '../../features/types';
import { humanReadable } from '../../features/utils';
import { State, statesToTypeahead } from '../Location';
import { LookupStatusAndType } from '../LookupStatusAndType';

export namespace MajorEquipmentReport {
  export interface Data extends Reports.Report {
    lookupMajorEquipmentType: number;
    clli: string;
    lookupStatusEquipment: number;
    numberOfAccessLines: number;
    numberOfWorkingNumbers: number;
    numberOfWorkingNumbersDown: number;
    numberOfVoipSubscribers: number;
    numberOfVoipSubscribersDown: number;
    numberOfVideoSubscribers: number;
    numberOfVideoSubscribersDown: number;
    numberOfBroadbandAccessUsers: number;
    numberOfBroadbandAccessUsersDown: number;
    lookupStatusPower: number;
    lookupStatusGenerator: number;
    latitude: number;
    longitude: number;
    address: string;
    city: string;
    stateId: number;
  }
  export const DataKeys: string[] = [
    ...REPORT_KEYS,
    'lookupMajorEquipmentType',
    'clli',
    'lookupStatusEquipment',
    'numberOfAccessLines',
    'numberOfWorkingNumbers',
    'numberOfWorkingNumbersDown',
    'numberOfVoipSubscribers',
    'numberOfVoipSubscribersDown',
    'numberOfVideoSubscribers',
    'numberOfVideoSubscribersDown',
    'numberOfBroadbandAccessUsers',
    'numberOfBroadbandAccessUsersDown',
    'lookupStatusPower',
    'lookupStatusGenerator',
    'latitude',
    'longitude',
    'address',
    'city',
    'stateId',
  ];

  const reorderStatus = (label: string) => {
    let parts = label.split(' ');
    if (parts[0] === 'Status') {
      const status = parts.shift();
      parts.push(status!);
    }
    return parts.join(' ');
  };

  export const fieldRowParams = (
    key: keyof Data,
    states: State[],
    lookupTable: LookupStatusAndType[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
    theseOrThose?: [(keyof Data)[], (keyof Data)[]][];
  } | null => {
    if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
          isConditionallyRequired: true,
        },
        isNumber: true,
        theseOrThose: [
          [
            ['latitude', 'longitude'],
            ['address', 'city', 'stateId'],
          ],
        ],
      };
    } else if (key.includes('lookup')) {
      const options = lookupTable
        .filter(
          (lookup) =>
            lookup.category ===
            key.replace(/([A-Z])/g, '_$1').toLocaleLowerCase()
        )
        .map(
          (lookup): TypeaheadFormObj => ({
            id: lookup.id,
            label: lookup.name,
          })
        );
      return {
        props: {
          key: key,
          label: reorderStatus(humanReadable(key.replace('lookup', ''))),
          name: key,
          type: 'typeahead',
          options: options,
          isRequired: key !== 'lookupStatusGenerator',
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isRequired: key === 'clli',
          isConditionallyRequired:
            key === 'numberOfWorkingNumbers' ||
            key === 'numberOfWorkingNumbersDown' ||
            key === 'numberOfBroadbandAccessUsers' ||
            key === 'numberOfBroadbandAccessUsersDown' ||
            key === 'latitude' ||
            key === 'longitude' ||
            key === 'address' ||
            key === 'city',
          placeholder:
            key === 'latitude' || key === 'longitude'
              ? '###.######'
              : undefined,
        },
        isNumber:
          key === 'latitude' || key === 'longitude' || key.includes('number'),
        theseOrThose:
          key === 'numberOfWorkingNumbers' ||
          key === 'numberOfWorkingNumbersDown' ||
          key === 'numberOfBroadbandAccessUsers' ||
          key === 'numberOfBroadbandAccessUsersDown' ||
          key === 'latitude' ||
          key === 'longitude' ||
          key === 'address' ||
          key === 'city'
            ? [
                [
                  ['latitude', 'longitude'],
                  ['address', 'city', 'stateId'],
                ],
                [
                  ['numberOfWorkingNumbers', 'numberOfWorkingNumbersDown'],
                  [
                    'numberOfBroadbandAccessUsers',
                    'numberOfBroadbandAccessUsersDown',
                  ],
                ],
              ]
            : undefined,
      };
    }
  };

  export const onRenderValue = (value: any) => {
    return typeof value === 'number' || value ? `${value}` : '';
  };
}

export default MajorEquipmentReport;
