import React from 'react';
import packageJson from '../package.json';
import dayjs from 'dayjs';

const buildDateGreaterThan = (latestDate: number, currentDate: number) => {
  const dayLatestDateTime = dayjs(latestDate);
  const dayCurrentDateTime = dayjs(currentDate);

  if (dayLatestDateTime.isAfter(dayCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

function withClearCache<P>(Component: React.ComponentType<P>) {
  return function (props: P) {
    //const [isLatestBuildDate, setIsLatestBuildDate] = React.useState(false);

    const refreshCacheAndReload = () => {
      if (caches) {
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      window.location.reload();
    };

    React.useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = Number(meta.buildDate);
          const currentVersionDate = Number(packageJson.buildDate);
          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            //setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } /*else {
            setIsLatestBuildDate(true);
          }*/
        });
    }, []);
    return <Component {...props} />;
  };
}

export default withClearCache;
