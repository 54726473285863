import { Col, Modal, Row } from 'react-bootstrap';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import Button from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import './TutorialDialog.scss';
import React from 'react';
import pages from './pages';

interface TutorialDialogProps {
  show: boolean;
  onHide: () => void;
}

const TutorialDialog = ({ show, onHide }: TutorialDialogProps) => {
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  return (
    <Modal
      aria-label="tutorial dialog"
      className="tutorial-dialog"
      size="xl"
      show={show}
      onHide={() => {
        onHide();
        setCurrentPage(0);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Learning DIRS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tutorial-dialog__container">
          <Row>
            <Col xs={2}>
              <Button
                type="custom"
                onClick={() => {
                  if (currentPage > 0) setCurrentPage((prev) => --prev);
                }}
                className={`tutorial-dialog__container__chevron ${
                  currentPage === 0 ? 'disabled' : ''
                }`}
                label="Previous Page"
                showLabel={false}
                disabled={currentPage === 0}
              >
                <FontAwesomeIcon icon={solid('chevron-left')} />
              </Button>
            </Col>
            <Col className="tutorial-dialog__container__main-content">
              {pages[currentPage]}
            </Col>
            <Col xs={2}>
              <Button
                type="custom"
                onClick={() => {
                  if (currentPage < pages.length - 1)
                    setCurrentPage((prev) => ++prev);
                }}
                className={`tutorial-dialog__container__chevron ${
                  currentPage === pages.length - 1 ? 'disabled' : ''
                }`}
                label="Next Page"
                showLabel={false}
                disabled={currentPage === pages.length - 1}
              >
                <FontAwesomeIcon icon={solid('chevron-right')} />
              </Button>
            </Col>
          </Row>
          <Row>
            <div className="tutorial-dialog__container__dots">
              {pages.map((it, idx) => (
                <Button
                  type="custom"
                  onClick={() => setCurrentPage(idx)}
                  label={`Go to page ${idx + 1}`}
                  showLabel={false}
                  className={`tutorial-dialog__container__dots__dot ${
                    currentPage === idx ? 'selected' : ''
                  }`}
                  key={`dot${idx.toString()}`}
                />
              ))}
            </div>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="tutorial-dialog__container__footer">
        <Button
          type="primary"
          label="Download Full User Guide"
          onClick={() => {
            window.location.assign('/DIRS User Guide.docx');
          }}
          className="tutorial-dialog__download-btn"
        />
        <a href="mailto:DIRSHelp@fcc.gov?subject=DIRS Help">
          If you need assistance please contact DIRSHelp@fcc.gov
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default withErrorBoundary(TutorialDialog);
