import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { TypeaheadFormObj } from '../../features/types';
import { humanReadable } from '../../features/utils';
import { State, statesToTypeahead } from '../Location';
import { LookupStatusAndType } from '../LookupStatusAndType';

export namespace SatelliteReport {
  export interface Data extends Reports.Report {
    lookupSatelliteTypeOfEquipment: number;
    stationIdentifierCallSign: string;
    lookupSatelliteStatus: number;
    totalNumberOfAntennas: number;
    numberOfAntennasDown: number;
    numberOfTemporaryAntennasInstalled: number;
    lookupStatusPowerAntennas: number;
    lookupStatusGenerator: number;
    latitude: number;
    longitude: number;
    address: string;
    city: string;
    stateId: number;
  }
  export const DataKeys: string[] = [
    ...REPORT_KEYS,
    'lookupSatelliteTypeOfEquipment',
    'stationIdentifierCallSign',
    'lookupSatelliteStatus',
    'totalNumberOfAntennas',
    'numberOfAntennasDown',
    'numberOfTemporaryAntennasInstalled',
    'lookupStatusPowerAntennas',
    'lookupStatusGenerator',
    'latitude',
    'longitude',
    'address',
    'city',
    'stateId',
  ];

  const lookupKeyToCategory = (lookupKey: string): string => {
    switch (lookupKey) {
      case 'lookupStatusPowerAntennas':
        return 'lookup_status_power';
      case 'lookupStatusGenerator':
        return 'lookup_status_satellite_generator';
      default:
        return lookupKey.replace(/([A-Z])/g, '_$1').toLocaleLowerCase();
    }
  };

  const reorderStatus = (label: string) => {
    let parts = label.split(' ');
    if (parts[0] === 'Status') {
      const status = parts.shift();
      parts.push(status!);
    }
    return parts.join(' ');
  };

  export const fieldRowParams = (
    key: keyof Data,
    states: State[],
    lookupTable: LookupStatusAndType[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
    theseOrThose?: [(keyof Data)[], (keyof Data)[]][];
  } | null => {
    if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
          isConditionallyRequired: true,
        },
        theseOrThose: [
          [
            ['latitude', 'longitude'],
            ['address', 'city', 'stateId'],
          ],
        ],
      };
    } else if (key.includes('lookup')) {
      const options = lookupTable
        .filter((lookup) => lookup.category === lookupKeyToCategory(key))
        .map(
          (lookup): TypeaheadFormObj => ({
            id: lookup.id,
            label: lookup.name,
          })
        );
      return {
        props: {
          key: key,
          label: reorderStatus(humanReadable(key.replace('lookup', ''))),
          name: key,
          type: 'typeahead',
          options: options,
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isConditionallyRequired:
            key === 'latitude' ||
            key === 'longitude' ||
            key === 'address' ||
            key === 'city',
          isRequired: key === 'stationIdentifierCallSign',
          placeholder:
            key === 'latitude' || key === 'longitude'
              ? '###.######'
              : undefined,
        },
        isNumber:
          key === 'latitude' ||
          key === 'longitude' ||
          key === 'totalNumberOfAntennas' ||
          key === 'numberOfAntennasDown' ||
          key === 'numberOfTemporaryAntennasInstalled',
        theseOrThose:
          key === 'latitude' ||
          key === 'longitude' ||
          key === 'address' ||
          key === 'city'
            ? [
                [
                  ['latitude', 'longitude'],
                  ['address', 'city', 'stateId'],
                ],
              ]
            : undefined,
      };
    }
  };

  export const onRenderValue = (value: any) => {
    return typeof value === 'number' || value ? `${value}` : '';
  };
}

export default SatelliteReport;
