import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { TypeaheadFormObj } from '../../features/types';
import { humanReadable } from '../../features/utils';
import { State, statesToTypeahead } from '../Location';
import { LookupStatusAndType } from '../LookupStatusAndType';

export namespace RemoteAggregationDevicesReport {
  export interface Data extends Reports.Report {
    lookupRadServiceAffected: number;
    clliOfSwitchGeographicArea: string;
    numberOfRadDown: number;
    numberOfWorkingNumbersServed: number;
    numberOfWorkingNumbersDown: number;
    stateId: number;
  }
  export const DataKeys: string[] = [
    ...REPORT_KEYS,
    'lookupRadServiceAffected',
    'clliOfSwitchGeographicArea',
    'numberOfRadDown',
    'numberOfWorkingNumbersServed',
    'numberOfWorkingNumbersDown',
    'stateId',
  ];

  const lookupKeyToCategory = (lookupKey: string): string => {
    return lookupKey.replace(/([A-Z])/g, '_$1').toLocaleLowerCase();
  };

  export const fieldRowParams = (
    key: keyof Data,
    states: State[],
    lookupTable: LookupStatusAndType[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
  } | null => {
    if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
        },
      };
    } else if (key === 'lookupRadServiceAffected') {
      const options = lookupTable
        .filter((lookup) => lookup.category === lookupKeyToCategory(key))
        .map(
          (lookup): TypeaheadFormObj => ({
            id: lookup.id,
            label: lookup.name,
          })
        );
      return {
        props: {
          key: key,
          label: 'Service Affected',
          name: key,
          type: 'typeahead',
          options: options,
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isRequired:
            key === 'clliOfSwitchGeographicArea' ||
            key === 'numberOfWorkingNumbersServed' ||
            key === 'numberOfWorkingNumbersDown',
        },
        isNumber:
          key === 'numberOfWorkingNumbersServed' ||
          key === 'numberOfWorkingNumbersDown',
      };
    }
  };

  export const onRenderValue = (value: any) => {
    return typeof value === 'number' || value ? `${value}` : '';
  };
}

export default RemoteAggregationDevicesReport;
