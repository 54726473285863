import React from 'react';
import { Company } from '../models';

export interface SelectedCompany {
  company: Company | null;
  setCompany: (company: Company | null) => void;
}

export const SelectedCompanyContext =
  React.createContext<SelectedCompany | null>(null);

export const useSelectedCompany = () => {
  const context = React.useContext(SelectedCompanyContext);

  if (!context) {
    throw Error('useSelectedCompany must be in a SelectedCompanyContext');
  }
  return context;
};
