import { Container, Modal, Row } from 'react-bootstrap';
import { useApiState } from '../features/api';
import { withErrorBoundary } from '../components/ErrorBoundary';
import React from 'react';
import { FileUploadStatusGetResponse } from '../models/FileUpload';
import { LookupStatusAndType } from '../models/LookupStatusAndType';
import CustomLoadingBar, {
  CustomLoadingBarTypes,
} from '../components/CustomLoadingBar';
import Alert from '../components/Alert';
import './FileUploadProgressDialog.scss';
import { useAppDispatch } from '../app/hooks';
import { removeFileToMonitor, removeNotification } from '../features/session';

interface FileUploadProgressDialogProps {
  show: boolean;
  onHide: () => void;
  statuses: FileUploadStatusGetResponse[];
  uploadingFiles: boolean;
}

const FileUploadProgressDialog = ({
  show,
  onHide,
  statuses,
  uploadingFiles,
}: FileUploadProgressDialogProps) => {
  const { data: lookupStatusAndType } = useApiState<Array<LookupStatusAndType>>(
    'getLookupStatusAndType'
  ).useReduced((data) =>
    data.filter((it) => it.category === 'lookup_status_file_upload')
  );
  const [longWait, setLongWait] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [hiddenStatuses, setHiddenStatuses] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (show)
      window.setTimeout(() => {
        setLongWait(true);
      }, 300000);
    else setLongWait(false);
  }, [show]);

  React.useEffect(() => {
    if (statuses.length > 0) setHiddenStatuses([]);
  }, [statuses]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="xl"
      className="file-upload-processing"
      backdropClassName="file-upload-processing__backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>File Upload Processing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {statuses.length > 0 && uploadingFiles && (
            <Row>
              <p>
                Your file upload is now processing and you have been sent an
                email. Another email will be sent when the process has
                completed.
              </p>
              <p>
                <strong className="file-upload-processing__large">
                  You may close this popup
                </strong>{' '}
                and continue to navigate the site, the notification tray will
                keep you updated on the upload progress.
              </p>
            </Row>
          )}
          {statuses.length === 0 && uploadingFiles && (
            <Row>
              {longWait && (
                <Alert type="warning" purpose="Long wait warning">
                  It's taking longer than normal to get the status of your file.
                  You may continue to wait or contact your Administrator.
                </Alert>
              )}
              <p className="file-upload-processing__pulse">
                Please wait a moment while we get the status of your file.
              </p>
            </Row>
          )}
          {!uploadingFiles && <div className="h2">No Uploads Found</div>}
        </Container>
        <Container>
          <Row>
            <div className="file-upload-processing__progress-bars">
              {statuses
                .filter((it) => !hiddenStatuses.includes(it.id))
                .sort((a, b) => {
                  const aDone = lookupStatusAndType
                    ?.find(
                      (lsat) => Number(lsat.id) === Number(a.latestStatusId)
                    )
                    ?.name.toLocaleLowerCase()
                    .match(/failed|infected|error|failure|completed/);
                  const bDone = lookupStatusAndType
                    ?.find(
                      (lsat) => Number(lsat.id) === Number(b.latestStatusId)
                    )
                    ?.name.toLocaleLowerCase()
                    .match(/failed|infected|error|failure|completed/);
                  if (aDone && !bDone) {
                    return 1;
                  } else if (!aDone && bDone) {
                    return -1;
                  } else {
                    return b.id - a.id;
                  }
                })
                .map((it) => {
                  const statusAndType =
                    lookupStatusAndType?.find(
                      (lsat) => Number(lsat.id) === Number(it.latestStatusId)
                    )?.name || '';
                  let typ: CustomLoadingBarTypes = 'default';
                  if (
                    statusAndType
                      .toLocaleLowerCase()
                      .match(/failed|infected|error|failure/)
                  )
                    typ = 'bad';
                  else if (statusAndType.toLocaleLowerCase().match(/completed/))
                    typ = 'good';
                  return (
                    <CustomLoadingBar
                      key={it.id}
                      title={`${it.fileName}.${it.fileType}`}
                      possibleValues={
                        lookupStatusAndType
                          ?.map((it) => it.name)
                          .filter(
                            (it, idx, array) => array.indexOf(it) === idx
                          ) || []
                      }
                      currentValue={statusAndType}
                      type={typ}
                      onClose={
                        typ !== 'default'
                          ? () => {
                              dispatch(removeNotification(String(it.id)));
                              dispatch(removeFileToMonitor(it.id));
                              setHiddenStatuses((prev) => {
                                const newHidden = [...prev];
                                newHidden.push(it.id);
                                return newHidden;
                              });
                            }
                          : undefined
                      }
                    />
                  );
                })}
            </div>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default withErrorBoundary(FileUploadProgressDialog);
