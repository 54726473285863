import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { TypeaheadFormObj } from '../../features/types';
import { humanReadable } from '../../features/utils';
import { County, State, statesToTypeahead } from '../Location';

export namespace CableSystemReport {
  export interface Data extends Reports.Report {
    county: string;
    psid: string;
    totalNumberOfVideoSubscribersServed: number;
    estimatedNumberOfVideoSubscribersDown: number;
    totalNumberOfVoipSubscribersServed: number;
    estimatedNumberOfVoipSubscribersDown: number;
    totalNumberOfCableTelephoneSubscribersServed: number;
    estimatedNumberOfCableTelephoneSubscribersDown: number;
    totalNumberOfBroadbandAccessSubscribersServed: number;
    estimatedNumberOfBroadbandAccessSubscribersDown: number;
    stateId: number;
  }
  export const DataKeys = [
    ...REPORT_KEYS,
    'stateId',
    'county',
    'psid',
    'totalNumberOfVideoSubscribersServed',
    'estimatedNumberOfVideoSubscribersDown',
    'totalNumberOfVoipSubscribersServed',
    'estimatedNumberOfVoipSubscribersDown',
    'totalNumberOfCableTelephoneSubscribersServed',
    'estimatedNumberOfCableTelephoneSubscribersDown',
    'totalNumberOfBroadbandAccessSubscribersServed',
    'estimatedNumberOfBroadbandAccessSubscribersDown',
  ];

  export const fieldRowParams = (
    key: keyof Data,
    states: State[],
    countyNames: string[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
  } | null => {
    if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
          isRequired: true,
        },
      };
    } else if (key === 'county') {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          type: 'typeahead',
          options: countyNames.map(
            (it): TypeaheadFormObj => ({
              id: it,
              label: it,
            })
          ),
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isRequired: key === 'psid',
        },
        isNumber: key !== 'psid',
      };
    }
  };

  export const onRenderValue = (value: any) => {
    return typeof value === 'number' || value ? `${value}` : '';
  };
}

export default CableSystemReport;
