import { TypeaheadFormObj } from '../features/types';
import { Address } from './Address';

export interface CompanyType {
  id: string;
  name: string;
  createdByUserId: string;
  createdDate: string;
  updatedByUserId?: string;
  updatedDate?: string;
  versionNumber?: string;
}
export interface Company {
  id: number;
  companyType: CompanyType;
  name: string;
  securityCode?: string;
  contactPerson: string;
  address?: Address;
  phoneNumber?: string;
  phoneExtension?: string;
  cellPhoneNumber?: string;
  emailAddress: string;
  isLegacyCompany: boolean;
  createdByUserId: string;
  createdDate: string;
  updatedByUserId?: string;
  updatedDate?: string;
  versionNumber?: string;
}

export interface SimpleCompanyDetails {
  id: number;
  name: string;
  contactPerson: string;
  emailAddress: string;
  phoneNumber?: string;
  phoneExtension?: string;
  cellPhoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export const companyToSimpleCompanyDetails = (
  company: Company
): SimpleCompanyDetails => {
  return {
    id: company.id,
    name: company.name,
    contactPerson: company.contactPerson,
    emailAddress: company.emailAddress,
    phoneNumber: company.phoneNumber,
    phoneExtension: company.phoneExtension,
    cellPhoneNumber: company.cellPhoneNumber,
    addressLine1: company.address?.addressLine1,
    addressLine2: company.address?.addressLine2,
    city: company.address?.city,
    state: company.address?.state,
    postalCode: company.address?.postalCode,
  };
};

export const companyTypesToTypeahead = (
  companyTypes: CompanyType[]
): TypeaheadFormObj[] => {
  return companyTypes.map((ct) => ({
    id: ct.id,
    label: ct.name,
  }));
};

/**
 * Simplifies mapping companies to Typeahead options
 * @param companies Company to map
 * @returns An object usable for Typeahead
 */
export const companiesToTypeahead = (
  companies: (Company | SimpleCompanyDetails)[]
): TypeaheadFormObj[] => {
  return companies
    .map((company) => {
      return { id: String(company.id), label: company.name };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Simplifies mapping companies to Typeahead options with an All option
 * @param companies Company to map
 * @returns An object usable for Typeahead, with an All option
 */
export const companiesToTypeaheadWithAll = (
  companies: Company[]
): TypeaheadFormObj[] => {
  return (
    [
      { id: 'all', label: 'All companies', sortOrder: 'a' },
    ] as TypeaheadFormObj[]
  ).concat(companiesToTypeahead(companies));
};

export const formatCompanyPhoneNumber = (
  company: Company | SimpleCompanyDetails
) =>
  `${company.phoneNumber || ''}${
    company.phoneExtension ? ` Ext: ${company.phoneExtension}` : ''
  }`;

export const formatCompanyAddress = (
  company: Company | SimpleCompanyDetails
) => {
  const addr = Object.hasOwn(company, 'address')
    ? (company as Company).address
    : {
        addressLine1: (company as SimpleCompanyDetails).addressLine1,
        addressLine2: (company as SimpleCompanyDetails).addressLine2,
        city: (company as SimpleCompanyDetails).city,
        state: (company as SimpleCompanyDetails).state,
        postalCode: (company as SimpleCompanyDetails).postalCode,
      };
  if (addr) {
    const address: string[] = [];
    if (addr.addressLine1) address.push(addr.addressLine1);
    if (addr.addressLine2) address.push(addr.addressLine2);
    if (addr.city || addr.state || addr.postalCode)
      address.push(`${addr.city}, ${addr.state} ${addr.postalCode}`);
    return address.join('\n');
  }
  return '';
};
