import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Card.scss';
import { Card } from 'react-bootstrap';
import './SwitchCompanyCard.scss';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface SwitchCompanyCardProps {
  label: string;
  onClick: () => void;
  border?: string;
}

const SwitchCompanyCard = ({
  label,
  border,
  onClick,
}: SwitchCompanyCardProps) => {
  return (
    <Card className="cardRoot" border={border} onClick={onClick}>
      <FontAwesomeIcon
        className="icon"
        icon={solid('people-group')}
        size={'3x'}
      />

      <Card.Body>
        <Card.Text>{label}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default SwitchCompanyCard;
