import './SimpleNotification.scss';

interface SimpleNotificationProps {
  value: string;
}
const SimpleNotification = ({ value }: SimpleNotificationProps) => {
  return (
    <span className={`simple_notification simple_notification__container`}>
      <span className="simple_notification__hidden">{value}</span>
      <span className={`simple_notification__value`}>{value}</span>
    </span>
  );
};

export default SimpleNotification;
