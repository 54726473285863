import { Reports } from '.';
import { REPORT_KEYS } from '../../features/const';
import { TypeaheadFormObj } from '../../features/types';
import { humanReadable } from '../../features/utils';
import { County, State, statesToTypeahead } from '../Location';

export namespace WirelessCellSitesReport {
  export interface Data extends Reports.Report {
    county: string;
    percentOfHistoricalCapacityAvailable: number;
    cellSitesServed: number;
    cellSitesAffectedDown: number;
    cellSitesOutDueToCellSiteDamage: number;
    cellSitesOutDueToTransport: number;
    cellSitesOutDueToNoPowerAtCellSite: number;
    cellSitesOnBackupPower: number;
    stateId: number;
  }
  export const DataKeys: string[] = [
    ...REPORT_KEYS,
    'stateId',
    'county',
    'percentOfHistoricalCapacityAvailable',
    'cellSitesServed',
    'cellSitesAffectedDown',
    'cellSitesOutDueToCellSiteDamage',
    'cellSitesOutDueToTransport',
    'cellSitesOutDueToNoPowerAtCellSite',
    'cellSitesOnBackupPower',
  ];

  export const fieldRowParams = (
    key: keyof Data,
    states: State[],
    countyNames: string[]
  ): {
    props: { [key: string]: any; label: string };
    isNumber?: boolean;
  } | null => {
    if (key === 'stateId') {
      return {
        props: {
          key: key,
          label: 'State',
          name: key,
          type: 'typeahead',
          options: statesToTypeahead(states),
          isRequired: true,
        },
      };
    } else if (key === 'county') {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          type: 'typeahead',
          options: countyNames.map(
            (it): TypeaheadFormObj => ({
              id: it,
              label: it,
            })
          ),
          isRequired: true,
        },
      };
    } else {
      return {
        props: {
          key: key,
          label: humanReadable(key),
          name: key,
          isRequired: [
            'cellSitesServed',
            'cellSitesAffectedDown',
            'cellSitesOutDueToCellSiteDamage',
            'cellSitesOutDueToTransport',
            'cellSitesOutDueToNoPowerAtCellSite',
            'cellSitesOnBackupPower',
          ].includes(key),
        },
        isNumber:
          key === 'cellSitesServed' ||
          key === 'cellSitesAffectedDown' ||
          key === 'cellSitesOutDueToCellSiteDamage' ||
          key === 'cellSitesOutDueToTransport' ||
          key === 'cellSitesOutDueToNoPowerAtCellSite' ||
          key === 'cellSitesOnBackupPower',
      };
    }
  };

  export const onRenderValue = (value: any) => {
    return typeof value === 'number' || value ? `${value}` : '';
  };
}

export default WirelessCellSitesReport;
