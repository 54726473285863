import { TypeaheadFormObj } from '../features/types';
import { Company } from './Company';
import { checkPermission, PermissionIdentifier } from './Permission';
import { Role, RolePermission } from './Role';

export interface User {
  id: string;
  externalId?: string;
  company?: Company;
  companies?: Company[];
  roles: RolePermission[];
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  phoneExtension?: string;
  cellPhoneNumber?: string;
  emailAddress: string;
  isActive: boolean;
  isLegacyUser?: boolean;
  createdByUserId?: string;
  createdDate?: string;
  updatedByUserId?: string;
  updatedDate?: string;
  versionNumber?: string;
}

export interface CreateUpdateUserResponse
  extends Omit<User, 'externalId' | 'company' | 'roles'> {
  roles: Role[];
}

export interface UserSummary {
  userId: string;
  companies?: Company[];
  roles: Role[];
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber?: string;
  phoneExtension?: string;
  cellPhoneNumber?: string;
}

export interface UserExtendedSummary extends Omit<UserSummary, 'userId'> {
  id: number;
  externalId: string;
  isActive: boolean;
  updatedDate: string;
}

export interface UserSimpleSummary
  extends Omit<UserSummary, 'companies' | 'roles' | 'emailAddress'> {
  companies: number[];
  roles: number[];
  email: string;
}

export interface CreateUserRequest {
  companyId?: number;
  companyName?: string;
  typeOfCompany?: number;
  companySecurityCode?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneExtension: string;
  cellPhoneNumber: string;
  emailAddress: string;
  desiredUserType?: number;
  desiredStates?: number[];
  emailNotify?: boolean;
  smsNotify?: boolean;
}

export interface UpdateUserRequest {
  userId: number;
  companyIds: number[];
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneExtension: string;
  cellPhoneNumber: string;
  roles: string[];
  emailAddress: string;
  isActive: boolean;
  emailNotification: boolean;
  smsNotification: boolean;
}

export const userToUserSummary = (user: User): UserSummary => {
  return {
    userId: user.id,
    roles: user.roles?.map((role) => ({
      id: role.id || '',
      name: role.name,
      description: role.description || '',
    })),
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    phoneExtension: user.phoneExtension,
    cellPhoneNumber: user.cellPhoneNumber,
    emailAddress: user.emailAddress,
    companies: user.companies,
  };
};

export const userToUserSimpleSummary = (user: User): UserSimpleSummary => {
  return {
    userId: user.id,
    roles: user.roles?.map((role) => Number(role.id)) ?? [],
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    phoneExtension: user.phoneExtension,
    cellPhoneNumber: user.cellPhoneNumber,
    email: user.emailAddress,
    companies: user.companies?.map((it) => Number(it.id)) ?? [],
  };
};

export const createUpdateUserResponseToUserSummary = (
  updateUserResponse: CreateUpdateUserResponse
): UserSummary => {
  return {
    userId: updateUserResponse.id,
    firstName: updateUserResponse.firstName,
    lastName: updateUserResponse.lastName,
    emailAddress: updateUserResponse.emailAddress,
    phoneNumber: updateUserResponse.phoneNumber,
    phoneExtension: updateUserResponse.phoneExtension,
    cellPhoneNumber: updateUserResponse.cellPhoneNumber,
    roles: updateUserResponse.roles,
    companies: updateUserResponse.companies,
  };
};

export const createUpdateUserResponseToUserSimpleSummary = (
  updateUserResponse: CreateUpdateUserResponse
): UserSimpleSummary => {
  return {
    userId: updateUserResponse.id,
    firstName: updateUserResponse.firstName,
    lastName: updateUserResponse.lastName,
    email: updateUserResponse.emailAddress,
    phoneNumber: updateUserResponse.phoneNumber,
    phoneExtension: updateUserResponse.phoneExtension,
    cellPhoneNumber: updateUserResponse.cellPhoneNumber,
    roles: updateUserResponse.roles?.map((it) => Number(it.id)) ?? [],
    companies: updateUserResponse.companies?.map((it) => Number(it.id)) ?? [],
  };
};

/**
 * Checks if a user has access to a given resource by permissions
 * @param user The user to check
 * @param permission A permission identifier
 * @param role An optional role to restrict to. If supplied, even if the user
 *             has rights through another role, it will be rejected.
 * @returns True if the user has access, false otherwise.
 */
export const checkUserAccess = (
  user: User,
  permission: PermissionIdentifier,
  role?: string
): boolean => {
  let rolesToCheck =
    (role
      ? user.roles?.filter((userRole) => userRole.name === role)
      : user.roles) || [];

  return rolesToCheck.some((role) =>
    role.permissions.some((perm) => checkPermission(permission, perm))
  );
};

export const usersToTypeahead = (
  users: User[] | UserSummary[]
): TypeaheadFormObj[] =>
  users.map((user) => ({
    id: (user as User).id || (user as UserSummary).userId,
    label: `${user.lastName}, ${user.firstName}`,
  }));
