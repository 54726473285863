import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { User } from '../models';
import { SessionNotification } from '../models/SessionNotification';
import { FileUploadStatusGetResponse } from '../models/FileUpload';

export interface SessionState {
  currentUser: User | null | undefined;
  disclaimerAccepted: boolean;
  profileUpdated: number;
  notifications: SessionNotification[];
  showFileMonitor: boolean;
  filesToMonitor: number[];
  latestFileStatuses: FileUploadStatusGetResponse[];
}

const initialState: SessionState = {
  currentUser: undefined,
  disclaimerAccepted: sessionStorage.getItem('disclaimerAccepted') !== null,
  profileUpdated: Date.now(),
  notifications: [],
  showFileMonitor: false,
  filesToMonitor: [],
  latestFileStatuses: [],
};

export const counter = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null | undefined>) => {
      state.currentUser = action.payload;
    },
    setDisclaimerAccepted: (state, action: PayloadAction<boolean>) => {
      state.disclaimerAccepted = action.payload;
      if (action.payload) {
        sessionStorage.setItem('disclaimerAccepted', 'true');
      } else if (sessionStorage.getItem('disclaimerAccepted')) {
        sessionStorage.removeItem('disclaimerAccepted');
      }
    },
    setProfileUpdate: (state) => {
      state.profileUpdated = Date.now();
    },
    addNotification: (state, action: PayloadAction<SessionNotification>) => {
      const oldNoti = state.notifications.find(
        (noti) => noti.id === action.payload.id
      );
      if (oldNoti) {
        oldNoti.message = action.payload.message;
        oldNoti.type = action.payload.type;
      } else {
        state.notifications.push(action.payload);
      }
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      const idx = state.notifications.findIndex(
        (notification) => notification.id === action.payload
      );
      if (idx > -1) {
        state.notifications.splice(idx, 1);
      }
    },
    setShowFileMonitor: (state, action: PayloadAction<boolean>) => {
      const doShow = action === undefined || action.payload;
      state.showFileMonitor = doShow;
    },
    addFileToMonitor: (state, action: PayloadAction<number>) => {
      if (
        state.filesToMonitor.find((it) => it === action.payload) === undefined
      )
        state.filesToMonitor.push(action.payload);
    },
    removeFileToMonitor: (state, action: PayloadAction<number>) => {
      const idx = state.filesToMonitor.findIndex((it) => it === action.payload);
      if (idx > -1) state.filesToMonitor.splice(idx, 1);
    },
    setLatestFileStatuses: (
      state,
      action: PayloadAction<FileUploadStatusGetResponse[]>
    ) => {
      state.latestFileStatuses = action.payload;
    },
  },
});

export const {
  setUser,
  setDisclaimerAccepted,
  setProfileUpdate,
  addNotification,
  removeNotification,
  setShowFileMonitor,
  addFileToMonitor,
  removeFileToMonitor,
  setLatestFileStatuses,
} = counter.actions;

export const selectUser = (state: RootState) => state.session.currentUser;
export const selectProfileUpdated = (state: RootState) =>
  state.session.profileUpdated;
export const disclaimerAccepted = (state: RootState) =>
  state.session.disclaimerAccepted;
export const notifications = (state: RootState) => state.session.notifications;
export const showFileMonitor = (state: RootState) =>
  state.session.showFileMonitor;
export const filesToMonitor = (state: RootState) =>
  state.session.filesToMonitor;
export const latestFileStatuses = (state: RootState) =>
  state.session.latestFileStatuses;

export default counter.reducer;
