import React from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  notifications,
  removeFileToMonitor,
  removeNotification,
  setShowFileMonitor,
} from '../features/session';
import Alert from './Alert';
import './SessionNotifications.scss';
import Button from './Button';

const SessionNotifications = () => {
  const allNotifications = useAppSelector(notifications);
  const dispatch = useAppDispatch();

  const [trayOpen, setTrayOpen] = React.useState<boolean>(true);
  const [hasBeenClosed, setHasBeenClosed] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);

  React.useEffect(() => {
    const showPulse =
      trayOpen === false &&
      allNotifications.some(
        (noti) => noti.type === 'danger' || noti.type === 'warning'
      );
    setHasError(showPulse);
  }, [allNotifications, trayOpen, setHasError]);

  React.useEffect(() => {
    if (!hasBeenClosed) setTrayOpen(true);
    if (allNotifications.length === 0 && hasBeenClosed) setHasBeenClosed(false);
  }, [allNotifications, hasBeenClosed, setTrayOpen]);

  return (
    <div className="session-notifications">
      {allNotifications.length > 0 && (
        <>
          <div
            className={`session-notifications__notification-list session-notifications__notification-list__${
              trayOpen ? 'open' : 'closed'
            }${
              hasError ? ' session-notifications__notification-list__pulse' : ''
            }`}
          >
            {allNotifications.length > 1 && (
              <Button
                className="session-notifications__notification-list__clear-all"
                type="primary"
                onClick={() => {
                  allNotifications.forEach((noti) => {
                    dispatch(removeNotification(noti.id));
                    dispatch(removeFileToMonitor(Number(noti.id)));
                  });
                }}
                label="Clear All Notifications"
              />
            )}
            {allNotifications.map((notification) => (
              <Alert
                purpose=""
                key={notification.id}
                type={notification.type || 'danger'}
                align="center"
                closeable
                onClose={() => {
                  dispatch(removeNotification(notification.id));
                  dispatch(removeFileToMonitor(Number(notification.id)));
                }}
              >
                <Button
                  type="custom"
                  className="session-notifications__monitor-btn"
                  onClick={() => dispatch(setShowFileMonitor(true))}
                >
                  {notification.message}
                </Button>
              </Alert>
            ))}
          </div>
          <div
            className={`session-notifications__toggle session-notifications__toggle__${
              trayOpen ? 'open' : 'closed'
            }`}
          >
            <Button
              type="custom"
              onClick={() => {
                setHasBeenClosed(true);
                setTrayOpen((prev) => !prev);
              }}
            >
              <div className="session-notifications__toggle__hr" />
              <div className="session-notifications__toggle__hr" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SessionNotifications;
