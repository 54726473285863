import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback, KeyboardEvent } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Hook for when window dimensions change
 * Can be used in conjunction with grid_breakpoints in variables.ts
 * @returns Object with new width and height of window in pixels
 */
export function useWindowDimensions() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return { width, height };
}

/**
 * Hook to add a key click handler where needed for buttons to support A11Y
 * @param onClick The function to call on click
 * @returns A function that can be passed to onKeyPressed
 */
export const useClickKeyHandler = (onClick: (evt?: any) => void) =>
  useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Space' || e.code === 'Enter') {
        onClick?.(e);
        e?.preventDefault();
      }
    },
    [onClick]
  );

/**
 * Creates a function that auto resolves URLs to external URLs
 * @returns A function that will invoke internal or external links based on content
 */
export const useNavLinker = () => {
  const navigate = useNavigate();
  return (url: string) => {
    if (url.match(/$(http|mailto):/)) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };
};
