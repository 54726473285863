/**
 * Bootstrap breakpoints, for use in React.
 */
export const grid_breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
