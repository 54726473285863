import { Modal } from 'react-bootstrap';
import Button from '../components/Button';
import dayjs from 'dayjs';
import { withErrorBoundary } from '../components/ErrorBoundary';
import React from 'react';
import './SessionExpiring.scss';

interface SessionExpiringProps {
  show?: boolean;
  onHide: (refresh: boolean) => void;
  tokenExpireTime: dayjs.Dayjs | null;
}

const SessionExpiring = ({
  show,
  onHide,
  tokenExpireTime,
}: SessionExpiringProps) => {
  const [countDown, setCountDown] = React.useState<string>('0 seconds');

  React.useEffect(() => {
    const interval = setInterval(() => {
      const millis = tokenExpireTime?.diff() || 0;
      const mins = Math.floor((millis % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((millis % (1000 * 60)) / 1000);
      if (mins > 0) {
        setCountDown(`${mins.toString().padStart(2, '0')} minutes.`);
      } else {
        setCountDown(`${seconds.toString().padStart(2, '0')} seconds.`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [tokenExpireTime]);

  const refresh = () => {
    onHide(true);
  };

  return (
    <Modal
      aria-label="session expiration dialog"
      size="xl"
      centered
      show={show}
      onHide={() => onHide(false)}
      className="session-expiring"
      backdropClassName="session-expiring__backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>Your Session is About to Expire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="session-expiring__countdown">
          Your session will expire in {countDown}
        </p>
        <p className="session-expiring__msg">
          If you do not renew your session, you will be automatically logged
          out.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className={`session-expiring__buttons`}>
          <Button
            className="session-expiring__buttons__button"
            type="primary"
            onClick={refresh}
          >
            Stay Logged In
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default withErrorBoundary(SessionExpiring);
