import { Row, Container } from 'react-bootstrap';
import './PreparingPortal.scss';

const PreparingPortal = () => {
  return (
    <Container className="preparing_portal">
      <Row>
        <div className="form__title" id="main-content">
          Welcome to the Disaster Information Reporting System (DIRS)
        </div>
      </Row>
      <div className="h2 preparing_portal__main_content">Loading...</div>
    </Container>
  );
};

export default PreparingPortal;
