import React from 'react';
import './ErrorBoundary.scss';

interface ErrorBoundaryProps {
  children: JSX.Element | JSX.Element[];
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  clearState = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="error_boundary">
          Something went wrong. <button onClick={this.clearState}>Retry</button>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export const withErrorBoundary = <P,>(Component: React.ComponentType<P>) => {
  return function (props: P) {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
};

export default ErrorBoundary;
