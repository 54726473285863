import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React, { useEffect, useState } from 'react';
import './Alert.scss';
import { Collapse } from 'react-bootstrap';

export type AlertType = 'info' | 'success' | 'warning' | 'danger';

interface AlertProps {
  children: React.ReactNode;
  type: AlertType;
  purpose: string;
  timeout?: boolean;
  timeoutTime?: number;
  closeable?: boolean;
  onClose?: () => void;
  align?: 'center' | 'left' | 'right';
  printable?: boolean;
  id?: string;
}
/**
 *
 * @param props -
 * type - one of 'info', 'success', 'warning', or 'danger'
 *
 * timeout - if Alert should auto close. Default 5 seconds
 *
 * timeoutTime - changes timeout time
 *
 * closeable - if close button should appear on Alert
 *
 * onClose - callback when Alert closes
 *
 * align - one of 'center', 'left', or 'right'
 */
const Alert = ({
  children,
  type,
  timeout = false,
  timeoutTime = 5000,
  closeable = false,
  onClose,
  purpose,
  align = 'left',
  printable = true,
  id,
}: AlertProps) => {
  const [open, setOpen] = useState(true);
  const updateTimer = React.useRef<NodeJS.Timeout | null>(null);

  const ANIMATION_TIME = 300;

  useEffect(() => {
    if (!updateTimer.current && timeout && open) {
      updateTimer.current = setTimeout(() => {
        updateTimer.current = null;
        if (onClose !== undefined) {
          setTimeout(onClose, ANIMATION_TIME);
        }
        setOpen(false);
      }, timeoutTime);
    }
  }, [open, timeout, onClose]);

  const closeBtnHandler = () => {
    if (updateTimer.current) {
      clearTimeout(updateTimer.current);
      updateTimer.current = null;
    }
    if (onClose !== undefined) {
      setTimeout(onClose, ANIMATION_TIME);
    }
    setOpen(false);
  };

  const getIcon = () => {
    switch (type) {
      case 'info':
        return solid('info');
      case 'success':
        return solid('check');
      case 'warning':
        return solid('warning');
      case 'danger':
        return solid('xmark');
    }
  };
  const getAriaLabel = () => {
    switch (type) {
      case 'info':
        return 'Informational alert';
      case 'success':
        return 'Successful alert';
      case 'warning':
        return 'This is a warning';
      case 'danger':
        return 'Alert! This must be fixed before continuing';
      default:
        return 'Alert!';
    }
  };

  return (
    <Collapse
      in={open}
      timeout={ANIMATION_TIME}
      className={`${printable ? '' : 'print_hidden'}`}
    >
      <aside
        role="region"
        aria-label={`${purpose} ${type} banner`}
        className="alert"
        id={id}
      >
        <div
          className={`alert__container alert__${type} align_${align}`}
          aria-label={getAriaLabel()}
        >
          <div className="alert__content">
            <FontAwesomeIcon
              icon={getIcon()}
              size={'lg'}
              className="alert__icon"
              title={type}
            />
            <div className="alert__children">{children}</div>
          </div>
          {closeable && (
            <button
              type={'button'}
              className="alert__close_btn"
              onClick={closeBtnHandler}
            >
              x
            </button>
          )}
        </div>
      </aside>
    </Collapse>
  );
};

export default Alert;
