import React from 'react';
import './SocialMedia.scss';

const SocialMedia = () => {
  return (
    <React.Fragment>
      <a
        href="https://twitter.com/fcc"
        className="social-media__twitter"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open Twitter (opens in new window)"
      >
        <img alt="Twitter" src="/icons.png" />
      </a>
      <a
        href="https://www.facebook.com/FCC"
        className="social-media__facebook"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open Facebook (opens in new window)"
      >
        <img alt="Facebook" src="/icons.png" />
      </a>
      <a
        href="https://www.youtube.com/fcc"
        className="social-media__youtube"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open Youtube (opens in new window)"
      >
        <img alt="Youtube" src="/icons.png" />
      </a>
      <a
        href="https://www.flickr.com/photos/fccdotgov"
        className="social-media__flickr"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open Flickr (opens in new window)"
      >
        <img alt="Flickr" src="/icons.png" />
      </a>
      <a
        href="https://www.instagram.com/fcc"
        className="social-media__instagram"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open Instagram (opens in new window)"
      >
        <img alt="Instagram" src="/icons.png" />
      </a>
    </React.Fragment>
  );
};

export default SocialMedia;
