import { Container, Navbar } from 'react-bootstrap';
import './Header.scss';
import { LinkContainer } from 'react-router-bootstrap';
import UserStatus from './UserStatus';
import { useAppSelector } from '../app/hooks';
import { selectUser } from '../features/session';
import { useSelectedCompany } from '../features/SelectedCompanyContext';
import { getPrimaryRole } from '../models/Role';
import Button from './Button';

interface HeaderProps {
  mainContentId: string;
}

const Header = ({ mainContentId }: HeaderProps) => {
  const currentUser = useAppSelector(selectUser);
  const selectedCompany = useSelectedCompany();

  return (
    <Navbar aria-label="Header menu" className="header" data-testid="header">
      <Container>
        <Button
          className="header__skip-content"
          onClick={() => {
            document.getElementById(mainContentId)?.focus();
          }}
          label="Skip to Main Content"
        />
        <LinkContainer to="/" aria-label="return to homepage">
          <Navbar.Brand>
            <img
              className="header__branding"
              alt="Federal Communications Commission"
              src="/fcc-logo-lg.png"
            />
          </Navbar.Brand>
        </LinkContainer>
        {currentUser &&
          currentUser.companies &&
          currentUser.companies.length > 1 &&
          (getPrimaryRole(currentUser?.roles)?.name === 'coordinator' ||
            getPrimaryRole(currentUser?.roles)?.name === 'inputter') && (
            <Container className="loggedIn">
              Current Company:&nbsp;
              <span className="companyName">
                {selectedCompany?.company?.name}
              </span>
            </Container>
          )}
        <Navbar.Collapse />
        <UserStatus />
      </Container>
    </Navbar>
  );
};

export default Header;
