import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './SectionHeader.scss';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet-async';
import { dirsDescription } from '../features/const';

export const SectionHeaderContext = React.createContext<Element | null>(null);

interface Breadcrumb {
  label: string;
  url: string;
}

interface SectionHeaderProps {
  title?: string;
  hidden?: boolean;
  breadcrumbs?: (Breadcrumb | null)[];
  ogType?: string;
  ogDescription?: string;
  ogImage?: string;
}

const SectionHeaderImpl = ({
  title,
  hidden,
  breadcrumbs,
  ogType,
  ogDescription,
  ogImage,
}: SectionHeaderProps) => {
  return (
    <>
      <Helmet>
        <title>Disaster Information Reporting System (DIRS) - {title}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content={ogType || 'website'} />
        <meta
          property="og:title"
          content={`Disaster Information Reporting System (DIRS) - ${title}`}
        />
        <meta
          property="og:description"
          content={ogDescription || dirsDescription}
        />
        <meta
          property="og:image"
          content={ogImage || '/social-media-sharing-fcc-logo.jpg'}
        />
      </Helmet>
      {!hidden && (
        <Container as="header" fluid className="section_header">
          <Container>
            {breadcrumbs && (
              <Row
                className="section_header__breadcrumbs"
                data-testid="breadcrumbs"
              >
                <Col aria-label="breadcrumbs">
                  <h2 hidden className="accessibility__hidden">
                    You are here
                  </h2>
                  {breadcrumbs
                    .filter((it): it is Breadcrumb => !!it)
                    .map(({ label, url }) => (
                      <React.Fragment key={url}>
                        <Link
                          className="section_header__breadcrumbs__link"
                          to={url}
                          data-testid={`breadcrumb-${url}`}
                        >
                          {label}
                        </Link>
                        <span className="section_header__breadcrumbs__separator">
                          &nbsp;/&nbsp;
                        </span>
                      </React.Fragment>
                    ))}
                </Col>
              </Row>
            )}
            <Row className="section_header__title">
              <Col>
                <h1 className="h1">{title}</h1>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </>
  );
};

const SectionHeader = (props: SectionHeaderProps) => {
  return (
    <SectionHeaderContext.Consumer>
      {(location) => {
        return (
          location &&
          ReactDOM.createPortal(<SectionHeaderImpl {...props} />, location)
        );
      }}
    </SectionHeaderContext.Consumer>
  );
};

export default SectionHeader;
