import './TutorialPage.scss';
import { lazy, Suspense } from 'react';

const pages = (() => {
  const thePages: JSX.Element[] = [];
  const LTP = lazy(() => import('./LandingTutPage'));
  thePages.push(
    <Suspense fallback={<div />}>
      <LTP />
    </Suspense>
  );
  const MMP = lazy(() => import('./MainMenuPage'));
  thePages.push(
    <Suspense fallback={<div />}>
      <MMP />
    </Suspense>
  );
  const TOP = lazy(() => import('./TopOfPage'));
  thePages.push(
    <Suspense fallback={<div />}>
      <TOP />
    </Suspense>
  );
  const NP = lazy(() => import('./NotificationsPage'));
  thePages.push(
    <Suspense fallback={<div />}>
      <NP />
    </Suspense>
  );
  const EDP = lazy(() => import('./EnhancedDropdownsPage'));
  thePages.push(
    <Suspense fallback={<div />}>
      <EDP />
    </Suspense>
  );
  const FRP = lazy(() => import('./FormRequiredPage'));
  thePages.push(
    <Suspense fallback={<div />}>
      <FRP />
    </Suspense>
  );
  const PP = lazy(() => import('./PopupPage'));
  thePages.push(
    <Suspense fallback={<div />}>
      <PP />
    </Suspense>
  );
  const FP = lazy(() => import('./FinalPage'));
  thePages.push(
    <Suspense fallback={<div />}>
      <FP />
    </Suspense>
  );
  return thePages;
})();

export interface TutorialPageProps {
  images: { image: string | React.ReactNode; alt: string }[];
  text: React.ReactNode;
}

export const TutorialPage = ({ images, text }: TutorialPageProps) => {
  return (
    <div className="tutorial-page">
      <div className="tutorial-page__imgs-container">
        <div className="tutorial-page__imgs-container__imgs">
          {images.map((it) => (
            <div
              key={it.alt}
              className="tutorial-page__imgs-container__imgs__img"
            >
              {typeof it.image === 'string' && (
                <img key={it.alt} src={it.image} alt={it.alt} />
              )}
              {typeof it.image !== 'string' && it.image}
            </div>
          ))}
        </div>
      </div>
      <div className="tutorial-page__text">{text}</div>
    </div>
  );
};

export default pages;
