import { Modal, Row, Col } from 'react-bootstrap';
import { useRef } from 'react';
import { useSelectedCompany } from '../features/SelectedCompanyContext';
import { selectUser } from '../features/session';
import { useAppSelector } from '../app/hooks';
import SwitchCompanyCard from '../components/SwitchCompanyCard';
import { Company } from '../models';

interface SwitchCompaniesDialogProps {
  show: boolean;
  onDismiss: () => void;
  onCompanySelect: () => void;
}

const SwitchCompaniesDialog = ({
  show,
  onDismiss,
  onCompanySelect,
}: SwitchCompaniesDialogProps) => {
  const currentUser = useAppSelector(selectUser);
  const selectedCompany = useSelectedCompany();
  const companies: any = currentUser?.companies;
  const borderColor = useRef<string>('');
  const changeCompany = (companyId: number) => {
    const newCompany: Company = companies?.find(
      (element: Company) => element.id === companyId
    );

    selectedCompany.setCompany(newCompany);
    onCompanySelect();
  };

  return (
    <Modal
      aria-label="switch companies dialog"
      size="lg"
      show={show}
      onHide={onDismiss}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Switch Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row xs={1} md={2} lg={3} className="g-4 p-2">
          {companies?.map((company: any) => {
            if (selectedCompany.company?.name === company.name) {
              borderColor.current = 'warning';
            } else {
              borderColor.current = '';
            }
            return (
              <Col key={company.name}>
                <SwitchCompanyCard
                  label={company.name}
                  onClick={() => changeCompany(company.id)}
                  border={borderColor.current}
                />
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SwitchCompaniesDialog;
