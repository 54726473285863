import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { useWindowDimensions } from '../app/hooks';
import { grid_breakpoints } from '../app/variables';
import SocialMedia from './SocialMedia';
import './Footer.scss';

const Footer = () => {
  const { width } = useWindowDimensions();
  const showAll = width >= grid_breakpoints.md;
  const contactBtnClasses =
    width >= grid_breakpoints.md
      ? 'footer__container__contact-link'
      : 'btn btn-primary footer__container__contact-btn';

  const contactInfo = (
    <ListGroup as="ul">
      <ListGroup.Item as="li">
        <address>
          Federal Communications Commission
          <br />
          45 L Street NE
          <br />
          Washington, DC 20554
        </address>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="tel:+18882255322">Phone: 1-888-225-5322</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="tel:+18444322275">ASL Video Call: 1-844-432-2275</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="tel:+18664180232">Fax: 1-866-418-0232</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a
          className={contactBtnClasses}
          href="https://www.fcc.gov/about/contact"
        >
          Contact Us
        </a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <SocialMedia />
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <img
          aria-label="fcc seal"
          className="footer__container__fcc-seal"
          alt="Federal Communications Commission"
          src="/icons.png"
        />
      </ListGroup.Item>
    </ListGroup>
  );
  const otherLinks = (
    <ListGroup as="ul">
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/website-notices">
          Website Policies &amp; Notices
        </a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/privacy">Privacy Policy</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/foia">FOIA</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/no-fear-act-data">No Fear Act Data</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/digital-strategy">Digital Strategy</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/open-government-fcc">
          Open Government Directive
        </a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/plain-writing-fcc">Plain Writing Act</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/rss">RSS Feeds &amp; Email Updates</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/accessibility/program">Accessibility</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/vulnerability-disclosure-policy">
          Vulnerability Disclosure Policy
        </a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.usa.gov">USA.gov</a>
      </ListGroup.Item>
    </ListGroup>
  );
  const categories = (
    <ListGroup as="ul">
      <ListGroup.Item as="li">CATEGORIES</ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/about/overview">About the FCC</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/proceedings-actions">
          Proceedings &amp; Actions
        </a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/licensing">Licensing &amp; Databases</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/reports-research">
          Reports &amp; Research
        </a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/news-events">News &amp; Events</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/consumers">For Consumers</a>
      </ListGroup.Item>
    </ListGroup>
  );
  const bureausAndOffices = (
    <ListGroup as="ul">
      <ListGroup.Item as="li">BUREAUS &amp; OFFICES</ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/consumer-and-governmental-affairs">
          Consumer
        </a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/enforcement">Enforcement</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/inspector-general">Inspector General</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/international">International</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/media">Media</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/public-safety-and-homeland-security">
          Public Safety
        </a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/wireless-telecommunications">Wireless</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/wireline-competition">Wireline</a>
      </ListGroup.Item>
      <ListGroup.Item as="li">
        <a href="https://www.fcc.gov/offices-bureaus">Offices</a>
      </ListGroup.Item>
    </ListGroup>
  );
  return (
    <Row className="footer">
      <footer className="footer__container" data-testid="footer">
        <Container fluid>
          <Row>
            <Col xs={12} md={4}>
              {contactInfo}
            </Col>
            {showAll && <Col>{otherLinks}</Col>}
            {showAll && <Col>{categories}</Col>}
            {showAll && <Col>{bureausAndOffices}</Col>}
          </Row>
        </Container>
      </footer>
    </Row>
  );
};

export default Footer;
