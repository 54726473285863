import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../components/Button';
import './DialogSignedOut.scss';
import { useOktaAuth } from '@okta/okta-react';
import { useAppDispatch } from '../app/hooks';
import { setProfileUpdate } from '../features/session';

interface DialogSignedOutProps {
  show: boolean;
  onDismiss: () => void;
}

const DialogSignedOut = ({ show, onDismiss }: DialogSignedOutProps) => {
  const oktaContext = useOktaAuth();
  const dispatch = useAppDispatch();

  const doLogin = () => {
    oktaContext.oktaAuth
      .signInWithRedirect()
      .then(() => dispatch(setProfileUpdate()));
    onDismiss();
  };

  return (
    <Modal
      aria-label="signed out dialog"
      size="lg"
      show={show}
      onHide={onDismiss}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>You have been signed out</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your session has expired or you have logged out from another tab.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDismiss}>Dismiss</Button>
        <Button onClick={doLogin}>Log In</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogSignedOut;
